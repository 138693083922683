import React from 'react'
import { CircularProgress } from '@mui/material';

function PullDownContent() {
    return (
        <div id="container2">
            <div id="arrow" />
            <CircularProgress sx={{ color: "black" }} />
            <style>
                {`
                #container2 {
                    padding-top:20px;
                    height: 100px;
                    text-align: center;
                }
                             
            `}
            </style>
        </div>
    )
}

export default PullDownContent