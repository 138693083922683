import React, { useEffect, useState } from "react";
import { Box, IconButton, TextField, Typography, } from "@mui/material";
import { GetAllSeniors, GetRoutes } from "../../Services/Api/MealService";
import AuthService from "../../Services/AuthService";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import SearchIcon from "@mui/icons-material/Search";
import DoneIcon from "@mui/icons-material/Done";

const Root = styled(Box)(({ theme }) => ({
  height: "100%",
  padding: 0,
  "& ~ .MuiPopover-root": {
    "& div": {
      "& ul": {
        "& li": {
          backgroundColor: "red",
        },
      },
    },
  },
  "& .mainContainer": {
    backgroundImage: `url(${require("../../Assets/loginBackgroundImg/img1.png")})`,
    backgroundSize: "cover",
    overflow: "hidden",
    backgroundPosition: "center",
    backgroundColor: "#f8e9f7",
    height: "calc(100vh - 48px)",
    marginTop: 48,
    overflowY: "auto",
    "& .miniNav": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
      background: "white",
      zIndex: 1,
      position: "relative",
      borderTop: "1px solid #ccc",
      padding: "0 20px",
      "& .MuiInputBase-root": {
        "& .MuiInputBase-input": {
          fontSize: 16,
          fontWeight: 300,
          color: theme.palette.background.black,
        },
      },
      "& .searchBtn": {
        position: "fixed",
        top: 12,
        right: 56,
      },
      "& .headerText": {
        color: "black",
        fontSize: 16,
        fontWeight: 500,
      },
      "& .MuiInputBase-input": {
        color: 'black !important',
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: 'grey !important',
      },
    },
    "& .content": {
      margin: "20px auto",
      width: "100%",
      minWidth: 275,
      padding: 10,
      backgroundColor: theme.palette.primary.contrastText,
      boxShadow: "0px 3px 15px -6px rgb(0 0 0 / 50%)",
      borderRadius: 10,
      "& .mealInfo": {
        position: 'relative',
      },
      "& .mealBtns": {
        marginTop: 5,
      },
      "& .outlineButton": {
        fontSize: 18,
        color: theme.palette.other.main,
        borderColor: theme.palette.other.main,
        borderRadius: 20,
      },
      "& .containedButton": {
        fontSize: 18,
        color: theme.palette.primary.contrastText,
        borderColor: theme.palette.other.main,
        borderRadius: 20,
        backgroundColor: theme.palette.other.main,
      },
      "& .routetextColor": {
        fontSize: 23,
        fontWeight: 400,
        color: theme.palette.other.main,
        width: "80%",
        overflow: "hidden",
        lineBreak: "anywhere"
      },
      "& .textColor": {
        fontSize: 16,
        fontWeight: 400,
        color: theme.palette.primary.dark,
      },
      "& .images": {
        position: 'absolute',
        top: 0,
        right: 0,
      },
    },
  },
  '& img[alt="mealpng"]': {
    width: 60,
  },
  "& .mainCotainer2": {
    position: "fixed",
    left: "50%",
    top: 2,
    width: "100%",
    zIndex: '1',
    height: "48px",
    transform: "translateX(-50%)",
    "& .headerNav": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
      backgroundColor: "white",
      height: 47,
      borderBottom: "1px solid #00000033",
      "& .headerText": {
        display: "flex",
        color: "black",
        fontSize: 16,
        fontWeight: 500,
      },
      "& img": {
        width: 26,
        filter: "invert(1)",
      },
    },
  },
}));

export default function Senior(props) {
  // Custom Styles:
  const menuItemStyles = { color: "#1f0757", fontWeight: "600" };
  let location = useLocation();
  let id = location.state;
  if (!!id) {
    localStorage.setItem("MealId", id);
  }
  if (!id) {
    id = parseInt(localStorage.getItem("MealId"));
  }
  const [state, setState] = useState({
    routes: [],
    seniorId: [],
    seniorsList: [],
    filteredSeniorList: [],
  });
  // const [checkIcon, setCheckIcon] = useState(true);
  const [checkIconSort, setCheckIconSort] = useState(true);
  // const [count, setCount] = useState(0);
  const [openSearch, setOpenSearch] = useState(false);
  const [isSorted, setIsSorted] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menu, setMenu] = React.useState(null);
  useEffect(() => {
    let Token = AuthService.getAuthDataByName("token") || "";
    if (Token === "") {
      navigate("/");
    } else {
      if (state.routes.length === 0) {
        GetInitialDetails();
      }
    }
    // eslint-disable-next-line
  }, []);
  let navigate = useNavigate();
  //If route id not available than all senior show
  const GetInitialDetails = async () => {
    await GetRoutes();
    setTimeout(() => {
      if (!openSearch) {
        let seniorsList = GetAllSeniors();
        let seniorsListData = seniorsList.data;
        if (state.seniorsList.length === 0) {
          setState({ ...state, seniorsList: seniorsListData, filteredSeniorList: seniorsListData });
        }
      }
    }, 1000)
  };
  //Open Dialogs  sortBy
  const toggleMenu = (event, menuName) => {
    // handleCountDeliverShow();
    setAnchorEl(event.currentTarget);
    if (menu == null) {
      setMenu(menuName);
    } else if (menu !== menuName) {
      setMenu(menuName);
    } else {
      setMenu(null);
    }
  };
  //Close Dialogs  sortBy
  const handleCloseSort = () => {
    setAnchorEl(null);
    setMenu(null);
  };
  // Dialogs handler
  const handleSortByName = () => {
    const SortedData = state.seniorsList.sort((a, b) => {
      if (a.FirstName < b.FirstName) {
        return -1;
      }
      if (a.FirstName > b.FirstName) {
        return 1;
      }
      return 0;
    });
    setState({ ...state, filteredSeniorList: SortedData });
    setCheckIconSort(true);
    handleCloseSort();
  };
  const handleSortById = () => {
    const SortedData = state.seniorsList.sort((a, b) => {
      setIsSorted(true)
      if (a.Postion < b.Postion) {
        return -1;
      }
      if (a.Postion > b.position) {
        return 1;
      }
      return 0;
    });
    setState({ ...state, filteredSeniorList: SortedData });
    setCheckIconSort(false);
    handleCloseSort();
  };
  const handleOpenSearchBar = () => {
    setOpenSearch(!openSearch);
  };
  const handleSearch = (e) => {
    if (!(e.target.value == null || e.target.value === "")) {
      const FilteredData = state.seniorsList.filter((d) =>
        d.FirstName.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setState({ ...state, filteredSeniorList: FilteredData });
    } else {
      setState({ ...state, filteredSeniorList: state.seniorsList });
    }
  };
  return (
    <Root p={2}>
      <Box
        className="mainCotainer2"
        sx={{ width: "100%", maxWidth: "100%", padding: "0px 15px 0px 44px" }}
      >
        <Box className="headerNav">
          <Box
            size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2, display: "inline-flex", alignItems: "center" }}
          >
            <img src={require("../../Assets/icons/msc_heart.png")} alt="Logo" style={{ marginRight: 10 }} />
            <Typography color="black" variant="h6"> My Senior Center </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: 1 }}>
            <Typography variant="p" component="div" className="headerText"> Seniors </Typography>
            <Box className="searchBtn" onClick={handleOpenSearchBar}> <SearchIcon /> </Box>
          </Box>
        </Box>
      </Box>
      <Box className="mainContainer">
        <Box sx={{ flexGrow: 1 }}>
          <Box className="miniNav">
            {openSearch ? (<TextField id="standard-basic" sx={{ marginBottom: 0 }} label="" placeholder="Type Keyword" size="small" onChange={(e) => { handleSearch(e); }} variant="outlined" />) : ("")}
            <IconButton
              size="large"
              edge="start"
              color="default"
              aria-label="menu"
              sx={{ display: "flex", justifyContent: "space-between" }}
              id="fade-button"
              aria-controls={menu === "sort" ? "fade-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={menu === "sort" ? "true" : undefined}
              onClick={(event) => toggleMenu(event, "sort")}
            >
              <SwapVertIcon />
            </IconButton>
            <Menu
              id="fade-menu"
              MenuListProps={{
                "aria-labelledby": "fade-button",
              }}
              anchorEl={anchorEl}
              open={menu === "sort"}
              onClose={handleCloseSort}
              TransitionComponent={Fade}
            >
              <MenuItem sx={menuItemStyles} onClick={handleSortById}>
                Sort by route order {!checkIconSort ? <DoneIcon /> : ""}
              </MenuItem>
              <MenuItem sx={menuItemStyles} onClick={handleSortByName}>
                Sort by Name {checkIconSort ? <DoneIcon /> : ""}
              </MenuItem>
            </Menu>
          </Box>
        </Box>
        <Box sx={{ margin: "4px 10px" }}>
          {state.filteredSeniorList?.map((item, index) => (
            <Box key={index} sx={{ margin: "4px 10px" }}>
              <Box className="content">
                <Box className="mealInfo">
                  <NavLink
                    style={{ textDecoration: "none" }}
                    to={"/info"}
                    state={{
                      routeID: item.RouteID,
                      seniorID: item.SeniorID,
                    }}
                  >
                    <Typography className="routetextColor" variant="body1"> {item.LastName}, {item.FirstName} </Typography>
                    <Typography className="textColor" variant="body1"> {item.Address} </Typography>
                  </NavLink>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Root>
  );
}
