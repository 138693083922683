import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { AppBar, Box, Button, DialogContent, Divider, TextField, Toolbar, Typography } from '@mui/material';
import Beep from "../Assets/audio/scanner-beep.mp3"
import Dialog from "@mui/material/Dialog";
import SmartphoneOutlinedIcon from '@mui/icons-material/SmartphoneOutlined';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { Link } from 'react-router-dom';
const Root = styled(Box)(({ theme }) => ({
    '& .header-cont': {
        // width: "100%",
        width: "device-width",
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        "& header": {
            display: "flex",
            height: 48,
            background: theme.palette.primary.contrastText,
            fontSize: 12,
            alignItems: "center",
            zIndex: "99",
            '& img[alt="Logo"]': {
                width: 34,
                filter: 'invert(1)',
            },
            "& .headerNav": {
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                "& .pageMainTitle": {
                    fontSize: 15,
                    fontWeight: 600,
                    whiteSpace: "nowrap"
                },
                "& .headerText": {
                    display: "flex",
                    color: '#1f0757',
                    fontSize: 13,
                    textShadow: '#1f0757',
                    fontWeight: 600,
                    whiteSpace: "nowrap"
                }
            }
        },
    },
    '& .mainContainer': {
        backgroundImage: `url(${require('../Assets/loginBackgroundImg/img1.png')})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundColor: "#f8e9f7",
        overflow: "hidden",
        display: "flex",
        height: "calc(100vh - 48px)",
        overflowY: "auto",
        marginTop: "48px",
        flexDirection: "column",
        padding: "0px 14px",
        "& .bluetoothIcon": {
            position: "absolute",
            top: 6,
            right: 90,
            "&:hover": {
                background: "transparent",
            }
        },
        "& .searchArea": {
            display: "flex",
            marginTop: 15,
            alignItems: "center",
            "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input  ": {
                fontWeight: 600
            },
            "& .css-1dknvfp-MuiFormLabel-root-MuiInputLabel-root": {
                fontWeight: 600,
                fontSize: 18,
            },
            "& .MuiInputLabel-formControl": {
                fontWeight: 600,
                fontSize: 14,
            }
        },
        "& .mainList": {
            padding: 0,
            listStyle: "none",
            margin: "0px 0px 20px 0px",
            "& li": {
                borderBottom: "1px solid #cccccc",
                padding: "10px 0"
            }
        },
        "& .searchbar": {
            margin: 0,
        },
        "& .selected": {
            backgroundColor: "#dbbecc",
            borderRadius: "4px",
        },
        "& .listText": {
            [theme.breakpoints.down('sm')]: {
                width: "65%"
            },
        },
        "& .addBtn": {
            backgroundColor: theme.palette.button.main
        },
        "& .removeBtn": {
            padding: "2px",
            backgroundColor: theme.palette.error.main
        }
    }
}))
const DialogBox = styled(Dialog)(({ theme }) => ({
    "& .dialogBtnClose": {
        marginTop: "0px",
        textAlign: "center",
        "& .closeDialog": {
            border: "none",
            backgroundColor: "transparent",
            color: theme.palette.sidebarLink.main,
            textDecoration: "underline",
            cursor: "pointer",
            fontSize: "16px",
            "&:hover": {
                textDecoration: "none"
            },
        }
    },
    "& .titleBoxDialogTwo": {
        display: "flex",
        justifyContent: "start",
        padding: "4px 8px",
    },
    "& .mainListDialog": {
        padding: 0,
        listStyle: "none",
        margin: 0,
        height: "225px",
        overflowY: "auto",
        width: "235px",
        "& li": {
            borderBottom: "1px solid #cccccc",
            padding: "10px 0"
        }
    },
}));


const BluetoothScanner = () => {
    const [searchResult, setSearchResult] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [selectedState, setSelectedState] = useState(0);
    const [lastIndex, setLastIndex] = useState(0);
    const [openBluetoothDialog, setOpenBluetoothDialog] = React.useState(false);
    const [bluetoothDevices, setBluetoothDevices] = React.useState([]);

    function playBeep() {
        const audio = new Audio(Beep);
        audio.play();
    }
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleClickFind();
        }
    }
    const handleClickFind = (e) => {
        if (searchValue === "") {
            alert("Please Enter Text.")
        } else {
            playBeep();
            setSearchResult([...searchResult, searchValue])
            setLastIndex(searchResult.length);
            setSearchValue("");
        }
    }
    const handleKeyDownOnArrow = (event) => {
        const key = event.key;
        if (key === "ArrowUp") {
            if (selectedState === 0) {
                return
            } else {
                setSelectedState(selectedState - 1);
            }
        } else if (key === "ArrowDown") {
            if (selectedState === lastIndex) {
                return
            } else {
                setSelectedState(selectedState + 1);
            }
        }
    };
    const handleRemove = (index) => {
        playBeep();
        const updatedResult = [...searchResult];
        updatedResult.splice(index, 1);
        setSearchResult(updatedResult);
    }
    const handleCloseBluetoothDialog = () => {
        setOpenBluetoothDialog(false);
    };
    // const handleOpenBluetoothDialog = () => {
    //     // connectToBluetooth()
    //     setOpenBluetoothDialog(true);
    // };
    // const connectToBluetooth = async () => {
    //     try {
    //         const device = await navigator.bluetooth.requestDevice({ acceptAllDevices: true });
    //         setBluetoothDevices([...bluetoothDevices, bluetoothDevices]);
    //     } catch (error) {
    //         console.log('Error: ' + error);
    //     }
    // }

    return (

        <Root >
            <Box className="header-cont">
                <Box sx={{ flexGrow: 1 }}>
                    <AppBar position="static">
                        <Toolbar sx={{ width: "100%", maxWidth: "100%", padding: "0px 15px" }}>
                            <Box className="headerNav">
                                <Box
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    aria-label="menu"
                                    sx={{ mr: 2, display: "inline-flex", alignItems: "center" }}
                                >
                                    <img src={require('../Assets/icons/msc_heart.png')} alt="Logo" style={{ marginRight: 10 }} />
                                    <div>
                                        <Typography color="black" className="pageMainTitle" id="pageTitle">
                                            My Senior Center
                                        </Typography>
                                        <Typography variant="h6" component="span" className="headerText">Bluetooth </Typography>
                                    </div>
                                </Box>
                            </Box>
                            <Link to='/'>Back</Link>
                        </Toolbar>
                    </AppBar>
                </Box>
            </Box>
            <Box className="mainContainer " sx={{ overflowY: " auto", scrollBehavior: "smooth" }}>
                {/* <IconButton onClick={handleOpenBluetoothDialog} className='bluetoothIcon'>
                    <BluetoothIcon />
                </IconButton> */}
                <Box sx={{ display: 'flex', flexDirection: "column", alignItems: "center" }}>
                </Box >
                <Box className="searchArea">
                    <TextField className='searchbar' onFocus={{ borderColor: "black" }} onKeyDown={(e) => handleKeyDown(e)} value={searchValue} onChange={(e) => { setSearchValue(e.target.value) }} label="Add Text" variant="outlined" />
                    <Box sx={{ paddingLeft: "6px" }}>
                        <Button sx={{ padding: "12px" }} className='addBtn' variant='contained' onClick={handleClickFind} >Add</Button>
                    </Box>
                </Box>
                <Box >
                    <Box sx={{ marginTop: '20px', }}>
                        <ul className='mainList' >
                            {searchResult.map((value, index) => (
                                <li style={{ display: "flex", justifyContent: "space-between", padding: "10px", gap: 5, wordBreak: "break-all", minWidth: 290, outline: "none", alignItems: "center" }} tabIndex={0} onKeyDown={(e) => handleKeyDownOnArrow(e)} onClick={() => setSelectedState(index)} className={index === selectedState ? "selected" : ''} variant='subtitle2' key={index}><Typography className='listText' variant="subtitle">{value}</Typography><Button className='removeBtn' variant='contained' onClick={() => handleRemove(index)}>Remove</Button></li>
                            ))}
                        </ul>
                    </Box>
                </Box>
            </Box>
            {/*  Dialog  */}
            <DialogBox
                open={openBluetoothDialog}
                onClose={() => handleCloseBluetoothDialog()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Box className="titleBoxDialogTwo"> <Typography variant='subtitle1'>Available Devices</Typography> </Box>
                <Divider />
                <DialogContent sx={{ display: "flex", flexDirection: "column", gap: "5px", padding: "2px 6px 4px 6px" }}>
                    <Box >
                        <ul className='mainListDialog' >
                            {bluetoothDevices.map((value, index) => (
                                <li style={{ display: "flex", padding: "10px", gap: 5, wordBreak: "break-all", minWidth: 200, outline: "none", alignItems: "center" }} variant='subtitle2' key={index}><SmartphoneOutlinedIcon sx={{ color: "grey" }} /><Typography variant="subtitle">{value}</Typography><ArrowForwardOutlinedIcon sx={{ color: "grey", marginLeft: "auto" }} /></li>
                            ))}
                        </ul>
                    </Box>
                    <Box className="dialogBtnClose"> <Button onClick={() => handleCloseBluetoothDialog()} className='closeDialog'>Close</Button> </Box>
                </DialogContent>
            </DialogBox>
        </Root>

    );
};

export default BluetoothScanner;

