import React, { useEffect, useState } from 'react';
import { AppBar, Box, Button, CircularProgress, FormControl, IconButton, Radio, Toolbar, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import AuthService, { GetAppName } from '../../Services/AuthService';
import { GetVehiclesByDevice } from "../../Services/Api/RidesServices";
import RefreshIcon from '@mui/icons-material/Refresh';
const Root = styled(Box)(({ theme }) => ({
    height: '100%',
    padding: 0,
    '& .header-cont': {
        // width: "100%",
        width: "device-width",
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        "& .MuiToolbar-root": {
            padding: "0px 15px 0px 45px"
        },
        "& header": {
            display: "flex",
            height: 48,
            background: theme.palette.primary.contrastText,
            fontSize: 12,
            alignItems: "center",
            '& img[alt="Logo"]': {
                width: 34,
                filter: 'invert(1)',
            },
            "& .headerNav": {
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                "& .pageMainTitle": {
                    fontSize: 15,
                    fontWeight: 600,
                    whiteSpace: "nowrap"
                },
                "& .headerText": {
                    display: "flex",
                    color: '#1f0757',
                    fontSize: 13,
                    textShadow: '#1f0757',
                    fontWeight: 600,
                    whiteSpace: "nowrap"
                },
                "& .refreshingIcon": {
                    color: "Grey"
                },
                "& .reloadBtn": {
                    padding: "0px 0px 4px 0px"
                }
            }
        },
    },
    '& .mainContainer': {
        backgroundImage: theme.palette.type === 'light' ? 'white' : `url(${require('../../Assets/loginBackgroundImg/img1.png')})`,
        backgroundSize: "cover",
        overflow: "hidden",
        backgroundPosition: "center",
        height: "calc(100vh - 48px)",
        marginTop: 48,
        overflowY: "auto",

        "& .nextBtn": {
            width: "80%",
            borderRadius: "40px",
            padding: "16px 14px",
            margin: "20px 0px",
            background: theme.palette.button.main,
            borderColor: theme.palette.button.main,
            color: theme.palette.background.black,
            fontSize: 18,
            fontWeight: 600,
            "&:hover": {
                background: theme.palette.button.main,
                borderColor: theme.palette.button.main,
                color: theme.palette.background.black,
            },
        },
        "& .routeBox": {
            margin: "20px auto",
            display: "flex",
            width: "95%",
            minWidth: "80%",
            gap: 8,
            border: theme.palette.type === 'light' ? ' 1px solid #707070' : "1px solid #ffcccc",
            backgroundColor: theme.palette.primary.contrastText,
            padding: "10px",
            borderRadius: 10,
            justifyContent: "space-between",
            cursor: "pointer",
            "& .routeText": {
                display: "flex",
                flexDirection: "column",
            },

            "& .routetextColor": {
                fontSize: 16,
                fontWeight: 400,
                color: theme.palette.primary.dark,
            },
            "& .textColor": {
                fontSize: 16,
                fontWeight: 300,
                color: theme.palette.primary.dark,
            },
            "& .selectRadioColor": {
                "& .MuiFormControlLabel-root": {
                    margin: 0,
                    marginTop: 2,
                },
                "& .MuiSvgIcon-root": {
                    color: theme.palette.button.main,
                },

            },
            "& .radioBtn": {
                "& .MuiFormControlLabel-root": {
                    margin: 0,
                    marginTop: 2,
                },
                "& .MuiSvgIcon-root": {
                    color: "grey",
                },
            },
            "& .routeBoxInner": {
                display: "flex",
                gap: 12,
                alignItems: "center",
                marginLeft: 6,
            },
        },
        "& .selectColor": {
            boxShadow: '0px 1px 12px 4px #0000000a',
            border: '1px solid #4caf50',
            backgroundColor: theme.palette.type === 'light' ? 'none' : theme.palette.button.light,
        },



    },
    '& img[alt="heartpng"]': {
        width: 50,
    },
}));
export default function SelecteVehicle() {
    let navigate = useNavigate();
    const [appName, setAppName] = React.useState('');
    const [logo, setLogo] = React.useState('');
    const [state, setState] = useState({
        vehiclesByDevice: [],
        vehiclesByDriver: [],
        callTaken: false,
    });
    const [data, setData] = useState({
        gsid: null,
        deviceTagID: null,
        deviceSecret: "",
    });
    const [responceRide, setResponceRide] = useState(false);
    const [selectedVehicle, setSelectedVehicle] = React.useState(null);
    useEffect(() => {
        let tagid = AuthService.getAuthDataByName("deviceTagID")
        if (appName === "My Senior Center" && tagid !== null) {
            GetApplicationName(tagid);
        }
        let applicationName = AuthService.getAppDataByName("AppName");
        if (applicationName === null || applicationName === '') {
            setAppName("My Senior Center");
        } else {
            setAppName(AuthService.getAppDataByName("AppName"));
            setLogo(AuthService.getAppDataByName("Logo"));
        }
        let Token = AuthService.getAuthDataByName("token") || "";
        if (Token === "") {
            navigate("/")
        } else {
            AuthService.setUserData("processStep", 2);
            if (!state.callTaken) {
                state.callTaken = true;
                GetRideData();
            }
            let ProcessCompleted = AuthService.getAuthDataByName("processStep") || "";
            if (ProcessCompleted === "Complete") {
                navigate("/ride")
            }
        }
        // eslint-disable-next-line
    }, [])
    const GetApplicationName = async (id) => {
        await GetAppName(id)
            .then((res) => {
                let data = res.data
                if (Object.keys(data).length && data !== null) {
                    AuthService.setAppData("AppName", res.data.Name)
                    AuthService.setAppData("Logo", res.data.Logo)
                } else {
                    AuthService.setAppData("AppName", "My Senior center")
                }
            })
            .catch((err) => {
                console.log(err)
                AuthService.setAppData("AppName", "My Senior center")
            })
    }
    const fakeLoader = (timeout = 1000) => {
        setResponceRide(true)
        setTimeout(() => {
            setResponceRide(false)
        }, timeout);
    }
    const GetRideData = async () => {
        fakeLoader(1000)
        let gsid = AuthService.getAuthDataByName("gsid")
        let deviceTagID = AuthService.getAuthDataByName("deviceTagID")
        let deviceSecret = AuthService.getAuthDataByName("deviceSecret")
        data.gsid = gsid;
        data.deviceTagID = deviceTagID;
        data.deviceSecret = deviceSecret;
        setData({ ...data })
        if (deviceTagID === null) {
            navigate("/");
        }
        let vehicleActivation = await GetVehiclesByDevice(data);
        setState({
            ...state,
            vehiclesByDevice: vehicleActivation.data,
        });
        if (!!vehicleActivation.data && vehicleActivation.data.length) {
            setSelectedVehicle(vehicleActivation.data[0].name)
        }
    };
    const handleNext = () => {
        if (selectedVehicle === null) {
            alert("Please select atleast One Vehicle")
        }
        else {
            const filteredVData = state.vehiclesByDevice.filter(item => item.name === selectedVehicle);
            let vehicleUID = filteredVData[0].vehicleUID;
            AuthService.setUserData("vehicleUID", vehicleUID)
            AuthService.setUserData("processStep", 1);
            AuthService.setUserData("selectedVehicle", selectedVehicle);
            navigate("/driver")
        }
    }
    return (
        <Root p={2} >
            <Box className="header-cont">
                <Box sx={{ flexGrow: 1 }}>
                    <AppBar position="static">
                        <Toolbar sx={{ width: "100%", maxWidth: "100%", padding: "0px 15px 0px 45px" }}>
                            <Box className="headerNav">
                                <Box
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    aria-label="menu"
                                    sx={{ mr: 2, display: "inline-flex", alignItems: "center" }}
                                >
                                    {logo ? <img src={logo} alt="Logo" style={{ marginRight: 10 }} /> : <img src={require('../../Assets/icons/msc_heart.png')} alt="Logo" style={{ marginRight: 10 }} />}

                                    <div>
                                        {appName ? <Typography color="black" className="pageMainTitle" id="pageTitle">
                                            {appName}
                                        </Typography> : <Typography color="black" className="pageMainTitle" id="pageTitle">
                                            My Senior Center
                                        </Typography>}
                                        <Typography variant="h6" component="span" className="headerText">Select Vehicle </Typography>
                                    </div>
                                </Box>

                                <IconButton className="reloadBtn" onClick={() => GetRideData()} sx={{ marginLeft: "auto" }}>
                                    {responceRide ? <CircularProgress size={20} className='refreshingIcon' /> : <RefreshIcon size={20} />}
                                </IconButton>
                            </Box>
                        </Toolbar>
                    </AppBar>
                </Box>
            </Box>
            <Box className="mainContainer">
                <Box sx={{ height: "calc(100vh - 175px)", overflowY: "auto" }}>
                    {state?.vehiclesByDevice?.length === 0 ? (
                        <Box sx={{ height: 50, textAlign: "center" }}> <Typography variant="subtitle1" sx={{ marginTop: 10 }}> Loading.....! </Typography> </Box>
                    ) : (
                        state.vehiclesByDevice?.map((e, i) => (
                            <Box
                                className={`routeBox ${e.name === selectedVehicle ? "selectColor" : ""}`} key={i} onClick={() => setSelectedVehicle(e.name)}
                            >
                                <Box className={"routeBoxInner"}>
                                    <Box className="image"> <img width={50} src={require("../../Assets/images/vehicle-icon.png")} alt="heartpng" /> </Box>
                                    <Box className="routeText">
                                        <Typography className="routetextColor" variant="h3">{e.name}</Typography>
                                        <Typography className="textColor" variant="h5">Car</Typography>
                                        <Typography className="textColor" variant="h5">Stop Count: {e.stopCount}</Typography>
                                    </Box>
                                </Box>
                                <Box className={`${e.name === selectedVehicle ? " selectRadioColor" : 'radioBtn'} `}> <FormControl> <Radio checked={e.name === selectedVehicle} /> </FormControl> </Box>
                            </Box>
                        ))
                    )}
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Button className="nextBtn" onClick={handleNext}> Next </Button>
                </Box>
            </Box>
        </Root>
    )
}