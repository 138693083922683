import AuthService, { GetAuthToken, handleUnauthorized } from "../AuthService";
import AppSettings from "../AppSettings";

let baseUrl = AppSettings.serverUrl;
let GetRequest = async (endpoint, authorization = false, gsidRequired = false) => {
    let auth = AuthService.getAuthDataByName("gsid");
    var i = 0;
    while (i++ <= 3) {
        try {
            let headers = {}
            if (gsidRequired) {
                headers.gsid = auth
            }

            var response = await fetch(baseUrl + endpoint, {
                method: "GET",
                headers: authorization ? new Headers({ 'Authorization': await GetAuthToken(), 'Content-Type': 'application/json', 'appVersion': AppSettings.dynamicCacheName }) : headers,
            })
            if (response.status === 401) {
                await handleUnauthorized();
                continue;
            } else
                return await response.json();
        } catch (err) {
            console.log(baseUrl + endpoint, err)
        }
    }
}

let PostRequest = async (endpoint, data = null, authorization = false, gsidRequired = false) => {
    let auth = AuthService.getAuthDataByName("gsid");
    var i = 0;
    while (i++ <= 3) {
        try {
            let headers = {}
            if (gsidRequired) {
                headers['gsid'] = auth;
            }
            if (authorization) {
                headers['Authorization'] = await GetAuthToken();
                headers['Content-Type'] = 'application/json';
                headers['appVersion'] = AppSettings.dynamicCacheName;
            }
            var response = await fetch(baseUrl + endpoint, {
                method: "POST",
                headers: new Headers(headers),
                body: data ? JSON.stringify(data) : null
            })
            if (response.status === 401) {
                await handleUnauthorized();
                continue;
            }
            else {
                return await response.json();
            }
        } catch (err) {
            console.log(baseUrl + endpoint, err)
            // alert("something went wrong please refresh")
        }
    }
}

let PostFormsRequest = async (endpoint, data = null, authorization = false, gsidRequired = false) => {
    var i = 0;
    while (i++ <= 3) {
        try {
            let auth = AuthService.getAuthDataByName("gsid");
            let headers = authorization ? new Headers({ 'Authorization': await GetAuthToken(), 'appVersion': AppSettings.dynamicCacheName }) : {};
            if (gsidRequired)
                headers.gsid = auth;
            console.log('request post object: ', {
                method: "POST",
                headers: headers,
                body: data ? data : null
            });
            for (var pair of data.entries()) {
                console.log(pair[0] + ', ' + pair[1]);
            }
            var response = await fetch(baseUrl + endpoint, {
                method: "POST",
                headers: headers,
                body: data
            })
            if (response.status === 401) {
                await handleUnauthorized();
                continue;
            }
            else {
                return await response.json();
            }
        } catch (err) {
            console.log(baseUrl + endpoint, err)
        }
    }
}


export { GetRequest, PostRequest, PostFormsRequest }