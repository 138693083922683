import React, { useEffect, useState } from 'react';
import { AppBar, Box, Button, Checkbox, DialogContent, Divider, FormControlLabel, Grid, IconButton, Toolbar, Typography, } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { format } from 'date-fns';
import { styled } from '@mui/material/styles';
import AuthService, { GetAppName } from '../../Services/AuthService';
import HouseIcon from '@mui/icons-material/Home';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import moment from 'moment';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { GetVehicleRoute, SaveVehicleGPS } from '../../Services/Api/RidesServices';
import { useRef } from 'react';
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import RightDrawer from '../../Components/RightDrawer'
import localData from "../../Services/ride.json"
import RideDetails from '../../Components/RidesDetails';
const Root = styled(Box)(({ theme }) => ({
    height: '100%',
    padding: 0,
    '& .header-cont': {
        // width: "100%",
        width: "device-width",
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1,
        "& header": {
            display: "flex",
            height: 48,
            background: "white",
            fontSize: 12,
            alignItems: "center",
            '& img[alt="Logo"]': {
                width: 34,
                filter: 'invert(1)',
            },
            "& .headerNav": {
                // marginRight: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                "& .fuel": {
                    color: "red",
                    position: "absolute",
                    width: "26px",
                    right: "62px",
                    top: "12px",
                    cursor: "pointer"
                },
                "& .pageMainTitle": {
                    fontSize: 15,
                    fontWeight: 600,
                    whiteSpace: "nowrap"
                },
                "& .headerText": {
                    display: "flex",
                    color: '#1f0757',
                    fontSize: 13,
                    textShadow: '#1f0757',
                    fontWeight: 600,
                    whiteSpace: "nowrap"
                }
            }
        },
    },
    '& [data-disable="true"]': {
        pointerEvents: 'none',
        opacity: 0.5
    },
    "& a": {
        textDecoration: "none",
    },
    "& .mainContainer": {
        display: "flex",
        color: theme.palette.background.white,
        justifyContent: "center",
        height: "calc(100vh - 16px)",
        overflowY: "auto",
        "& .left": {
            backgroundColor: theme.palette.primary.main,
            display: "flex",
            height: "100vh",
            "& .leftContent": {
                padding: "10px 0px",
                width: "100%",
                display: "flex",
                marginTop: "48px",
                flexDirection: "column",
                '& .MuiAvatar-root': {
                    '& img': {
                        width: '80%',
                        height: '80%'
                    }
                },
                "& .livePassenger": {
                    width: "fit-content",
                    padding: "3px 6px",
                    backgroundColor: theme.palette.secondary.light,
                    "&:hover": {
                        backgroundColor: theme.palette.secondary.light,
                    },
                },
                "& .completedRides": {
                    width: "fit-content",
                    marginBottom: "5px",
                    padding: "3px 8px",
                    "&:hover": {
                        background: "#ffffff40",
                        boxShadow: "3px 1px 4px 0px #02383c"
                    },
                },
                "& .leftContentSelect": {
                    display: "flex",
                    flex: "0 0 66%",
                    alignItems: "center",
                    gap: "10px",
                    "& .greenDot": {
                        color: theme.palette.button.main,
                        marginBottom: "-5px",
                    }
                },
                "& .absoluteTime": {
                    position: "absolute",
                    right: "16px",
                    top: "62px",
                    "& > P": {
                        fontSize: "24px"
                    }
                },
                "& .fuel": {
                    position: "absolute",
                    right: "61px",
                    top: "12px",
                    zIndex: 9,
                    width: "28px",
                    opacity: "0.1"
                },
                "& .leftContentIcons": {
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    padding: "10px",
                    paddingRight: 0,

                    "& .topIcons": {
                        padding: 0,
                        position: "absolute",
                        right: "40px",
                        top: "12px",

                        "& svg": {
                            color: "black",
                            fontSize: "30px"
                        },
                        "& .refreshingIcon": {
                            position: "absolute",
                            right: "5px",
                            top: "4px",
                            width: 25,
                            height: 25,
                        },
                    },
                    [theme.breakpoints.up('lg')]: {
                        gap: "15px",
                    },


                },
                "& .isLoadingText": {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: 'center',
                    height: "80vh"
                },
                "& .textHeader": {
                    fontWeight: 400,
                    fontSize: 20,
                },
                "& .text-white": {
                    marginTop: 20,
                    borderRadius: "5px",
                    fontSize: 10,
                    marginBottom: 12,
                    padding: 4,
                    cursor: "pointer",
                    border: theme.palette.type === 'light' ? '1px solid transparent' : 'none',
                    '&[data-active="true"]': {
                        background: theme.palette.type === 'light' ? 'none' : '#0f34369e',
                        // boxShadow: theme.palette.type === 'light' ? 'none' : "3px 1px 4px 0px #02383c",
                        border: theme.palette.type === 'light' ? '1px solid black' : 'none',
                    },
                    [theme.breakpoints.up('md')]: {
                        minWidth: 330,
                    },
                },
                "& .button-white": {
                    borderRadius: "5px",
                    fontSize: 10,
                    padding: 0,
                    cursor: "pointer",
                    margin: "0px 10px",
                    border: theme.palette.type === 'light' ? '1px solid transparent' : 'none',
                    "&:hover": {
                        background: theme.palette.type === 'light' ? 'none' : '#0f34369e',
                        boxShadow: theme.palette.type === 'light' ? 'none' : "3px 1px 4px 0px #02383c",
                        border: theme.palette.type === 'light' ? '1px solid black' : 'none',
                    },
                    [theme.breakpoints.up('md')]: {
                        minWidth: 330,
                    },
                },
                "& .location-info": {
                    flex: "3",
                    paddinRight: 10,
                    '& > div': {
                        '& h6 + h6': {
                            textTransform: "capitalize",
                            "&.stop-status-arrived ": {
                                // color: theme.palette.type === "light" ? 'black' : 'white',
                                color: "#3aa7f7",
                            },
                            "&.stop-status-departed": {
                                color: theme.palette.button.main,
                            },
                            "&.stop-status-completed": {
                                color: theme.palette.button.main,
                            },
                        }
                    }
                },
                "& .location-info-lastspan": {
                    flex: " 1",
                    paddinRight: 10,
                    textAlign: "right",
                    color: theme.palette.type === "light" ? "black" : "white",
                    "& .statusDash": {
                        color: theme.palette.type === "light" ? "black" : "white"
                    }
                },
                "& .completeRidesButton": {
                    display: "flex",
                    alignItems: "center",
                    "& .MuiCheckbox-root": {
                        color: "white",
                        padding: "3px",
                        background: "#ffffff40",
                        boxShadow: "3px 1px 4px 0px #02383c",
                        "& .hover": {
                            background: "#ffffff40",
                            boxShadow: "3px 1px 4px 0px #02383c"
                        }
                    }
                },
                "& .CardView": {
                    overflowY: 'auto',
                    height: "calc(100vh - 64.5vh)",
                    margin: '0 10px',
                    [theme.breakpoints.up('sm')]: {
                        height: "calc(100vh - 59.8vh)"
                    },
                },
            }
        },
        "& .Wrapper1": {
            display: "flex",
            justifyContent: "space-between",
            fontWeight: 600,
            gap: 10,
            "& .tileIcon": {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: 36,
                width: 36,
                borderRadius: "50%",
                backgroundColor: theme.palette.primary.light,
                padding: 10,
                alignSelf: "center"
            }
        },
    },



    "& .css-1qggfp3-MuiButtonBase-root-MuiIconButton-root": {
        "&:hover": {
            backgroundColor: theme.palette.primary.main
        }
    }
}));
const DialogBox = styled(Dialog)(({ theme }) => ({

    "& .titleBoxDialogTwo": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "4px 8px",
        "& .MuiSvgIcon-root": {
            color: "black",
            fontSize: 30,
        },
    },
    "& .active": {
        backgroundColor: "#edaeae",
    },
    "& .routeTextDialogTwo": {
        textAlign: "center",
        fontSize: 14,
    },
    "& .fuelButton": {
        backgroundColor: "green",
        "&:hover": {
            backgroundColor: "green"
        }
    },
    "& .fuelfield": {
        "& .MuiInputBase-input ": {
            fontSize: 14,
            fontWeight: 600,
        },
    },
    "& .disBUtton": {
        backgroundColor: "#ccc"
    },
    "& .Checkbox": {
        display: "flex",
        gap: "5px",
        alignItems: "center",

    }
}));
export default function NewRide() {
    const [rides, setRides] = useState({
        data: null,
        message: "",
        date: null,
        callTaken: false,
    });
    const ridesDateRef = useRef(null);
    const [completedRides, setCompletedRides] = useState([]);
    const [completeButtonClicked, setCompleteButtonClicked] = useState(true);
    const [routes, setRoutes] = useState({});
    const [completedRidesCount, setCompletedRidesCount] = useState(0);
    const [stopStatus, setStopStatus] = useState("arrived");
    const [filterId, setFilterId] = useState(null);
    const [responceRide, setResponceRide] = useState(false);
    const [driverName, setDriverName] = useState("");
    const [vehicleName, setVehicleName] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [bottomDrawer, setBottomDrawer] = useState(true);
    const [geolocationPermission, setGeolocationPermission] = useState(null);
    const [nextStepIndex, setNextStepIndex] = useState(false);
    const [ridesDate, setRidesDate] = useState(null);
    const [dialogText, setDialogText] = useState('');
    const [openDialogTwo, setOpenDialogTwo] = React.useState(false);
    const firstLoad = useRef(true);
    const st = useRef(false)
    const [timeDifferencecounter, setTimeDifferencecounter] = useState();
    const [timewaiting, setTimewaiting] = useState(false);
    const [prevLatitude, setPrevLatitude] = useState(null);
    const [prevLongitude, setPrevLongitude] = useState(null);
    const [dataLoader, setDataLoadder] = useState(true);
    const [rideMergedata, setRideMergedata] = useState([]);
    const [isLocation, setIsLocation] = useState(false);
    const [appName, setAppName] = React.useState('');
    const [logo, setLogo] = React.useState('');
    const [isROpen, setIsROpen] = React.useState('');

    // const currentLocation = useRef(null);
    let navigate = useNavigate();

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        return [year, month, day].join('-');
    }
    // app name
    useEffect(() => {
        let tagid = AuthService.getAuthDataByName("deviceTagID")
        if (appName === "My Senior Center" && tagid !== null) {
            GetApplicationName(tagid);
        }

        setLogo(AuthService.getAppDataByName("Logo"));
        let applicationName = AuthService.getAppDataByName("AppName");
        if (applicationName === null || applicationName === "") {
            setAppName("My Senior Center");

        } else {
            setAppName(AuthService.getAppDataByName("AppName"));

        }
    }, [appName])
    const GetApplicationName = async (id) => {
        await GetAppName(id)
            .then((res) => {
                let data = res.data
                if (Object.keys(data).length && data !== null) {
                    AuthService.setAppData("AppName", res.data.Name)
                    AuthService.setAppData("Logo", res.data.Logo)
                } else {
                    AuthService.setAppData("AppName", "My Senior center")
                }
            })
            .catch((err) => {
                console.log(err)
                AuthService.setAppData("AppName", "My Senior center")
            })
    }
    setTimeout(() => {
        setLogo(AuthService.getAppDataByName("Logo"));
        setAppName(AuthService.getAppDataByName("AppName"));
    }, 1000)
    // others
    useEffect(() => {
        let ProcessCompleted = AuthService.getAuthDataByName("processStep") || "";
        let Token = AuthService.getAuthDataByName("token") || "";
        if (Token === "") {
            navigate("/");
        } else {
            if (!ProcessCompleted || ProcessCompleted === 1 || ProcessCompleted === 0 || ProcessCompleted === 2) {
                navigate("/vehicle")
            } else {
                if (!rides.callTaken) {
                    rides.callTaken = true;
                    getRideData();
                }
                else {
                    return;
                }
            }
        }// eslint-disable-next-line 
    }, [])
    React.useEffect(() => {
        const checkDateInterval = setInterval(checkForDateChange, 60000);
        return () => {
            clearInterval(checkDateInterval);
        }
    }, [])
    React.useEffect(() => {
        if (Object.keys(routes).length) {
            handleWaiting()
        }
    }, [filterId])
    useEffect(() => {
        const trackLocation = () => {
            if (navigator.geolocation) {
                const id = navigator.geolocation.watchPosition(
                    (position) => {
                        setIsLocation(true)
                        const currentLatitude = position.coords.latitude;
                        const currentLongitude = position.coords.longitude;
                        setGeolocationPermission(true);
                        if (currentLatitude !== prevLatitude || currentLongitude !== prevLongitude) {
                            console.log('Latitude:', currentLatitude.toFixed(6));
                            console.log('Longitude:', currentLongitude.toFixed(6));
                            setPrevLatitude(currentLatitude);
                            setPrevLongitude(currentLongitude);
                            let gsid = AuthService.getAuthDataByName("gsid");
                            let vehicleUID = AuthService.getAuthDataByName("vehicleUID");
                            let DateTimeUTC = moment.utc();
                            let stopId = filterId ? filterId : 6435;
                            let gpsdata = {
                                CurrentStopId: stopId,
                                DateTimeUTC: DateTimeUTC,
                                vehicleUID,
                                lat: currentLatitude,
                                lng: currentLongitude,
                                Elevation: position.coords.accuracy,
                                // Velocity:velocity
                            }
                            setTimeout(() => {
                                SaveVehicleGPS(gsid, gpsdata)
                            }, 60000);

                        }
                    },
                    (error) => {
                        console.log(error.message);
                        setIsLocation(false)
                    },
                    { enableHighAccuracy: true, timeout: 60000 }
                );

                return id;
            } else {
                console.log('Geolocation is not supported by this browser.');
            }
        };
        const id = trackLocation();
        return () => {
            if (id) {
                navigator.geolocation.clearWatch(id);
            }
        };
    }, [prevLatitude, prevLongitude]);
    // useEffect(() => {
    //     if (props.drawer === true) {
    //         setTimeout(() => {
    //             setBottomDrawer(props.drawer);
    //         }, 500)
    //     } else {
    //         setBottomDrawer(props.drawer);
    //     }

    // }, [props.drawer]);
    // useEffect(() => {
    //     setBottomDrawer(!isROpen);
    // }, [isROpen]);


    const handlestateFromDrawer = (value) => {
        setIsROpen(value);
    }
    //first time data loaded in bottom  drawer
    setTimeout(() => {
        if (firstLoad.current === true) {
            firstLoad.current = false
            setDataLoadder(false)
            setBottomDrawer(true)
        }
    }, 1000)
    // Date Switcher
    function checkForDateChange() {
        if (!!ridesDateRef.current) {
            let oldDay = ridesDateRef.current.getDate();
            let newDay = new Date().getDate()
            if (oldDay !== newDay) {
                getRideData();
            }
        }
    }
    // handle location on button
    const handleLocationON = () => {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(
                position => {
                    setGeolocationPermission(true);
                },
                error => {
                    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
                    const isAndroid = /Android/.test(navigator.userAgent);
                    if (isIOS) {
                        // Show iOS-specific message to enable location services
                        setDialogText('Location is not enabled on Mobile Safari. You need to enable it manually. You can go to Settings -> Privacy & Security -> Location Services -> Safari Websites Change setting "ALLOW LOCATION ACCESS" to While Using the App.');
                        setOpenDialogTwo(true);
                    } else if (isAndroid) {
                        // Show Android-specific message to enable location services
                        setDialogText('Your Android location is turned off. To turn it on, go to Settings > Location and toggle the Switch to On.');
                        setOpenDialogTwo(true);
                    } else {
                        setDialogText('Location permission denied.');
                        setOpenDialogTwo(true);
                    }
                }
            );
        } else {
            alert('Geolocation is not supported by this browser.')
        }
    }
    //get all routes
    const getDriverData = async (gsid, vehicleUID, date) => {
        let activeTile = filterId;
        fakeLoader(1000)
        // Online Data Start
        await GetVehicleRoute(gsid, vehicleUID, date)
            .then(ridesData => {
                if (ridesData?.data?.length) {

                    // Initial Date
                    setRidesDate(new Date())
                    ridesDateRef.current = new Date();
                    // Other
                    setRideMergedata(ridesData.data)
                    const data = ridesData.data
                    const mergedStops = data.reduce((accumulator, current) => {
                        const lastIndex = accumulator.length - 1;
                        if (lastIndex >= 0 && accumulator[lastIndex].locationInfo.name === current.locationInfo.name) {
                            accumulator[lastIndex].departureTime = current.departureTime;
                            accumulator[lastIndex].requestsOn.push(...current.requestsOn);
                            accumulator[lastIndex].requestsOff.push(...current.requestsOff);
                        } else {
                            accumulator.push(current);
                        }
                        return accumulator;
                    }, []);
                    setRides({ ...rides, data: mergedStops })
                    let CompleteRides = mergedStops.filter(item => item.stopStatus !== "departed")
                    setCompletedRides(CompleteRides)
                    setFilterId(activeTile ? activeTile : CompleteRides[0].stopID)
                    let CompleteRidesLength = mergedStops.filter(item => item.stopStatus === "departed")
                    setRoutes(CompleteRides[0])
                    setCompletedRidesCount(CompleteRidesLength.length)
                    setCompleteButtonClicked(true)
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    }
    // localData
    //   const data = localData
    //   const mergedStops = data.reduce((accumulator, current) => {
    //     const lastIndex = accumulator.length - 1;
    //     if (lastIndex >= 0 && accumulator[lastIndex].locationInfo.name === current.locationInfo.name) {
    //       accumulator[lastIndex].departureTime = current.departureTime;
    //       accumulator[lastIndex].requestsOn.push(...current.requestsOn);
    //       accumulator[lastIndex].requestsOff.push(...current.requestsOff);
    //     } else {
    //       accumulator.push(current);
    //     }
    //     return accumulator;
    //   }, []);
    //   setRides({ ...rides, data: mergedStops })
    //   setRoutes(mergedStops[0])
    //   setFilterId(activeTile ? activeTile : mergedStops[0].stopID)
    //   let CompleteRides = mergedStops.filter(item => item.stopStatus !== "departed")
    //   setCompletedRides(CompleteRides)
    //   let CompleteRidesLength = mergedStops.filter(item => item.stopStatus === "departed")
    //   setCompletedRidesCount(CompleteRidesLength.length)
    //   setCompleteButtonClicked(true)
    //   setIsLoading(false)
    // }
    const getRideData = async () => {
        let gsid = AuthService.getAuthDataByName("gsid") || "";
        let deviceSecret = AuthService.getAuthDataByName("deviceSecret") || "";
        let driverName = AuthService.getAuthDataByName("selectedDriver") || "";
        let vehicleName = AuthService.getAuthDataByName("selectedVehicle") || "";
        let vehicleUID = AuthService.getAuthDataByName("vehicleUID") || "";
        if (!driverName && !vehicleName) {
            navigate("/vehicle");
        }
        setDriverName(driverName)
        setVehicleName(vehicleName)
        AuthService.setUserData("deviceSecret", deviceSecret)
        let date = formatDate(new Date());
        getDriverData(gsid, vehicleUID, date);
    }
    // time and date formater
    const timeFormate = (time) => {
        var localtime
        if (time) {
            localtime = new Date(time);
            return localtime.toLocaleTimeString([], { hour: 'numeric', minute: 'numeric', hour12: true }).toUpperCase();
        } else {
            localtime = new Date();
            return localtime.toLocaleTimeString([], { hour: 'numeric', minute: 'numeric', hour12: true }).toUpperCase();
        }

    }
    const formateDate = (dataT) => {
        if (dataT) {
            const date = new Date(dataT);
            const formattedDate = format(date, 'EEEE, MMM do, yyyy');
            return formattedDate;
        } else {
            const date = new Date();
            const formattedDate = format(date, 'EEEE, MMM do, yyyy');
            return formattedDate;
        }
    };
    var second;
    //new
    const handleWaiting = () => {
        let currentWaitingIds = JSON.parse(localStorage?.getItem("currentWaitingIds") || "[]");
        let currentWaitings = JSON.parse(localStorage?.getItem("currentWaitings") || "[]");
        const date1 = new Date(routes.departureTime).getTime();
        const date2 = new Date(routes.arrivalTime).getTime();
        let differenceInMilliseconds = date1 - date2;
        let allowTimer = currentWaitingIds.findIndex(x => x === routes.routeID);
        let alreadyDepartedIndex = currentWaitings.findIndex(x => x.routeID === routes.routeID);


        if (differenceInMilliseconds <= 120000 || allowTimer === -1) {
            differenceInMilliseconds = 0;
        }
        else if (alreadyDepartedIndex === -1) {

            let updatedTime = new Date().getTime() + differenceInMilliseconds;
            let remainingTime = updatedTime - new Date().getTime();
            let obj = { routeID: routes.routeID, updatedTime: updatedTime };
            currentWaitings.push(obj);
            localStorage.setItem("currentWaitings", JSON.stringify(currentWaitings));
            // Update as per new average
            differenceInMilliseconds = remainingTime <= 0 ? 0 : remainingTime;
        }
        else {
            let remainingTime = currentWaitings[alreadyDepartedIndex].updatedTime - new Date().getTime();
            // Update as per new average
            differenceInMilliseconds = remainingTime <= 0 ? 0 : remainingTime;
        }
        second = (differenceInMilliseconds / 1000).toFixed(0);
        setTimeDifferencecounter(second);
        localStorage.setItem("time", second)
        if (second > 125) {
            setTimewaiting(true);
        } else {
            setTimewaiting(false);
        }
    }
    // filter data when click on tile
    const handleFilter = (id) => {
        setFilterId(id);
        localStorage.setItem("ACTIVE_TILE", id)
        setBottomDrawer(true);
        let dataRoute = rides?.data.find(item => item.stopID === id);
        setRoutes(dataRoute);
    }
    // dash between status in tile
    const handleDash = (e) => {
        if (e.stopStatus === "completed" || e.stopStatus === "arrived" || e.stopStatus === "departed") {
            return <span className='statusDash' >-</span>;
        }
    }
    //back to Select Vehicle
    const goToVehicle = () => {
        navigate("/vehicle")
    }
    //back to Select Driver
    const goToDriver = () => {
        navigate("/driver")
    }
    //Fake Loader
    const fakeLoader = (timeout = 1000) => {
        setResponceRide(true)
        setTimeout(() => {
            setResponceRide(false)
        }, timeout);
    }
    // Update State from Child 
    const handlestateFromChild = (name, value) => {
        switch (name) {
            case "setStopStatus":
                setStopStatus(value);
                break;
            case "setResponceRide":
                setResponceRide(value);
                break;
            case "setRides":
                setRides({ ...rides, data: value });
                break;
            case "setBottomDrawer":
                setBottomDrawer(value)
                break;
            case "setNextStepIndex":
                setNextStepIndex(value)
                break;
            case "setFilterId":
                let routesData = rides?.data.find(item => item.stopID === value);
                localStorage.setItem("ACTIVE_TILE", value)
                setRoutes(routesData);
                setFilterId(value)
                break;
            case "setCompletedRides":
                setCompletedRides(value)
                break;
            case "setCompletedRidesCount":
                setCompletedRidesCount(value)
                break;
            case "setCompleteButtonClicked":
                setCompleteButtonClicked(value)
                break;
            default:
                break;
        }
    }
    // Tile Status
    const handleTileStatus = (e) => {
        if (e.stopStatus === "arrived") {
            return "Driving";
        } else if (e.stopStatus === "departed") {
            return "complete";
        } else if (e.stopStatus === "notarrived") {
            return "";
        } else if (e.stopStatus === "completed") {
            return "complete";
        } else {
            return "";
        }
    }
    //show and hide completed rides
    const handleCompletedRides = (e) => {
        if (completeButtonClicked) {
            setCompleteButtonClicked(!completeButtonClicked)
            setCompletedRides(rides.data)
        } else {
            let CompleteRides = rides.data.filter(item => item.stopStatus !== "departed")
            setCompletedRides(CompleteRides)
            setCompleteButtonClicked(!completeButtonClicked)
        }
    }
    const handleCross2 = () => {
        // st.current = true;
        setOpenDialogTwo(false);
    };
    const checkboxChecked = (e) => {
        st.current = e.target.checked;
        setOpenDialogTwo(false);
    }
    const handleBackdropClick = (event) => {
        // Prevent closing the dialog when clicking outside of it
        event.stopPropagation();
    };
    const addNameInObject = () => {
        rideMergedata.forEach((e, i) => {
            const date2 = new Date(e.arrivalTime);
            const date1 = new Date(e.departureTime);
            const differenceInMilliseconds = date1 - date2;
            second = differenceInMilliseconds / 1000;

            if (second > 120) {
                rideMergedata[i].name = "duplicateData";
            }

        });
    }
    useEffect(() => {
        addNameInObject()
    })


    return (
        // <DefaultLayout>
        <Root p={2} >
            {/* Header */}
            <Box className="header-cont">
                <Box sx={{ flexGrow: 1 }}>
                    <AppBar position="static">
                        <Toolbar sx={{ width: "100%", maxWidth: "100%", padding: "0px 15px 0px 45px !important" }}>
                            <Box className="headerNav">
                                <Box
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    aria-label="menu"
                                    sx={{ mr: 2, display: "inline-flex", alignItems: "center" }}>
                                    {logo ? <img src={logo} alt="Logo" style={{ marginRight: 10 }} />
                                        :
                                        <img src={require('../../Assets/icons/msc_heart.png')} alt="Logo" style={{ marginRight: 10 }} />}
                                    <div>
                                        {appName ? <Typography color="black" className="pageMainTitle" id="pageTitle">
                                            {appName}
                                        </Typography> : <Typography color="black" className="pageMainTitle" id="pageTitle">
                                            My Senior Center
                                        </Typography>}
                                    </div>
                                </Box>
                                <RightDrawer handlestateFromDrawer={handlestateFromDrawer} />
                            </Box>
                        </Toolbar>
                    </AppBar>
                </Box>
            </Box>
            {/* Content */}
            <Box className="mainContainer">
                <Grid container spacing={2}>
                    <Grid className="left" item xs={12} md={12} >
                        <Box className="leftContent">
                            {geolocationPermission ? null : (<Button onClick={() => handleLocationON()} className='livePassenger' variant='contained' startIcon={<LocationOnIcon />}>Enable Live Passenger Data</Button>)}
                            <Box sx={{ display: "flex", justifyContent: "space-between", height: "5%", padding: "0 10px" }}>
                                <Box className="leftContentSelect">
                                    <Typography sx={{ cursor: "pointer" }} onClick={goToDriver} variant="span">{driverName}</Typography>
                                    <Typography onClick={goToVehicle} sx={{ fontSize: 10, cursor: "pointer" }} variant="span">{vehicleName}</Typography>
                                    <Typography className='greenDot' ><FiberManualRecordIcon fontSize='10' /></Typography>
                                </Box>
                            </Box>
                            <Box className="absoluteTime">
                                {rides.data !== null ? <Typography>{timeFormate(routes.arrivalTime)}</Typography> : ""}
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: 2, height: "5%", padding: "0 10px" }}>
                                <Box>
                                    <Typography className="textHeader" variant='h5'> {formateDate(ridesDate)} </Typography>
                                    <Typography variant="caption">{rides?.data?.length ? rides.data.length : "0"} Destinations Today</Typography>
                                </Box>
                            </Box>
                            {completedRidesCount > 0 ? <Box className={`button-white`}  >
                                <Box className="Wrapper1" sx={{ alignItems: "center", marginLeft: "3px", padding: "8px 0px" }} onClick={() => handleCompletedRides()} >
                                    <Box className="tileIcon"> <RemoveRedEyeIcon sx={{ fontSize: "1.5rem !important" }} /> </Box>
                                    <Box className="location-info">
                                        <Typography variant='subtitle1'  >   {completedRidesCount} Completed Ride{completedRidesCount > 1 ? "s" : ""} </Typography>
                                    </Box>
                                </Box>
                            </Box> : ""}
                            <Box className="CardView" sx={{}}>
                                {rides.data !== null ?
                                    completedRides.map((e, i) => (
                                        <Box key={i}>
                                            <Box className={`text-white mt-5`} onClick={() => handleFilter(e.stopID)} data-active={filterId === e.stopID} >
                                                <Box className="Wrapper1">
                                                    <Box className="tileIcon"> <HouseIcon sx={{ fontSize: "1.5rem !important" }} /> </Box>
                                                    <Box className='location-info'>
                                                        <Box sx={{ display: "flex", flexDirection: "row", gap: "2px", }}>
                                                            <Typography sx={{ lineHeight: "150%", maxHeight: "50px", overflow: "hidden" }} variant="subtitle1">{e.locationInfo.name}</Typography>
                                                            <Typography sx={{ lineHeight: "150%", whiteSpace: "nowrap" }} className={`stop-status-${e.stopStatus}`} variant="subtitle2">{handleDash(e)}{handleTileStatus(e)}</Typography>
                                                        </Box>
                                                        <Typography sx={{ display: "flex", }} variant="caption">{e.locationInfo.address}, {e.locationInfo.city}, {e.locationInfo.state}</Typography>
                                                    </Box>
                                                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                                                        <Typography className='location-info-lastspan' variant="caption">{`A: ${timeFormate(e.arrivalTime)}`}</Typography>
                                                        {e.name ? <Typography className='location-info-lastspan fff' variant="caption">{`D: ${timeFormate(e.departureTime)}`}</Typography> : ""}
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    )) : isLoading ? <>
                                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "60vh" }}>
                                            <Typography variant='subtitle1'>Loading...</Typography>
                                        </Box>
                                    </> : ""}
                            </Box>

                            <RideDetails timewaiting={timewaiting} dataLoader={dataLoader} startTimer={handleWaiting} timeDifferencecounter={timeDifferencecounter} handlestateFromChild={handlestateFromChild} completeButtonClicked={completeButtonClicked} filterId={filterId} completedRidesCount={completedRidesCount} completedRides={setCompletedRides} nextStepIndex={nextStepIndex} toggleDrawer={(value) => setBottomDrawer(value)} rides={rides} routes={routes} responceRide={responceRide} stopStatus={stopStatus} />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            {/* Dialog */}
            <DialogBox
                open={openDialogTwo}
                onClose={handleCross2}
                onClick={handleBackdropClick}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Box className="titleBoxDialogTwo">
                    <Typography variant='subtitle2'> Location Not Enabled</Typography>
                    <IconButton onClick={handleCross2}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Divider />
                <DialogContent>
                    <Box className="routeBoxInner">
                        <Box className="routeTextDialogTwo"> <Typography variant="p"> {dialogText} </Typography> </Box>
                        <Box className="Checkbox" >
                            <FormControlLabel
                                control={
                                    <Checkbox onChange={(e) => checkboxChecked(e)} />
                                }
                                label={<Typography variant="body2" >Don't show again</Typography>}
                            /></Box>
                    </Box>
                </DialogContent>
            </DialogBox>
        </Root >
        // </DefaultLayout>
    )
}