import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const Root = styled(Box)(({ theme }) => ({
    height: '100vh',
    backgroundImage: `url(${require('../Assets/loginBackgroundImg/img1.png')})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundColor: "#f8e9f7",
    overflow: "hidden",

    '& img[alt="Logo"]': {
        maxWidth: 100,
        marginBottom: theme.spacing(2),
    },

    '& .MuiTypography-h1': {
        fontSize: '1.5rem',
    },

    '& .MuiButton-root': {
        margin: theme.spacing(2, 'auto'),
    },
}));

export default function Forgot() {

    const navigate = useNavigate();
    React.useEffect(() => {
        if (window.location.pathname == "/gotomeal") {
            navigate("/meal")
        } else if (window.location.pathname == "/gotoactivity") {
            navigate("/activties")
        }

    }, [])
    return (
        <Root p={2} sx={{ display: 'flex' }}>
            <Box className="notFound" alignItems="center" justifyContent="center" flexDirection="column" m={"auto"} sx={{ display: 'flex', width: '100%' }} autoComplete="off">
                <img src={`${window.location.origin}/images/logo.png`} alt="Logo" />
                <Typography variant="h1" gutterBottom>404 Not Found</Typography>
                <Typography variant="body1" gutterBottom sx={{ textAlign: 'center' }}>We are unable to find the resource.</Typography>
                <Button onClick={() => navigate(-1)} variant="contained" color='success'>Go Back</Button>
            </Box>
        </Root>
    )
}