import { useNavigate } from "react-router-dom";
import constant from "./Constant";
const Protected = ({ children, mode, name }) => {
    const navigate = useNavigate();
    const host = window.location.hostname;
    if (mode == "driver" && constant.driverProtectedDomains.includes(host)) {
        return children;
    } else if (mode == "volunteer" && constant.volunteerProtectedDomains.includes(host)) {
        return children;

    } else if (mode == "meals" && constant.mealsProtectedDomains.includes(host)) {
        return children;

    }

    navigate('/noaccess', { state: { name } })
};
export default Protected;