import React, { useEffect, useState } from "react";
import { Box, Button, Dialog, DialogContent, DialogContentText, IconButton, TextField, Typography, Avatar, Badge, Divider, } from "@mui/material";
import { GetSeniorsByRoute, GetAllSeniors, getSeniorDetailByID, GetAllKeytags, GetSeniorByKeytag } from "../../Services/Api/MealService";
import AuthService, { GetAppName } from "../../Services/AuthService";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import PhoneIcon from "@mui/icons-material/Phone";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import SearchIcon from "@mui/icons-material/Search";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoneIcon from "@mui/icons-material/Done";
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
const Root = styled(Box)(({ theme }) => ({
  height: "100%",
  padding: 0,
  "& ~ .MuiPopover-root": {
    "& div": {
      "& ul": {
        "& li": {
          backgroundColor: "red",
        },
      },
    },
  },

  '& .main_container': {
    // minHeight: "100vh",
    marginTop: "40px",
    // padding: "8px ",
    // backgroundColor: theme.palette.type == 'dark' ? '#343945' : "#FBFBFD",

  },
  "& .avatarContainer": {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    "& .name": {
      color: theme.palette.type == 'dark' ? '#fff' : "#000",
      fontWeight: 500
    },
    "& .cameraIcon": {
      width: "30px",
      height: "30px",
      backgroundColor: theme.palette.type == 'dark' ? '#2196f3' : "grey",
      color: "#fff",
      borderRadius: '50%',
      padding: "5px"
    }
  },
  "& .detail_container": {
    backgroundColor: "#FFFFFF",
    padding: "8px",
    border: "1px solid #c4c4c4",
    borderRadius: "5px",
    marginBottom: "10px",
    "& .items": {
      display: "flex",
      justifyContent: "space-between",
      padding: "8px 0px"
    },
    // "& .typo_text": {
    //   fontSize: "16px"
    // }
  },



  "& .mainContainer": {
    backgroundImage: `url(${require("../../Assets/loginBackgroundImg/img1.png")})`,
    backgroundSize: "cover",
    overflow: "hidden",
    backgroundPosition: "center",
    height: "calc(100vh - 48px)",
    marginTop: 48,
    overflowY: "auto",
    "& .miniNav": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
      background: "white",
      position: "relative",
      borderTop: "1px solid #ccc",
      padding: "0 20px",
      "& .MuiInputBase-root": {
        border: '1px solid #ccc',
        "& .MuiInputBase-input": {
          fontSize: 16,
          fontWeight: 300,
          color: "black",
        },
      },
      "& .searchBtn": {
        position: "fixed",
        top: 12,
        right: 56,
      },
      "& .headerText": {
        color: "black",
        fontSize: 16,
        fontWeight: 500,
      },
    },
    "& .content": {
      margin: "20px auto",
      width: "100%",
      minWidth: 275,
      padding: 10,
      backgroundColor: theme.palette.primary.contrastText,
      boxShadow: "0px 3px 15px -6px rgb(0 0 0 / 50%)",
      borderRadius: 10,
      "& .mealInfo": {
        position: 'relative',
      },
      "& .mealBtns": {
        marginTop: 5,
      },
      "& .outlineButton": {
        fontSize: 18,
        color: theme.palette.other.main,
        borderColor: theme.palette.other.main,
        borderRadius: 20,
      },
      "& .containedButton": {
        fontSize: 18,
        color: theme.palette.primary.contrastText,
        borderColor: theme.palette.other.main,
        borderRadius: 20,
        backgroundColor: theme.palette.other.main,
      },
      "& .routetextColor": {
        fontSize: 23,
        fontWeight: 400,
        color: theme.palette.other.main,
      },
      "& .textColor": {
        fontSize: 16,
        fontWeight: 400,
        color: theme.palette.primary.dark,
      },
      "& .images": {
        height: "50px",
        width: "50px",
        borderRadius: "50%",
        overflow: "hidden",
        "& img": {
          objectFit: "contain",
          width: "100%",
          height: "100%"
        }
      },
      // "& .images": {
      //   position: 'absolute',
      //   top: 0,
      //   right: 0,
      // },
    },
  },
  '& img[alt="mealpng"]': {
    width: 60,
  },
  "& .mainCotainer2": {
    position: "fixed",
    left: "50%",
    top: 2,
    width: "100%",
    zIndex: 1,
    height: "48px",
    transform: "translateX(-50%)",
    "& .headerNav": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
      backgroundColor: "white",
      height: 47,
      borderBottom: "1px solid #00000033",
      "& .pageMainTitle": {
        fontSize: 15,
        fontWeight: 600,
        whiteSpace: "nowrap"
      },
      "& .headerText": {
        display: "flex",
        color: '#1f0757',
        fontSize: 13,
        textShadow: '#1f0757',
        fontWeight: 600,
        whiteSpace: "nowrap"
      },
      "& img": {
        width: 26,
        filter: "invert(1)",
      },
    },
  },
}));
export default function RouteSenior(props) {
  const dummyData = {
    "seniorID": 988,
    "firstName": "Janice",
    "lastName": "Abare",
    "city": "Sterling",
    "photoURL": "https://s3.amazonaws.com/myseniorcenter-tenants/_common/female.png"
  }

  // Custom Styles:
  const menuItemStyles = { color: "#1f0757", fontWeight: "600" };
  let location = useLocation();
  let id = location.state;
  if (!!id) {
    localStorage.setItem("MealId", id);
  }
  if (!id) {
    id = parseInt(localStorage.getItem("MealId"));
  }
  const [state, setState] = useState({
    routes: [],
    seniorId: [],
    seniorsList: [],
    filteredSeniorList: [],
  });
  const [loader, setLoader] = useState(false);
  const [appName, setAppName] = React.useState('');
  const [dialogInfo, setDialogInfo] = useState({
    mainNumber: "",
    EmergencyInfo: "",
  });
  const [checkIcon, setCheckIcon] = useState(true);
  const [checkIconSort, setCheckIconSort] = useState(false);
  const [notes, setNotes] = useState();
  const [count, setCount] = useState(0);
  const [openSearch, setOpenSearch] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menu, setMenu] = React.useState(null);
  const [logo, setLogo] = React.useState('');
  const [tags, setTags] = React.useState([]);
  const [seniorByIdDetail, setSeniorByIdDetail] = React.useState({});
  // const [seniorByTag, setSeniorByTag] = React.useState({});
  // const [keyTags, setKeyTags] = React.useState([]);
  useEffect(() => {
    let tagid = AuthService.getAuthDataByName("deviceTagID")
    if (appName === "My Senior Center" && tagid !== null) {
      GetApplicationName(tagid);
    }
    let applicationName = AuthService.getAppDataByName("AppName");
    if (applicationName === null || applicationName === "") {
      setAppName("My Senior Center");
    } else {
      setAppName(AuthService.getAppDataByName("AppName"));
      setLogo(AuthService.getAppDataByName("Logo"));
    }

    // if (state.routes.length === 0) {
    //   if (id) {
    //     GetSeniorList(id);
    //   } else {
    //     GetInitialDetails();
    //   }
    // }
    // eslint-disable-next-line
  }, [state.routes.length]);

  //New Work 
  useEffect(() => {
    // getAllKey()
    // getSeniorDetail()
    let Seniors = JSON.parse(localStorage.getItem("Seniors"))
    let currentSenior = Seniors.find(e => e.seniorID === id)
    setSeniorByIdDetail(currentSenior)
    getTagsBySeniorID()
  }, [])

  const getSeniorDetail = async () => {
    setLoader(true)
    try {
      let res = await getSeniorDetailByID(id);
      setSeniorByIdDetail(res.data[0])
      getTagsBySeniorID()
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false)
    }
  }

  const getTagsBySeniorID = () => {
    let allTags = JSON.parse(localStorage.getItem("KayTags"))
    let currentSeniorTags = allTags?.filter(t => t.seniorID === id)
    setTags(currentSeniorTags);
  }


  // const getSeniorByKey = async (id) => {
  //   try {
  //     let res = await GetSeniorByKeytag(id);
  //     setSeniorByTag(res.data[0])
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }
  // const getAllKey = async () => {
  //   try {
  //     let res = await GetAllKeytags();
  //     // setKeyTags(res.data)

  //     let selectedSenior = res.data?.find(x => x.seniorID === id);
  //     if (selectedSenior) {
  //       getSeniorByKey(selectedSenior.cardID)
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }



  const GetApplicationName = async (id) => {
    await GetAppName(id)
      .then((res) => {
        let data = res.data
        if (Object.keys(data).length && data !== null) {
          AuthService.setAppData("AppName", res.data.Name)
          AuthService.setAppData("Logo", res.data.Logo)
        } else {
          AuthService.setAppData("AppName", "My Senior center")
        }
      })
      .catch((err) => {
        console.log(err)
        AuthService.setAppData("AppName", "My Senior center")
      })
  }
  let navigate = useNavigate();

  //If Route Id available
  const GetSeniorList = async () => {
    let seniorId = await GetSeniorsByRoute(id);
    let seniorIdData = seniorId.data;
    setState({
      ...state,
      seniorsList: seniorIdData,
      filteredSeniorList: seniorIdData,
    });
  };
  //If route id not available than all senior show
  const GetInitialDetails = async () => {
    let seniorsList = await GetAllSeniors();
    console.log(seniorsList)
    let seniorsListData = seniorsList.data;
    setState({ ...state, filteredSeniorList: seniorsListData });
  };
  //Open Dialogs
  const [openContact, setopenContact] = React.useState(false);
  const [openMenu, setOpenMenu] = React.useState(false);
  //Contact Dialog open
  const handleClickOpen = (phone, contact, name) => {
    let einfo = `${name} ${contact}`;
    setDialogInfo({ ...dialogInfo, mainNumber: phone, EmergencyInfo: einfo });
    setopenContact(true);
  };
  //Contact Dialog Close
  const handleCloseContact = () => {
    setopenContact(false);
  };
  //Notes Dialog Open
  const handleClickOpenMenu = (notes) => {
    setNotes(notes);
    setOpenMenu(true);
  };
  //Notes Dialog Close
  const handleCloseMenu = () => {
    setOpenMenu(false);
  };
  //Open Dialogs  sortBy
  const toggleMenu = (event, menuName) => {
    handleCountDeliverShow();
    setAnchorEl(event.currentTarget);
    if (menu == null) {
      setMenu(menuName);
    } else if (menu !== menuName) {
      setMenu(menuName);
    } else {
      setMenu(null);
    }
  };
  //Close Dialogs  sortBy
  const handleCloseSort = () => {
    setAnchorEl(null);
    setMenu(null);
  };
  //Close Dialogs filter
  const handleCloseFilter = () => {
    setMenu(null);
  };
  // Dialogs handler
  const handleSortByName = () => {
    const SortedData = state.seniorsList.sort((a, b) => {
      if (a.FirstName < b.FirstName) {
        return -1;
      }
      if (a.FirstName > b.FirstName) {
        return 1;
      }
      return 0;
    });
    setState({ ...state, filteredSeniorList: SortedData });
    setCheckIconSort(true);
    handleCloseSort();
  };
  const handleSortById = () => {
    const SortedData = state.seniorsList.sort((a, b) => {
      if (a.Postion < b.Postion) {
        return -1;
      }
      if (a.Postion > b.position) {
        return 1;
      }
      return 0;
    });
    setState({ ...state, filteredSeniorList: SortedData });
    setCheckIconSort(false);
    handleCloseSort();
  };
  const handleOpenSearchBar = () => {
    setOpenSearch(!openSearch);
  };
  const handleSearch = (e) => {
    if (!(e.target.value == null || e.target.value === "")) {
      const FilteredData = state.seniorsList.filter((d) =>
        d.FirstName.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setState({ ...state, filteredSeniorList: FilteredData });
    } else {
      setState({ ...state, filteredSeniorList: state.seniorsList });
    }
  };
  // Flter and Hide Deliver
  const handleFlterDeliverHide = () => {
    const filterDeliverData = state.seniorsList.filter(
      (f) => f.Delivered === false
    );
    setState({ ...state, filteredSeniorList: filterDeliverData });
    setCheckIcon(true);
    handleCloseFilter();
  };
  // Flter and Show Deliver
  const handleFlterDeliverShow = () => {
    const filterDeliverData = state.seniorsList.filter(
      (f) => f.Delivered === true
    );
    setState({ ...state, filteredSeniorList: state.seniorsList });
    setCount(filterDeliverData.length);
    setCheckIcon(false);
    handleCloseFilter();
  };
  const handleCountDeliverShow = () => {
    const filterDeliverData = state.seniorsList.filter(
      (f) => f.Delivered === true
    );
    setCount(filterDeliverData.length);
  };

  return (
    <Root p={2}>
      <Box
        className="mainCotainer2"
        sx={{ width: "100%", maxWidth: "100%", padding: "0px 15px 0px 45px" }}
      >
        <Box className="headerNav">
          <Box size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2, display: "inline-flex", alignItems: "center" }} >
            {logo ? <img src={logo} alt="Logo" style={{ marginRight: 10 }} />
              : <img src={require("../../Assets/icons/msc_heart.png")} alt="Logo" style={{ marginRight: 10 }} />}

            <Box>
              {appName ? <Typography color="black" className="pageMainTitle" id="pageTitle">
                {appName}
              </Typography> : <Typography color="black" className="pageMainTitle" id="pageTitle">
                My Senior Center
              </Typography>}
              <Typography variant="p" component="div" className="headerText"> Seniors </Typography>
            </Box>
          </Box>
          <Box sx={{ display: "flex", gap: 1 }}>

            <Box className="searchBtn" onClick={handleOpenSearchBar}> <SearchIcon /> </Box>
          </Box>
        </Box>
      </Box>
      <Box className="mainContainer">
        {/* <Box sx={{ flexGrow: 1 }}>
          <Box className="miniNav">
            {openSearch ? (<TextField id="standard-basic" sx={{ marginBottom: 0 }} label="" placeholder="Type Keyword" size="small" onChange={(e) => { handleSearch(e); }} variant="outlined" />) : ("")}
            <IconButton
              size="large"
              edge="start"
              color="default"
              aria-label="menu"
              sx={{ display: "flex", justifyContent: "space-between" }}
              id="fade-button"
              aria-controls={menu === "sort" ? "fade-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={menu === "sort" ? "true" : undefined}
              onClick={(event) => toggleMenu(event, "sort")}
            >
              <SwapVertIcon />
            </IconButton>
            <Menu
              id="fade-menu"
              MenuListProps={{
                "aria-labelledby": "fade-button",
              }}
              anchorEl={anchorEl}
              open={menu === "sort"}
              onClose={handleCloseSort}
              TransitionComponent={Fade}
            >
              <MenuItem sx={menuItemStyles} onClick={handleSortById}>
                Sort by route order {!checkIconSort ? <DoneIcon /> : ""}
              </MenuItem>
              <MenuItem sx={menuItemStyles} onClick={handleSortByName}>
                Sort by Name {checkIconSort ? <DoneIcon /> : ""}
              </MenuItem>
            </Menu>
            <IconButton
              size="large"
              edge="start"
              color="default"
              aria-label="menu"
              sx={{ display: "flex", justifyContent: "space-between" }}
              id="fade-button"
              aria-controls={menu === "filter" ? "fade-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={menu === "filter" ? "true" : undefined}
              onClick={(event) => toggleMenu(event, "filter")}
            >
              <FilterAltIcon />
            </IconButton>
            <Menu
              id="fade-menu"
              MenuListProps={{
                "aria-labelledby": "fade-button",
              }}
              anchorEl={anchorEl}
              open={menu === "filter"}
              onClose={handleCloseFilter}
              TransitionComponent={Fade}
            >
              <MenuItem sx={menuItemStyles} onClick={handleFlterDeliverHide}>
                Hide delivered meals {checkIcon ? <DoneIcon /> : ""}
              </MenuItem>
              <MenuItem sx={menuItemStyles} onClick={handleFlterDeliverShow}>
                Show {count} hidden delivered meals{" "}
                {!checkIcon ? <DoneIcon /> : ""}
              </MenuItem>
            </Menu>
          </Box>
        </Box> */}
        <Box sx={{ margin: "4px 10px" }}>
          {/* {state.filteredSeniorList?.map((item, index) => (
            <Box key={index} sx={{ margin: "4px 10px" }}>
              <Box className="content">
                <Box className="mealInfo">
                  <NavLink
                    style={{ textDecoration: "none" }}
                    to={"/signature"}
                    state={{
                      routeID: item.RouteID,
                      seniorID: item.SeniorID,
                      mealID: item.MealId,
                      signature: item.Delivered,
                    }}
                  >
                    <Typography className="routetextColor" variant="body1"> {item.LastName}, {item.FirstName}{" "} {item.Delivered ? <CheckCircleIcon /> : ""} </Typography>
                    <Typography className="textColor" variant="body1"> {item.MealName} </Typography>
                    <Typography className="textColor" variant="body1"> {item.Address} </Typography>
                    <Box className="images"> <img src={require("../../Assets/images/meal-list-icon.png")} alt="mealpng" /> </Box>
                  </NavLink>
                </Box>
                <Box className="mealBtns">
                  <Button
                    className="outlineButton" variant="outlined"
                    onClick={() => handleClickOpen(item.Phone, item.EmergencyContact, item.EmergencyContactName)}
                    startIcon={<PhoneIcon />}
                  >
                    Contact
                  </Button>
                  {item.Notes != null ? (<Button sx={{ marginLeft: 1 }} className="containedButton" variant="contained" onClick={() => handleClickOpenMenu(item.Notes)} startIcon={<StickyNote2Icon />} > View Notes </Button>) : ("")}
                </Box>
              </Box>
            </Box>
          ))} */}

          {loader ?
            <Stack mx={2} alignItems='center' mt={5} spacing={3}>
              {/* <Skeleton variant="circular" width={150} height={150} />
              <Skeleton variant="text" width={150} sx={{ fontSize: '1rem' }} />
              <Skeleton variant="rounded" width={"100%"} height={150} />
              <Skeleton variant="rounded" width={"100%"} height={80} /> */}
              {/* Loading */}
            </Stack> :

            <Box className="main_container">
              <Box className="avatarContainer" mt={0}>
                <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                  <Avatar sx={{ width: '140px', height: '140px' }} alt="Travis Howard" src={seniorByIdDetail.photoURL} />
                </Badge>
                <Typography my={2} className='name' variant={'h2'}>{seniorByIdDetail.firstName} {seniorByIdDetail.lastName}</Typography>
              </Box>
              <Typography ml={.5} mb={1} sx={{ fontWeight: 600 }} variant='body2' >Personal Info</Typography>
              <Box className='detail_container'>
                <Box className="items"><Typography sx={{ fontWeight: 600 }} variant='body2' className='typo_text'>First Name</Typography><Typography variant='body2' className='typo_text'>{seniorByIdDetail?.firstName ? seniorByIdDetail.firstName : "----"}</Typography></Box>
                <Divider />
                <Box className="items"><Typography sx={{ fontWeight: 600 }} variant='body2' className='typo_text'>Last Name</Typography><Typography variant='body2' className='typo_text'>{seniorByIdDetail?.lastName ? seniorByIdDetail.lastName : "----"}</Typography></Box>
                <Divider />
                <Box className="items"><Typography sx={{ fontWeight: 600 }} variant='body2' className='typo_text'> City</Typography><Typography variant='body2' className='typo_text'>{seniorByIdDetail?.city ? seniorByIdDetail.city : "----"}</Typography></Box>
                <Divider />
                <Box className="items"><Typography sx={{ fontWeight: 600 }} variant='body2' className='typo_text'> Senior ID</Typography><Typography variant='body2' className='typo_text'>{seniorByIdDetail?.seniorID ? seniorByIdDetail.seniorID : "----"}</Typography></Box>
              </Box>
              <Typography ml={.5} mb={1} sx={{ fontWeight: 600 }} variant='body2' >Tags</Typography>
              {tags?.length ?
                <Box className='detail_container'>
                  {tags?.map((item, index) => (
                    <Box key={index}>
                      <Box className="items"><Typography sx={{ fontWeight: 600 }} variant='body2' className='typo_text'>Tag {index + 1}</Typography><Typography variant='body2' className='typo_text'>{item?.cardID ? item.cardID : "----"}</Typography></Box>
                    </Box>
                  ))}

                </Box> : <Typography sx={{ fontWeight: 600 }} textAlign='center' variant='body2' className='typo_text'>No Tags Found</Typography>}
            </Box>}

        </Box>
      </Box>
      {/* Contact Info Dialog */}
      <Dialog
        open={openContact} onClose={handleCloseContact} maxWidth="xs" fullWidth className="mainDialoge"
      >
        <DialogContent>
          <DialogContentText sx={{ marginBottom: 2 }}>
            <Typography sx={{ fontSize: 14 }} variant="subtitle1">
              Main Phone:
            </Typography>
            <a href={`tel:${dialogInfo.mainNumber}`} style={{ textDecoration: "none", fontSize: 18, fontWeight: 400, letterSpacing: 0, }} > {dialogInfo.mainNumber} </a>
          </DialogContentText>
          <DialogContentText sx={{ marginBottom: 2 }}>
            <Typography sx={{ fontSize: 14 }} variant="subtitle1">
              Emergency Phone:
            </Typography>
            <a href={`tel:${dialogInfo.EmergencyInfo}`} style={{ textDecoration: "none", fontSize: 18, fontWeight: 400, letterSpacing: 0, }} > {dialogInfo.EmergencyInfo} </a>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      {/* Notes Dialog */}
      <Dialog
        open={openMenu} onClose={handleCloseMenu} maxWidth="xs" fullWidth className="mainDialoge"
      >
        <DialogContent>
          <DialogContentText sx={{ marginBottom: 2 }}>
            <Typography sx={{ fontSize: 14 }} variant="subtitle1"> Notes: </Typography>
            <Typography sx={{ fontSize: 16, fontWeight: 400 }} variant="subtitle1" > {notes} </Typography>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Root>
  );
}
