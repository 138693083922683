import React, { useEffect, useState } from 'react';
import AuthService, { AuthByOTPCode } from '../../Services/AuthService';
import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link, useNavigate } from 'react-router-dom';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import OtpInput from 'react-otp-input';
import Helpers from '../../Shell/Helper';
const Root = styled(Box)(({ theme }) => ({
    height: '100%',
    padding: 0,
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: "#c8c8c8",
        borderWidth: "1px",
    },
    '& .MuiInputBase-input': {
        fontWeight: "normal !important",
        fontSize: "18px"
    },
    '& .header-cont': {
        // width: "100%",
        width: "device-width",
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1,
        "& header": {
            display: "flex",
            height: 48,
            background: "white",
            fontSize: 12,
            alignItems: "center",
            '& img[alt="Logo"]': {
                width: 34,
                filter: 'invert(1)',
            },
            "& .headerNav": {
                // marginRight: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                "& .fuel": {
                    color: "red",
                    position: "absolute",
                    width: "26px",
                    right: "62px",
                    top: "12px",
                    cursor: "pointer"
                },
                "& .pageMainTitle": {
                    fontSize: 15,
                    fontWeight: 600,
                    whiteSpace: "nowrap"
                },
                "& .headerText": {
                    display: "flex",
                    color: '#1f0757',
                    fontSize: 13,
                    textShadow: '#1f0757',
                    fontWeight: 600,
                    whiteSpace: "nowrap"
                }
            }
        },
    },
    '& .mainContainer': {
        backgroundImage: `url(${require('../../Assets/loginBackgroundImg/img1.png')})`,
        backgroundSize: "cover",
        overflow: "hidden",
        backgroundPosition: "center",
        backgroundColor: "#f8e9f7",
        height: "calc(100vh - 0px)",
        marginTop: 0,
        overflowY: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& .backIcon": {
            position: "absolute",
            top: "20px",
            left: "28px",
            cursor: "pointer"
        },
        "& .heading": {
            fontSize: '28px',
            textAlign: "center",
            marginTop: "20px",
            fontWeight: "600",
            '@media (min-width:475px)': {
                fontSize: '32px'
            },
        },
        "& .content": {
            // border: "1px solid red",
            width: "100%",
            // maxWidth: "350px",
            maxWidth: "560px",
            margin: "auto"
        },
        "& .otpContainer": {
            // border: "1px solid red",
            width: "100%",
            justifyContent: "space-between",
            marginBottom: "5px"

        },
        "& .otpInput": {
            border: "1px solid #c4c4c4",
            width: "50px !important",
            height: "50px !important",
            fontSize: "25px",
            borderRadius: "5px",
            '@media (min-width:475px)': {
                width: "60px !important",
                height: "60px !important",
                fontSize: "24px",
            },

        },
        "& .routeTextNull": {
            height: "80vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        },
        "& .continueBtn": {
            borderRadius: "40px",
            padding: "10px 14px",
            margin: "20px 0px",
            background: "#4caf50",
            borderColor: "#4caf50",
            color: "#000000",
            fontSize: "18px"
        },
        "& .routeBox": {
            margin: "20px auto ",
            display: "flex",
            width: "100",
            minWidth: 310,
            gap: 10,
            backgroundColor: theme.palette.primary.contrastText,
            padding: "10px",
            boxShadow: "0px 3px 15px -6px rgb(0 0 0 / 50%)",
            borderRadius: 10,
            "& .routeText": {
                display: "flex",
                flexDirection: "column",
            },
            "& .routetextColor": {
                fontSize: 20,
                fontWeight: 400,
                color: theme.palette.other.main
            },
            "& .textColor": {
                fontSize: 16,
                fontWeight: 300,
                color: theme.palette.primary.dark
            }
        },
    },
    '& img[alt="heartpng"] ': {
        display: "flex",
        width: 105,
        animation: " heartbeat 1.2s infinite",
    },

    "@keyframes heartbeat": {
        "0%": {
            transform: "scale(1)",
        },
        "25%": {
            transform: "scale(0.9)",
        },
        "60%": {
            transform: "scale(1.1)",
        },
        "100%": {
            transform: "scale(1)",
        }
    },
}));
export default function OtpVerify() {
    const hostName = window.location.hostname;
    const [otp, setOtp] = useState('');
    let navigate = useNavigate();
    let phoneNumber = localStorage.getItem('phoneNumber');
    const [loading, setLoading] = useState(false);
    // console.log(phoneNumber)
    // useEffect(() => {
    //     let Token = localStorage.getItem('phoneNumber') || "";
    //     if (Token === "") {
    //         navigate("/");
    //     }// eslint-disable-next-line
    // }, [])
    useEffect(() => {
        handleAutoLogin();
        const getOTP = async () => {
            if ("OTPCredential" in window) {
                // alert("success if")
                const ac = new AbortController();
                console.log(ac, "<-- ac");
                try {
                    // alert("under try")
                    const otpCredential = await navigator.credentials.get({
                        otp: { transport: ["sms"] },
                        signal: ac.signal,
                    });
                    console.log(otpCredential, "<-- otpCredential")
                    setOtp(otpCredential.code);
                } catch (error) {
                    // alert("under error", error)
                    console.error("Error retrieving OTP:", error);
                } finally {
                    // alert("under abort")
                    ac.abort();
                }
            }
        };

        getOTP();
    }, []);
    const handleAutoLogin = () => {
        const userToken = AuthService.getAuthDataByName("userToken")
        if (userToken) {
            const expireDate = userToken?.expiresUTC;
            const token = userToken?.tokenUID
            if (new Date() > new Date(expireDate)) {
                localStorage.clear();
            } else {
                if (token) {
                    if (hostName === "mow.myseniorcenter.net") {
                        let url = Helpers.trackWelcome();
                        navigate(url)
                    } else {
                        navigate("/volunteer")
                    }
                }
            }
        }
    }
    const handleResendCode = () => {
        console.log("handleResendCode")
    }

    const handleChange = (event) => {
        setOtp(event.target.value)
    };
    const handleClick = async () => {
        if (otp.length < 6) {
            alert("Invalid varification code")
        } else {
            try {
                setLoading(true)
                // console.log("handleClick-->", otp)
                let payload = { otp, phoneNumber }
                let response = await AuthByOTPCode(payload);
                console.log(response, "<-- resp");
                if (response?.success && response?.data) {
                    // alert(response.message)
                    console.log(response, "<-- resp")
                    AuthService.setUserData("userToken", response.data?.[0]);
                    AuthService.setUserData("gsid", response[0]?.data?.centerInfo[0]?.gsid);
                    localStorage.setItem("centerData", JSON.stringify(response.data))
                    // AuthService.setUserData("centerInfo", response.data?.centerInfo[0]);
                    setLoading(false)
                    if (hostName === "mow.myseniorcenter.net") {
                        let url = Helpers.trackWelcome();
                        navigate(url)
                    } else {
                        navigate("/volunteer")
                    }
                } else {
                    // let data = [
                    //     {
                    //         "tokenUID": "8ee3e3eb-f10e-4d8c-9688-a6246fd9b5f1",
                    //         "expiresUTC": "2024-09-25T19:53:42.493",
                    //         "centerInfo": [{
                    //             "gsid": 705,
                    //             "centerName": "North Reading Department of Elder Affairs",
                    //             "centerLogURI": null,
                    //             "centerPhone": "978-664-5600"
                    //         }]
                    //     }
                    //     ,
                    //     {
                    //         "tokenUID": "3ac5a5a5-0dc8-4667-b8ee-456678299911",
                    //         "expiresUTC": "2024-09-26T13:35:11.203",
                    //         "centerInfo": [
                    //             {
                    //                 "gsid": 570,
                    //                 "centerName": "Hanover Township Aging Services",
                    //                 "centerLogURI": "https://myseniorcenter-tenants.s3.amazonaws.com/il-bartlett/media/c846bfe7-629f-4222-ab91-0946e1dbbf80/IL_Bartlett128.jpg",
                    //                 "centerPhone": "630-483-5600"
                    //             }
                    //         ]
                    //     }
                    // ]


                    // AuthService.setUserData("userToken", data?.[0]);
                    // AuthService.setUserData("gsid", data[0]?.centerInfo[0]?.gsid);
                    // localStorage.setItem("centerData", JSON.stringify(data))
                    // // AuthService.setUserData("centerInfo", data?.centerInfo[0]);
                    // if (hostName === "mow.myseniorcenter.net") {
                    //     let url = Helpers.trackWelcome();
                    //     console.log(url, "<-- url")
                    //     navigate(url)
                    // } else {
                    //     navigate("/volunteer")
                    // }

                    setLoading(false)
                    alert(response?.message)
                }
            } catch (error) {
                console.error(error.message)
                setLoading(false)
                alert("Invalid verification code. Click on resend to receive a new code")
            }
        }
        // if (otp.length < 6) {
        //     alert("Invalid varification code")
        // } else {
        //     const hostName = window.location.hostname;
        //     // let ActivatedData = await ActivateByCode(otp);
        //     let ActivatedData = await ActivateByCode("736467");

        //     if (ActivatedData) {
        //         let ActivateData = ActivatedData.data;
        //         AuthService.setUserData("token", otp);
        //         AuthService.setUserData("gsid", ActivateData.gsid);
        //         AuthService.setUserData("deviceTagID", ActivateData.tagId);
        //         AuthService.setUserData("deviceSecret", ActivateData.deviceSecret);
        //         // Redirect based on hostname
        //         if (hostName === 'meals.myseniorcenter.net') {
        //             navigate("/meal")
        //         } else if (hostName === 'mobile.myseniorcenter.net') {
        //             navigate("/activties")

        //         }
        //         else if (hostName === 'qa1.xtecsoft.com') {
        //             navigate("/ride")
        //         }
        //         else {
        //             navigate("/volunteer")
        //         }
        //     } else {
        //         alert("Invalid varification code")
        //     }
        // }
    }
    return (

        <Root p={2} >
            {/* Header */}
            {/* <Box className="header-cont">
                <Box sx={{ flexGrow: 1 }}>
                    <AppBar position="static">
                        <Toolbar
                            sx={{ width: "100%", maxWidth: "100%", padding: "0px 15px 0px 45px !important" }}>
                            <Box className="headerNav">
                                <Box
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    aria-label="menu"
                                    sx={{ mr: 2, display: "inline-flex", alignItems: "center" }}>

                                    <img src={require('../../Assets/icons/msc_heart.png')} alt="Logo" style={{ marginRight: 10 }} />
                                    <div>
                                        <Typography color="black" className="pageMainTitle" id="pageTitle">
                                            My Senior Center
                                        </Typography>
                                        <Typography color="black" className="headerText" id="pageTitle">
                                            Meals
                                        </Typography>
                                    </div>
                                </Box>

                            </Box>
                        </Toolbar>
                    </AppBar>
                </Box>
            </Box> */}
            <Box className="mainContainer" p={5}>
                <Stack className='backIcon'> <ArrowBackRoundedIcon sx={{ cursor: "pointer" }} onClick={() => navigate("/phoneverification")} /> </Stack>
                <Box>
                    <Box sx={{ display: "flex", justifyContent: "center" }}> <img src={require("../../Assets/images/logo.png")} alt="heartpng" /> </Box>
                    <Typography color="black" className='heading' variant='h1' >
                        Verification Code
                    </Typography>
                    <Typography color="black" variant='subtitle2' textAlign={"center"} pt={1} >
                        Please enter the verification code we sent to your phone number.
                    </Typography>
                    <Stack className='content'>
                        <Stack alignItems={'center'} justifyContent={"space-between"} pt={5} >
                            <OtpInput
                                containerStyle='otpContainer'
                                inputStyle='otpInput'
                                value={otp}
                                onChange={setOtp}
                                numInputs={6}
                                renderSeparator={''}
                                shouldAutoFocus={true}
                                renderInput={(props) => <input  {...props} />}
                            />
                        </Stack>
                        <Box sx={{ textAlign: "end" }} mt={1}>
                            <Link onClick={handleResendCode} >Resend Code</Link>
                        </Box>
                        <Button
                            startIcon={loading ? <CircularProgress size={20} sx={{ color: "black" }} /> : ""}
                            disabled={loading ? true : false}
                            variant='contained' className='continueBtn'
                            sx={{ width: "100%", padding: "10px" }}
                            onClick={handleClick}>Done</Button>
                    </Stack>
                </Box>
            </Box>
        </Root>

    )
}

