import React, { useEffect, useRef, version } from 'react';
import { useNavigate, } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { darkTheme, lightTheme } from './Shell/Theme';
import CssBaseline from '@mui/material/CssBaseline';
import RoutesDefinition from './Shell/Routes';
import Paper from '@mui/material/Paper';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { styled } from "@mui/material/styles";
import IosShareIcon from '@mui/icons-material/IosShare';
import { Box, Button, Divider, IconButton, Snackbar, Typography } from '@mui/material';
import SwipeableLeftDrawer from './Components/LeftDrawer';
import { useAppContext } from './Shell/AppContext';
import CloseIcon from '@mui/icons-material/Close';

const DialogBox = styled(Dialog)(({ theme }) => ({
    "& .titleDialog": {
        display: "flex",
        padding: "10px",
        fontWeight: "bold",
        "& p": {
            fontSize: "18px"
        }
    },
    "& .description": {
        height: "120px",
        "& h6": {
            fontSize: "16px",
            lineHeight: "inherit"
        },
        "& .buttons": {
            display: "flex",
            justifyContent: "space-between",
            "& Button": {
                width: "48%",
                marginTop: "20px",
                alignSelf: "center"
            }
        },
    },
    '& .rocket': {
        transform: "rotate(45deg)"
    },
    "& .UpdatedDescription": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "auto",
        gap: "6px",
        "& h6": {
            textAlign: "center",
            fontSize: "16px",
        },
    },
    "& .heartimg": {
        margin: "0 auto",
        width: "80px"
    },

    "& .closeBtn": {
        fontSize: 20
    },
}));
const Root = styled(Paper)(({ theme }) => ({
    ".topLeftButton": {
        position: 'absolute',
        right: "0px",
        top: "8px",
        color: "black",
        zIndex: "99",
        "& svg": {
            fontSize: "25px",
        },
        '&.refresh': {
            right: 30,
        }
    },
    "& .sideBar": {
        position: " absolute",
        zIndex: "9999999999999999",
    }
}));
function App() {
    const [showPopup, setShowPopup] = React.useState(false);
    const [pathMenu, setPathMenu] = React.useState()
    const [isOpenDrawer, setIsOpenDrawer] = React.useState()
    const [dialogText, setDialogText] = React.useState("New version 2023.05.09.001 is available.")

    const [openDialog, setOpenDialog] = React.useState(false);
    const [openUpdatedDialog, setOpenUpdatedDialog] = React.useState(false);
    const [versionNo, setVersionNo] = React.useState("2023.01.01.000");
    const [isDarkMode, setIsDarkMode] = React.useState(localStorage?.getItem('darkMode') || false);
    const [clickCount, setClickCount] = React.useState(0);
    const currentDate = `${new Date().getMonth() + 1}-${new Date().getDate()}-${new Date().getFullYear()}`;
    const firstStart = useRef(true);
    const { data, setData } = useAppContext();
    const updatedDialogText = `App is Updated to ${localStorage?.getItem("APP_VERSION") || "2023.05.09.001"}`
    React.useEffect(() => {
        if (firstStart.current) {
            window.title = "Drivers - My Senior Center";
            firstStart.current = false;
            // Check for updates at first after 1 second
            setTimeout(() => {
                updateApp();
            }, 3000);
            // Check for updates later every 10 seconds
            setInterval(() => {
                updateApp();
            }, 30000);
        }
        //check PWA install in ios
        const isIos = () => {
            const userAgent = window.navigator.userAgent.toLowerCase();
            return /iphone|ipad|ipod/.test(userAgent);
        };
        const isPwaInstalled = () => {
            return window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone;
        };
        const isSafari = () => {
            return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        };
        const shouldShowPopup = (isIos() || isSafari()) && !isPwaInstalled();
        setShowPopup(shouldShowPopup)

        //dark and Light mode
        const darkMode = JSON.parse(localStorage?.getItem('darkMode') || true);
        setIsDarkMode(darkMode);
        // updated popup
        let ShowUpdateDialog = localStorage.getItem("ShowUpdateDialog");
        if (ShowUpdateDialog === "true") {
            setOpenUpdatedDialog(true);
            localStorage.removeItem("ShowUpdateDialog");
        }
    }, [])
    //PWA
    const handleCloseIOSPWADialog = () => {
        setShowPopup(false);
    };
    // ======== Dialog  =======
    const handleClose = () => {
        localStorage.setItem("APP_ALERT", currentDate)
        setOpenDialog(false);
    };
    const handleCloseUpdatedDialog = () => {
        setOpenUpdatedDialog(false);
    };
    const handleUpdate = () => {
        navigator.serviceWorker.getRegistrations()
            .then((registrations) => {
                Promise.all(registrations.map((r) => r.unregister()));
            })
            .then(() => {
                localStorage.setItem("APP_VERSION", versionNo);
                localStorage.setItem("APP_ALERT", "");
                localStorage.setItem("ShowUpdateDialog", true)
                setOpenDialog(false);
                window.location.reload();
            })
    }
    // const timeOut = 60000; // 1 minutes
    // let timer
    // const resetIdleTimer = () => {
    //     clearTimeout(timer);
    //     timer = setTimeout(updateAppInBackground, timeOut);
    // };

    // const updateAppInBackground = () => {
    //     updateApp();
    //     console.log("Updating app due to user inactivity.");

    // };
    // React.useEffect(() => {
    //     window.addEventListener("touchstart", () => resetIdleTimer(), { passive: true });
    //     window.addEventListener("touchmove", () => resetIdleTimer(), { passive: true });
    //     window.addEventListener("touchend", () => resetIdleTimer());
    //     return () => {
    //         window.removeEventListener("touchstart", () => resetIdleTimer());
    //         window.removeEventListener("touchmove", () => resetIdleTimer());
    //         window.removeEventListener("touchend", () => resetIdleTimer());
    //     };
    // }, []);

    // set Local storage PWA or local Storage
    useEffect(() => {
        const isBrowser = window.matchMedia('(display-mode: browser)').matches;

        const installMode = isBrowser ? 'browser' : 'PWA';

        // Set the installMode variable in local storage
        localStorage.setItem('installMode', installMode);
    }, [window.matchMedia('(display-mode: browser)').matches]);
    // Initialize the itimer
    // React.useEffect(() => {
    //     resetIdleTimer();
    //     return () => {
    //         clearTimeout(timer);
    //     };
    // }, []);
    const updateApp = async () => {
        await fetch(`/version.txt?ver=${Math.random()}`).then(x => x.text()).then(function (version) {
            const currentVersion = localStorage?.getItem("APP_VERSION");
            if ((currentVersion !== version) && version.includes('*')) {
                navigator.serviceWorker.getRegistrations()
                    .then((registrations) => {
                        Promise.all(registrations.map((r) => r.unregister()));
                    })
                    .then(() => {
                        localStorage.setItem('APP_VERSION', version);
                        setVersionNo(version)
                        localStorage.setItem('APP_ALERT', '');
                        setOpenDialog(false);
                        window.location.reload();
                    });
            } else {
                if (currentVersion === null) {
                    localStorage.setItem("APP_VERSION", version);
                }
                else if ((currentVersion !== version) && (currentDate !== localStorage.getItem("APP_ALERT"))) {
                    navigator.serviceWorker.getRegistrations()
                        .then((registrations) => {
                            Promise.all(registrations.map((r) => r.unregister()));
                        })
                        .then(() => {
                            localStorage.setItem('APP_VERSION', version);
                            localStorage.setItem('APP_ALERT', '');
                            setVersionNo(version)
                            setOpen(true)
                            setTimeout(() => {
                                window.location.reload();

                            }, 7000)
                        });
                    console.log("App Updated Successfully")
                }
                else {
                    console.log("App Already Updated")
                }
            }
        })
    }
    const handleTheme = (e) => {
        const newMode = e;
        // console.log(e.target.checked)
        setIsDarkMode(newMode);
        localStorage.setItem('darkMode', newMode);
    };
    const handlestateFromChildMenu = (value) => {
        setPathMenu(value)
    }
    const handlestateFromDrawer = (value) => {
        setIsOpenDrawer(value);
    }
    //check for theme mode
    useEffect(() => {
        if (localStorage.getItem("selectedMode") === "dark") {
            setData({ ...data, themeMode: "dark" })
        } else if (localStorage.getItem("selectedMode") === "light") {
            setData({ ...data, themeMode: "light" })
        } else {
            if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
                handleTheme(true)
            } else {
                handleTheme(false)
            }
            setData({ ...data, themeMode: "auto" })
        }
    }, [])
    //three click 
    let navigate = useNavigate()
    const Timedelay = 500;
    const handleTripleClick = (e) => {
        setClickCount((prevClickCount) => prevClickCount + 1);
        if (clickCount === 2) {
            if (window.location.pathname === "/scanner" || window.location.pathname === "/" || window.location.pathname === "/vehicle" || window.location.pathname === "/driver") {
                // alert("Not allowed")
            } else {
                navigate("/scanner")
            }
            setClickCount(0);
        } else {
            clearTimeout(handleTripleClick.debounceTimer);
            handleTripleClick.debounceTimer = setTimeout(() => {
                setClickCount(0);
            }, Timedelay);
        }
    }
    const [open, setOpen] = React.useState(false);

    const handleClosea = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    const action = (
        <React.Fragment>
            <Button color="secondary" size="small" onClick={handleClosea}>

            </Button>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClosea}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );
    return (
        <Root>
            <Paper onClick={handleTripleClick} elevation={0} id="AppWrapper">
                <Snackbar
                    open={open}
                    autoHideDuration={5000}
                    onClose={handleClosea}
                    message={`App Updated Successfully ${versionNo}`}
                    action={action}
                />
                <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
                    <CssBaseline />
                    {/* <Router> */}
                    {pathMenu === false ? (
                        <SwipeableLeftDrawer handlestateFromDrawer={handlestateFromDrawer} className="sideBar" />) : ""}
                    <RoutesDefinition isDarkMode={isDarkMode} handleTheme={handleTheme} rightDrawer={!isOpenDrawer} handlestateFromChildMenu={handlestateFromChildMenu} />
                    {showPopup && (
                        <DialogBox
                            open={showPopup}
                            onClose={handleCloseIOSPWADialog}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <Box className="titleDialog">
                                <Typography variant='body2'>Add to Home Screen</Typography>
                            </Box>
                            <Divider />
                            <DialogContent sx={{ padding: "20px" }}>
                                <Box sx={{ height: "fit-content" }} >
                                    <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                                        <img src={require("./Assets/heartBlack.png")} alt='mainLogo' className='heartimg' />
                                        <Typography textAlign={'center'} variant='subtitle2'>Install this application on your home screen for a better experience.</Typography>

                                        <Box sx={{ display: "flex", gap: "5px", justifyContent: "center" }}>
                                            <Typography variant='span'>Tab</Typography>
                                            <IosShareIcon />
                                            <Typography variant='span'>then "Add to Home Screen"</Typography>
                                        </Box>
                                    </Box>
                                    <Box sx={{ display: "flex", justifyContent: "center", marginTop: "15px", }} >
                                        <Button fullWidth onClick={() => handleCloseIOSPWADialog()} className='closeBtn' color='success' variant="text">Close</Button>
                                    </Box>
                                </Box>
                            </DialogContent>
                        </DialogBox>
                    )}
                    {/* </Router> */}
                </ThemeProvider>
                {/* Dialog  */}
                <DialogBox
                    open={openDialog}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <Box className="titleDialog">
                        <Typography variant='body2'>Update available</Typography>
                    </Box>
                    <Divider />
                    <DialogContent sx={{ padding: "20px" }}>
                        <Box className="description" >
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <img className="launch rocket" alt='rocket' width={"60px"} src={require("./Assets/images/rocket.png")} />
                                <Typography variant='subtitle2'>{dialogText}</Typography>
                            </Box>
                            <Box className="buttons" >
                                <Button onClick={() => handleClose()} color='error' variant="contained">Cancel</Button>
                                <Button onClick={() => handleUpdate()} color="success" variant="contained">Update</Button>
                            </Box>
                        </Box>
                    </DialogContent>
                </DialogBox>
                {/* Updated Dialog */}
                <DialogBox
                    open={openUpdatedDialog}
                    onClose={handleCloseUpdatedDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <Box className="titleDialog">
                        <Typography variant='body2'>Updated</Typography>
                    </Box>
                    <Divider />
                    <DialogContent sx={{ padding: "25px" }}>
                        <Box className="UpdatedDescription">
                            <img src={require("./Assets/images/happyface.png")} width={120} height={120} alt='smile' />
                            <Typography variant='subtitle2'>{updatedDialogText}</Typography>
                            <Button onClick={() => handleCloseUpdatedDialog()} color="success" variant="contained">OK</Button>
                        </Box>
                    </DialogContent>
                </DialogBox>
            </Paper>
        </Root>
    );
}

export default App;
