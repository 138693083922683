import React, { useEffect } from 'react'
import { AppBar, Box, IconButton, Toolbar, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Radio from '@mui/material/Radio';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../Shell/AppContext';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
const Root = styled(Box)(({ theme }) => ({
    height: '100%',
    padding: 0,
    "& .MainTitle": {
        fontSize: 15,
        fontWeight: 600,
        whiteSpace: "nowrap"
    },
    "& .subTitle": {
        fontSize: 15,
        fontWeight: 500,
        whiteSpace: "nowrap"
    },
    '& .header-cont': {
        // width: "100%",
        width: "device-width",
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1,
        background: "white",
        "& header": {
            display: "flex",
            height: 48,
            background: "white",
            fontSize: 12,
            alignItems: "center",
            "& .headerNav": {
                marginRight: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                "& .MuiIconButton-root:hover": {
                    backgroundColor: "transparent"
                }

            }
        },
    },
    '& .mainContainer': {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        overflowY: "auto",
        height: "calc(100vh - 48px)",
        marginTop: 48,
        "& .content": {
            padding: "10px",
            width: "100%",
            "& .infoCard": {
                padding: "10px",
                display: "flex",
                width: "100%",
                height: "fit-content",
                border: "1px solid #ccc",
                borderRadius: "5px",
                gap: "10px",
                marginBottom: "10px",
                "& .image": {
                    maxWidth: "50px",
                    maxHeight: "50px",
                    borderRadius: "50%",
                    overflow: "hidden",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    "& img": {
                        filter: "invert(1)",
                    }
                },
                "& .themeChanger": {
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                }
            }
        },

    },

}));
function ChangeTheme({ handleTheme, isDarkMode }) {
    const [selectedValue, setSelectedValue] = React.useState('');
    let navigate = useNavigate()
    const { data, setData } = useAppContext({ themeMode: localStorage.getItem("selectedMode") });
    useEffect(() => {
        if (data?.themeMode === undefined && localStorage.getItem("selectedMode") === null) {
            setSelectedValue("auto")
            localStorage.setItem("selectedMode", "auto")
        } else {
            if (data?.themeMode === "auto") {
                setSelectedValue("auto")
            } else {
                if (isDarkMode) {
                    setSelectedValue("dark")
                } else {
                    setSelectedValue("light")
                }
            }
        }
    }, [])
    const handleChange = (e) => {
        localStorage.setItem("selectedMode", e)
        setSelectedValue(e);
        if (e === "dark") {
            setData({ ...data, themeMode: "dark" })
            handleTheme(true)
        } else if (e === "light") {
            setData({ ...data, themeMode: "light" })
            handleTheme(false)
        } else if (e === "auto") {
            setData({ ...data, themeMode: "auto" })
            setSelectedValue(e)
            const darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)");
            if (darkThemeMq.matches) {
                handleTheme(true)
            } else {
                handleTheme(false)
            }
        }
    }

    //check for theme mode
    React.useEffect(() => {
        window.matchMedia('(prefers-color-scheme: dark)')
            .addEventListener('change', event => {
                const colorScheme = event.matches ? "dark" : "light";
                // console.log(colorScheme);
                if (localStorage.getItem("selectedMode") === "auto") {
                    if (colorScheme === "dark") {
                        handleTheme(true)
                    } else {
                        handleTheme(false)
                    }
                }
            });

    }, []);
    return (
        <Root p={2} >
            <Box className="header-cont">
                <Box sx={{ flexGrow: 1 }}>
                    <AppBar position="static">
                        <Toolbar sx={{ width: "100%", maxWidth: "100%", padding: "0px 15px  !important" }}>
                            <Box className="headerNav">
                                <Box
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    aria-label="menu"
                                    sx={{ mr: 2, display: "inline-flex", alignItems: "center", color: "black", gap: "10px" }}>
                                    <IconButton onClick={() => { navigate("/settings") }}>
                                        <ArrowBackIosIcon sx={{ color: "black" }} />
                                    </IconButton>
                                    <Typography className="MainTitle"  >Select Theme</Typography>
                                </Box>
                            </Box>
                        </Toolbar>
                    </AppBar>
                </Box>
            </Box>
            <Box className="mainContainer">
                <Box className="content">
                    <Box className='infoCard' sx={{ cursor: "pointer" }} onClick={() => handleChange('auto')} >
                        <Box className="themeChanger">
                            <Typography className="MainTitle">Auto</Typography>
                            <Radio
                                color='secondary'
                                checked={selectedValue === 'auto'}
                                value="auto"
                                name="radio-buttons"
                            />
                        </Box>
                    </Box>


                    <Box className='infoCard' sx={{ cursor: "pointer" }} onClick={() => handleChange('dark')}>
                        <Box className="themeChanger">
                            <Typography className="MainTitle">Dark</Typography>
                            <Radio
                                color='secondary'
                                checked={selectedValue === 'dark'}
                                value="dark"
                                name="radio-buttons"
                            />
                        </Box>
                    </Box>


                    <Box className='infoCard' sx={{ cursor: "pointer" }} onClick={() => handleChange('light')}>
                        <Box className="themeChanger">
                            <Typography className="MainTitle">Light</Typography>
                            <Radio
                                color='secondary'
                                checked={selectedValue === 'light'}
                                value="light"
                                name="radio-buttons"
                            />
                        </Box>
                    </Box>

                </Box>

            </Box>
        </Root>
    )
}

export default ChangeTheme