import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Divider, IconButton, Typography, } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import AuthService from '../Services/AuthService';
// Dialog
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
const Root = styled(Box)(({ theme }) => ({
    "& .drawerIcon": {
        position: "absolute",
        top: 5,
        left: 5,
        zIndex: "9",
        "& .MuiPaper-root": {
            height: "100% !importan",
        },
        "& .applogo": {
            display: "flex",
        },
    },
}))
export default function SwipeableTemporaryDrawer(props) {
    const [state, setState] = React.useState({
        left: false,
        logoutAlert: false,
    });
    const [mode, setMode] = React.useState();
    const [appName, setAppName] = React.useState('');
    const [logo, setLogo] = React.useState('');
    let PagesRoutesOne = ['Switch to Seniors', 'Switch to Scanner', 'Switch to Activities', 'Switch to Meals', 'Switch to Rides', 'Mini Kiosk Mode']
    React.useEffect(() => {
        let darkMode = localStorage.getItem("darkMode");
        setMode(darkMode)
    }, [mode])
    React.useEffect(() => {
        setLogo(AuthService.getAppDataByName("Logo"));
        let applicationName = AuthService.getAppDataByName("AppName");
        if (applicationName === null || applicationName === '') {
            setAppName("My Senior Center");
        } else {
            setAppName(AuthService.getAppDataByName("AppName"));
        }
        // eslint-disable-next-line
    }, [])
    setTimeout(() => {
        setAppName(AuthService.getAppDataByName("AppName"));
        setLogo(AuthService.getAppDataByName("Logo"));
    }, 1000)
    let navigate = useNavigate()

    const handleSwitchOne = async (index) => {
        setState({ left: false });
        props.handlestateFromDrawer(false)
        if (index === 0) {
            navigate('/senior')
        } else if (index === 1) {
            navigate('/scanner')
        } else if (index === 2) {
            navigate("/activties")
        } else if (index === 3) {
            navigate("/meal")
        } else if (index === 4) {
            navigate('/ride')
        } else if (index === 5) {
            navigate('/newscan')
        } else {
            navigate('/settings')
        }
    }




    const toggleDrawer = (open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        setState({ left: open });
        props.handlestateFromDrawer(open)
    };

    const gotoLogout = (index) => {
        if (index === 0) {
            setState({ left: false });
            props.handlestateFromDrawer(false)
            navigate('/settings')

        } else if (index === 1) {
            setState({ ...state, logoutAlert: true });
        }
    }
    const handleCloseDrawer = () => {
        setState({ left: false })
        props.handlestateFromDrawer(false)
    }
    const handleRefresh = () => {
        setState({ ...state, logoutAlert: false });
        window.location.reload();
    }
    const handleLogOut = () => {
        setState({ ...state, logoutAlert: false });
        AuthService.signOut();
    }
    return (
        <Root >
            <div className='drawerIcon'>
                <React.Fragment key="left"  >
                    <IconButton onClick={toggleDrawer(true)}>
                        <MenuIcon />
                    </IconButton>

                    <SwipeableDrawer
                        anchor="left"
                        open={state.left}
                        onClose={toggleDrawer(false)}
                        onOpen={toggleDrawer(true)}
                    >
                        <Box className="mainContainer" sx={{ height: "100vh", backgroundImage: `url(${require("../Assets/loginBackgroundImg/img1.png")})`, backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "cover" }}>
                            <Box
                                sx={{ width: 250, }}
                                role="presentation"

                            >
                                <Box className="applogo" style={{ display: 'flex', justifyContent: "space-between", alignItems: "center", gap: 12, margin: "15px 4px 15px 8px" }}>
                                    <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                        {logo ? <img src={logo} width={"35px"} alt="Meals" /> :
                                            <img style={{ filter: "invert(1)" }} src={`${require("../Assets/icons/msc_heart.png")}`} width={"35px"} alt="Meals" />}
                                        {appName ? <Typography variant='subtitle' color="black">{appName}</Typography> : <Typography variant='subtitle' color="black">My Senior Center  </Typography>}
                                    </Box>
                                    <CloseIcon onClick={handleCloseDrawer} />
                                </Box>
                                <Divider />
                                <Box sx={{ display: "flex", flexDirection: "column", height: "90vh" }}>
                                    <Box sx={{ flex: "95%", }}>
                                        <List >
                                            {PagesRoutesOne.map((text, index) => (
                                                <React.Fragment key={text}>
                                                    <ListItem disablePadding>
                                                        <ListItemButton onClick={() => handleSwitchOne(index)} >
                                                            <ListItemIcon sx={{ minWidth: "35px" }}>
                                                                {index === 0 ? (
                                                                    <img src={require('../Assets/images/senior-icon.png')} width="28px" alt="Seniors" />

                                                                ) : index === 1 ? (
                                                                    <img src={require('../Assets/images/scan-icon.png')} width="28px" alt="Scan" />

                                                                ) : index === 2 ? (
                                                                    <img src={require('../Assets/images/activities-icon.png')} width="28px" alt="Activities" />

                                                                ) : index === 3 ? (
                                                                    <img src={require('../Assets/images/meal-icon.png')} width="30px" alt="Meals" />

                                                                ) : index === 4 ? (
                                                                    <img src={require('../Assets/images/ride-icon.png')} width="30px" alt="Rides" />

                                                                ) : (
                                                                    <img src={require('../Assets/images/scan-icon.png')} width="28px" alt="Settings" />
                                                                )}
                                                            </ListItemIcon>
                                                            <ListItemText sx={{ padding: 1 }} primary={text} />
                                                        </ListItemButton>
                                                    </ListItem>
                                                    {index < 5 && <Divider sx={{ margin: "0px 20px" }} />}
                                                </React.Fragment>
                                            ))}

                                        </List>

                                    </Box>
                                    <Box sx={{ flex: "5%" }}>
                                        <List >
                                            {['Settings', 'Logout'].map((text, index) => (
                                                <React.Fragment key={text}>
                                                    <ListItem disablePadding>
                                                        <ListItemButton onClick={() => gotoLogout(index)}>
                                                            <ListItemIcon sx={{ minWidth: "35px" }}>
                                                                {index === 0 ? (
                                                                    <img src={require('../Assets/images/settingsicon.png')} width="28px" alt="Settings" />

                                                                ) : (
                                                                    <img src={require('../Assets/images/logout-icon.png')} width="30px" alt="Logout" />

                                                                )}

                                                            </ListItemIcon>
                                                            <ListItemText
                                                                onKeyDown={toggleDrawer(false)}
                                                                sx={{ padding: 1 }} primary={text} />
                                                        </ListItemButton>
                                                    </ListItem>
                                                    <Divider sx={{ margin: "0px 20px" }} />
                                                </React.Fragment>
                                            ))}
                                        </List>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </SwipeableDrawer>
                </React.Fragment>
            </div>

            <Dialog
                open={state.logoutAlert}
                onClose={() => setState({ ...state, logoutAlert: false })}
                aria-labelledby="logout-alert-title"
                aria-describedby="logout-alert-description"
            >

                <DialogTitle id="logout-alert-title" sx={{ fontSize: "20px", paddingBottom: "8px", paddingLeft: "15px", paddingRight: "10px" }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", }}>
                        <Typography sx={{}}>Are you sure?</Typography>
                        <CloseIcon onClick={() => setState({ ...state, logoutAlert: false, left: false })} />
                    </Box>

                </DialogTitle>
                <DialogContent sx={{ paddingBottom: "10x", paddingLeft: "15px" }}>
                    <Typography variant='body2' id="logout-alert-description">
                        Logging out means you will need an activation key which could take 10 minutes or several days depending on your center's availability
                    </Typography>
                </DialogContent>
                <DialogActions sx={{ paddingLeft: "15px", paddingRight: "15px", borderTop: "1px solid #ccc", display: 'flex', justifyContent: "space-between" }}>
                    <Button sx={{ backgroundColor: "#000524", borderRadius: 30, color: "white" }} variant="contained" onClick={handleLogOut} autoFocus>Logout</Button>

                    <Button sx={{ backgroundColor: "#4caf50", borderRadius: 30, color: "white", "&:hover": { backgroundColor: "#4caf50" } }} variant="contained" onClick={handleRefresh} autoFocus>Refresh Application</Button>
                </DialogActions>
            </Dialog>

        </Root>
    );
}