import { GetRequest, PostFormsRequest, PostRequest } from "./Apibase";
import { SyncApplicationWithServer } from "./RidesServices";

// Basic Reload
var isOnline = window.navigator.onLine
isOnline = true
window.addEventListener('online', async () => {
    isOnline = true;
    await SyncApplicationWithServer();
    window.location.reload();
});
window.addEventListener('offline', () => {
    isOnline = false
});

// Meal Services
let GetRoutes = async () => {
    if (isOnline) {
        let response = await GetRequest(`/api/meals/getmealroutes`, false, true);
        if (response.success) {
            response.data.forEach(async route => {
                await GetSeniorsByRoute(route.RouteID, true)
            })
            localStorage.setItem('PWA_ROUTES', JSON.stringify(response))
        }
        return response;
    } else {
        let cache = JSON.parse(localStorage.getItem('PWA_ROUTES'))
        console.log('Routes cache triggered')
        if (cache && cache.data.length)
            return cache
        else
            return { success: true, data: [] }
    }
}
let GetActivities = async (id) => {
    if (isOnline) {
        let response = await GetRequest(`/api/meals/getmealroutes`, false, true);
        if (response.success) {
            response.data.forEach(async route => {
                await GetSeniorsByRoute(route.RouteID, true)
            })
            localStorage.setItem('PWA_ROUTES', JSON.stringify(response))
        }
        return response;
    } else {
        let cache = JSON.parse(localStorage.getItem('PWA_ROUTES'))
        console.log('Routes cache triggered')
        if (cache && cache.data.length)
            return cache
        else
            return { success: true, data: [] }
    }
}
let GetAllSeniors = () => {
    return { success: true, data: JSON.parse(localStorage.getItem('PWA_SENIORS')) }
}
let GetSeniorsByRoute = async (routeID, forceFetch = false) => {
    let cache = JSON.parse(localStorage.getItem('PWA_SENIORS'))
    if (cache && cache.length) {
        cache = cache.filter(c => c.RouteID === routeID)
    }
    if ((!cache || !cache.length || forceFetch) && isOnline) {
        let response = await GetRequest(`/api/meals/getseniorsbyroute?routeID=${routeID}`, false, true);
        if (response.success)
            await UpdateSeniorsCache(routeID, response.data)
        return response;
    } else {
        return { success: true, data: cache }
    }
}
let UpdateSeniorsCache = async (routeID, data) => {
    let cache = JSON.parse(localStorage.getItem('PWA_SENIORS'))
    data.forEach(s => {
        s.RouteID = routeID
    })
    if (cache)
        cache = cache.filter(c => c.RouteID !== routeID)
    cache = cache && cache.length > 0 ? cache.concat(data) : data
    localStorage.setItem('PWA_SENIORS', JSON.stringify(cache))
}
let UpdateMealDeliveredInCache = async (seniorID) => {
    let cache = JSON.parse(localStorage.getItem('PWA_SENIORS'))
    if (cache && cache.length > 0) {
        cache.forEach(senior => {
            if (senior.SeniorID === seniorID) {
                senior.Delivered = true
                return
            }
        });
        localStorage.setItem('PWA_SENIORS', JSON.stringify(cache))
    }
}
let SetSeniorMealDelivered = async (routeID, seniorID, mealID, signature, isFake = false) => {
    if (isFake) {
        UpdateMealDeliveredInCache(seniorID)
        return { success: true }
    } else {
        if (isOnline) {
            var formData = new FormData();
            formData.append("mealID.Signature", signature);
            //for (var i = 0; i < mealID.length; i++) {
            formData.append(`mealID.mealID`, mealID);
            //}
            let response = await PostFormsRequest(`/api/meals/setseniormealdelivered`, formData, false, true);
            if (response.success) {
                UpdateMealDeliveredInCache(seniorID)
            }
            return response;
        } else {
            let data = [{ routeID: routeID, seniorID: seniorID, mealID: mealID, signature: signature }]
            let cache = JSON.parse(localStorage.getItem('PWA_REQUESTS'))
            cache = cache && cache.length > 0 ? cache.concat(data) : data
            localStorage.setItem('PWA_REQUESTS', JSON.stringify(cache))
            console.log('PWA_REQUESTS cache: ')
            UpdateMealDeliveredInCache(seniorID)
            return { success: true }
        }
    }
}
let CancelSignature = async (data) => {
    let response = await PostRequest(`/api/meals/CreateNonDeliveryNotes`, data);
    if (response.success) {
        return response;
    }
}
let SyncRequestsCache = async () => {
    let successfulRequests = []
    console.log('Became online')
    isOnline = true
    let cache = JSON.parse(localStorage.getItem('PWA_REQUESTS'))
    console.log('cache of requestedObjs before filter: ', cache)
    if (cache && cache.length) {
        cache.forEach(async (requestObj, index) => {
            let response = await SetSeniorMealDelivered(requestObj)
            console.log('SetSeniorMealDelivered response: ', response)
            if (response.success) {
                successfulRequests.concat(index)
                UpdateMealDeliveredInCache(requestObj.seniorID)
            }
        })
    }
    // if (successfulRequests.length > 0) {
    //     cache = _.filter(cache, function (value, index) {
    //         if (_.contains(successfulRequests, index)) {
    //             return index;
    //         }
    //     });
    //     console.log('cache of requestedObjs after filter: ', cache)
    //     return true
    // }
    return false
}
export {
    GetRoutes,
    GetSeniorsByRoute,
    SetSeniorMealDelivered,
    GetAllSeniors,
    SyncRequestsCache,
    GetActivities,
    CancelSignature
}
