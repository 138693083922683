import React, { useEffect, useState } from 'react'
import { AppBar, Box, Button, Divider, IconButton, MenuItem, Select, Toolbar, Typography } from '@mui/material'
import { styled } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
const Root = styled(Box)(({ theme }) => ({
    height: '100%',
    padding: 0,
    "& .MainTitle": {
        fontSize: 15,
        fontWeight: 600,
        whiteSpace: "nowrap"
    },
    "& .subTitle": {
        fontSize: 15,
        fontWeight: 500,
        whiteSpace: "nowrap"
    },
    '& .header-cont': {
        // width: "100%",
        width: "device-width",
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1,
        background: "white",
        "& header": {
            display: "flex",
            height: 48,
            background: "white",
            fontSize: 12,
            alignItems: "center",
            "& .headerNav": {
                marginRight: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                "& .MuiIconButton-root:hover": {
                    backgroundColor: "transparent"
                }

            }
        },
    },
    '& .mainContainer': {
        backgroundImage: `url(${require('../Assets/loginBackgroundImg/img1.png')})`,
        backgroundSize: "cover",
        overflow: "hidden",
        backgroundPosition: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        overflowY: "auto",
        height: "calc(100vh - 48px)",
        marginTop: 48,
        "& .content": {
            padding: "10px",
            width: "100%",
            "& .infoCard": {
                padding: "10px",
                display: "flex",
                width: "100%",
                height: "fit-content",
                borderRadius: "5px",
                gap: "10px",
                marginBottom: "10px",
                backgroundColor: "#fff",
                "& .themeChanger": {
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    "& .input": {
                        padding: "0px",
                        borderRadius: '5px',
                        outline: "none",
                        border: "none",
                        fontSize: 15,
                        fontWeight: 500,
                    },
                    "& .select": {
                        border: "none",
                        outline: "none",
                        scrollBehavior: "smooth",
                        '& .MuiSelect-standard:focus': {
                            outline: 'none',
                            border: 'none',
                            background: "transparent"
                        },
                    },

                },
                "& .personalInfo": {
                    cursor: "pointer",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px"

                }
            },
            "& .buttons": {
                display: "flex",
                justifyContent: "end",
                gap: "5px"
            }
        },

    },

}));
export default function EditInfo() {
    const [info, setInfo] = useState({
        lastName: "Glaser",
        firstName: "Mike",
        cityName: "Los Angles",
        stateName: "California"
    })
    let navigate = useNavigate()
    let { state } = useLocation()

    const handleCancel = () => {
        alert("Cancel Successfully")
    }
    const handleSave = () => {
        alert("Saved Successfully")
    }
    useEffect(() => {
        if (state?.type === "name") {
            setInfo({ ...info, lastName: state.lastInfo, firstName: state.firstInfo })
        } else if (state?.type === "city") {
            setInfo({ ...info, cityName: state.firstInfo })
        } else {
            setInfo({ ...info, stateName: state?.firstInfo })
        }
    }, [])
    return (
        <Root p={2} >
            <Box className="header-cont">
                <Box sx={{ flexGrow: 1 }}>
                    <AppBar position="static">
                        <Toolbar sx={{ width: "100%", maxWidth: "100%", padding: "0px 15px  !important" }}>
                            <Box className="headerNav">
                                <Box
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    aria-label="menu"
                                    sx={{ mr: 2, display: "inline-flex", alignItems: "center", color: "black", gap: "10px" }}>
                                    <IconButton onClick={() => { navigate("/info") }}>
                                        <ArrowBackIosIcon sx={{ color: "black" }} />
                                    </IconButton>
                                    <Typography className="MainTitle"  >Edit Information</Typography>
                                </Box>
                            </Box>
                        </Toolbar>
                    </AppBar>
                </Box>
            </Box>
            <Box className="mainContainer">
                <Box className="content">
                    <Box className='infoCard'>
                        {state?.type === "name" ? (<Box className="personalInfo" >
                            <Box className="themeChanger" >
                                <Typography className="MainTitle" > First</Typography>
                                <input className='input' onChange={(e) => { setInfo({ ...state, firstName: e.target.value }) }} type='text' value={info.firstName} />
                            </Box>
                            <Divider />
                            <Box className="themeChanger">
                                <Typography className="MainTitle" >Last </Typography>
                                <input className='input' onChange={(e) => { setInfo({ ...state, lastName: e.target.value }) }} type='text' value={info.lastName} />
                            </Box>
                        </Box>) : state?.type === "city" ? (<Box className="personalInfo" >
                            <Box className="themeChanger" >
                                <Typography className="MainTitle" >City</Typography>
                                <Select
                                    className='select'
                                    id="city-select"
                                    value={info.cityName}
                                    onChange={(e) => { setInfo({ ...state, cityName: e.target.value }) }}
                                    label={info.cityName}
                                    disableUnderline
                                    variant='standard'
                                >
                                    <MenuItem sx={{ fontSize: "12px", fontWeight: "500" }} value={info.cityName}>{info.cityName}</MenuItem>
                                    {/* <MenuItem sx={{ fontSize: "12px", fontWeight: "500" }} value='Somerville'>Somerville</MenuItem> */}
                                    <MenuItem sx={{ fontSize: "12px", fontWeight: "500" }} value='Cleveland'> Cleveland </MenuItem>
                                    <MenuItem sx={{ fontSize: "12px", fontWeight: "500" }} value="Cincinnati">Cincinnati</MenuItem>
                                </Select>
                            </Box>
                        </Box>) : (
                            <Box className="themeChanger" >
                                <Typography className="MainTitle" >State</Typography>
                                <Select
                                    className='select'
                                    id="state-select"
                                    value={info.stateName}
                                    onChange={(e) => { setInfo({ ...state, stateName: e.target.value }) }}
                                    label={info.stateName}
                                    disableUnderline
                                    variant='standard'
                                >
                                    <MenuItem sx={{ fontSize: "12px", fontWeight: "500" }} value={info.stateName}>{info.stateName}</MenuItem>
                                    {/* <MenuItem sx={{ fontSize: "12px", fontWeight: "500" }} value='OH'>OH</MenuItem> */}
                                    <MenuItem sx={{ fontSize: "12px", fontWeight: "500" }} value='Delaware'>Delaware</MenuItem>
                                    <MenuItem sx={{ fontSize: "12px", fontWeight: "500" }} value="Georgia">Georgia</MenuItem>
                                </Select>
                            </Box>
                        )}
                    </Box>
                    <Box className="buttons">
                        <Button color='error' onClick={handleCancel} variant='contained'>Cancel</Button>
                        <Button color='success' onClick={handleSave} variant='contained'>Save</Button>
                    </Box>
                </Box>
            </Box>
        </Root>
    )
}
