import React, { useEffect, useState } from 'react'
import { AppBar, Box, IconButton, Toolbar, Typography } from '@mui/material'
import { styled } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
const Root = styled(Box)(({ theme }) => ({
    height: '100%',
    padding: 0,
    "& .MainTitle": {
        fontSize: 15,
        fontWeight: 600,
        whiteSpace: "nowrap"
    },
    "& .subTitle": {
        fontSize: 15,
        fontWeight: 500,
        whiteSpace: "nowrap"
    },
    '& .header-cont': {
        // width: "100%",
        width: "device-width",
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1,
        background: "white",
        "& header": {
            display: "flex",
            height: 48,
            background: "white",
            fontSize: 12,
            alignItems: "center",
            "& .headerNav": {
                marginRight: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",


            }
        },
    },
    '& .mainContainer': {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        overflowY: "auto",
        height: "calc(100vh - 48px)",
        marginTop: 48,
        "& .content": {
            padding: "10px",
            width: "100%",
            "& .infoCard": {
                padding: "10px",
                display: "flex",
                width: "100%",
                height: "fit-content",
                border: "1px solid #ccc",
                borderRadius: "5px",
                gap: "10px",
                marginBottom: "10px",
                "& .image": {
                    maxWidth: "50px",
                    maxHeight: "50px",
                    borderRadius: "50%",
                    overflow: "hidden",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    "& img": {
                        filter: "invert(1)",
                    }
                },
                "& .themeChanger": {
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                }
            },
            "& .mainAccordion": {
                boxShadow: "none",
                borderRadius: "5px",
                marginBottom: "10px",
                "& .accHeader": {
                    minHeight: "50px",
                    borderBottom: "1px solid #ccc",
                    "& .MuiAccordionSummary-content": {
                        margin: "10px 0px",

                    }
                },
                "& .items": {
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "10px 15px",
                    borderBottom: "1px solid #ccc"
                },
                "& .selectedItems": {
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "10px 15px",
                    borderBottom: "1px solid #ccc",
                    backgroundColor: "#34fd3429"
                }
            },

        },
        "& .version": {
            textAlign: "center",
            marginBottom: "20px"

        }
    },

}));

function Notification() {
    const [expanded, setExpanded] = useState(false);
    const [selectedTile, setSelectedTile] = useState(null);
    const [notification, setNotification] = useState([])
    const [title, setTitle] = useState("Notification")
    let { state } = useLocation()
    let navigate = useNavigate()
    const senioNotification = [
        {
            name: "Scanner Sound",
            path: `${require('../Assets/audio/scanner-beep.mp3')}`
        },
        {
            name: "Login Sound",
            path: `${require('../Assets/audio/beep.mp3')}`
        },
        {
            name: "Logout Sound",
            path: `${require('../Assets/audio/greanpatch.mp3')}`
        },
        {
            name: "Success Sound",
            path: `${require('../Assets/audio/menu-click.mp3')}`
        },
        {
            name: "Bouncy Sound",
            path: `${require('../Assets/audio/bouncy-sound.mp3')}`
        },
        {
            name: "Error Sound",
            path: `${require('../Assets/audio/error1.mp3')}`
        },
        {
            name: "Failed Sound",
            path: `${require('../Assets/audio/error-2.mp3')}`
        },
        {
            name: "Denied Sound",
            path: `${require('../Assets/audio/select_denied.mp3')}`
        },
        {
            name: "Message Sound",
            path: `${require('../Assets/audio/notifications.mp3')}`
        },
    ]
    const mealNotification = [
        {
            name: "Scanner Sound",
            path: `${require('../Assets/audio/scanner-beep.mp3')}`
        },
        {
            name: "Login Sound",
            path: `${require('../Assets/audio/beep.mp3')}`
        },
        {
            name: "Logout Sound",
            path: `${require('../Assets/audio/greanpatch.mp3')}`
        },
        {
            name: "Success Sound",
            path: `${require('../Assets/audio/menu-click.mp3')}`
        },
        {
            name: "Bouncy Sound",
            path: `${require('../Assets/audio/bouncy-sound.mp3')}`
        },
        {
            name: "Error Sound",
            path: `${require('../Assets/audio/error1.mp3')}`
        },
        {
            name: "Failed Sound",
            path: `${require('../Assets/audio/error-2.mp3')}`
        },
        {
            name: "Denied Sound",
            path: `${require('../Assets/audio/select_denied.mp3')}`
        },
        {
            name: "Message Sound",
            path: `${require('../Assets/audio/notifications.mp3')}`
        },
    ]
    const driverNotification = [
        {
            name: "Scanner Sound",
            path: `${require('../Assets/audio/scanner-beep.mp3')}`
        },
        {
            name: "Login Sound",
            path: `${require('../Assets/audio/beep.mp3')}`
        },
        {
            name: "Logout Sound",
            path: `${require('../Assets/audio/greanpatch.mp3')}`
        },
        {
            name: "Success Sound",
            path: `${require('../Assets/audio/menu-click.mp3')}`
        },
        {
            name: "Bouncy Sound",
            path: `${require('../Assets/audio/bouncy-sound.mp3')}`
        },
        {
            name: "Error Sound",
            path: `${require('../Assets/audio/error1.mp3')}`
        },
        {
            name: "Failed Sound",
            path: `${require('../Assets/audio/error-2.mp3')}`
        },
        {
            name: "Denied Sound",
            path: `${require('../Assets/audio/select_denied.mp3')}`
        },
        {
            name: "Message Sound",
            path: `${require('../Assets/audio/notifications.mp3')}`
        },

    ]
    useEffect(() => {
        if (state === "Driver") {
            setTitle("Driver Notification")
            setNotification(driverNotification)
        } else if (state === "Meal") {
            setNotification(mealNotification)
            setTitle("Meal Notification")
        } else {
            setNotification(senioNotification)
            setTitle("Senior Notification")
        }
    }, [])
    //Accordian
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const handleAudio = (path, id) => {
        setSelectedTile(id)
        let filepath = path
        var audio = new Audio(filepath);
        audio.play();
    }
    return (
        <Root p={2} >
            <Box className="header-cont">
                <Box sx={{ flexGrow: 1 }}>
                    <AppBar position="static">
                        <Toolbar sx={{ width: "100%", maxWidth: "100%", padding: "0px 15px  !important" }}>
                            <Box className="headerNav">
                                <Box
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    aria-label="menu"
                                    sx={{ mr: 2, display: "inline-flex", alignItems: "center", color: "black", gap: "10px" }}>
                                    <IconButton onClick={() => { navigate("/settings") }}>
                                        <ArrowBackIosIcon sx={{ color: "black" }} />
                                    </IconButton>
                                    <Typography className="MainTitle"  >{title}</Typography>
                                </Box>
                            </Box>
                        </Toolbar>
                    </AppBar>
                </Box>
            </Box>
            <Box className="mainContainer">
                <Box className="content">
                    <Box className='mainAccordion' expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                        {notification?.map((e, i) => {
                            return <Box key={i} onClick={() => handleAudio(e.path, e.name)} className={e.name === selectedTile ? "selectedItems" : "items"}><Typography className="subTitle">{e.name}</Typography>{e.name === selectedTile ? <CheckIcon color='success' /> : ""} </Box>
                        })}
                    </Box>
                </Box>
            </Box>
        </Root>
    )
}

export default Notification