import React, { useEffect, useRef, useState } from 'react';
// import { BarcodeFormat } from '@zxing/library';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import { AppBar, Button, DialogActions, Slide, Toolbar, Typography } from '@mui/material';
import AuthService from '../Services/AuthService';
import { useNavigate } from 'react-router-dom';

import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogContent, DialogTitle, } from '@mui/material';
import { useZxing } from "react-zxing";
import { BarcodeFormat, DecodeHintType } from "@zxing/library";
import { GetAllSeniors, GetRoutes } from '../Services/Api/MealService';
import { keyframes } from '@emotion/react';
// dialog transition animation
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const Root = styled(Box)(({ theme }) => ({
    '& .header-cont': {
        width: "100%",
        width: "device-width",
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1,
        "& header": {
            display: "flex",
            height: 48,
            background: "white",
            fontSize: 12,
            alignItems: "center",
            '& img[alt="Logo"]': {
                width: 28,
                // filter: 'invert(1)',
            },
            "& .HeaderScan": {
                width: "100%",
                maxWidth: "100%",
                paddingLeft: "38px",
                paddingRight: "8px",
                [theme.breakpoints.down('md')]: {
                    padding: "0px 8px 0 40px",
                },
            },
            "& .headerNav": {
                // marginRight: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                "& .fuel": {
                    color: "red",
                    position: "absolute",
                    width: "26px",
                    right: "62px",
                    top: "12px",
                    cursor: "pointer"
                },
                "& .pageMainTitle": {
                    fontSize: 16,
                    fontWeight: 600,
                    whiteSpace: "nowrap"
                },
                "& .calenderIcon": {
                    [theme.breakpoints.down('sm')]: {
                        display: "none"
                    },
                },
                "& .headerText": {
                    display: "flex",
                    color: '#1f0757',
                    fontSize: 13,
                    textShadow: '#1f0757',
                    fontWeight: 600,
                    whiteSpace: "nowrap"
                }
            }
        },
    },
    "& .MainTitle1": {
        fontSize: 14,
        fontWeight: 600,
        textShadow: "black",
        whiteSpace: "nowrap"
    },
    "& .MainTitle": {
        fontSize: 15,
        fontWeight: 600,
        whiteSpace: "nowrap"
    },
    "& .subTitle": {
        fontSize: 15,
        fontWeight: 500,
        whiteSpace: "nowrap"
    },
    '& .mainContainer': {
        // backgroundImage: `url(${require('../Assets/loginBackgroundImg/img1.png')})`,
        // backgroundSize: "cover",
        // backgroundPosition: "center",

        display: "flex",
        // flexDirection: "column",
        alignItems: "start",
        justifyContent: "center",
        height: "100vh",
        padding: "50px 0 0",
        overflow: "hidden",
        "& .content": {
            width: "100%",
            padding: "0px 0px",
            height: "calc(100vh - 52px)",
            "& .infoCard": {
                padding: "10px",
                display: "flex",
                width: "100%",
                height: "fit-content",
                gap: "10px",
                marginBottom: "10px",
                backgroundColor: "#fff",
                borderBottom: "1px solid #ccc",
                "& .image": {
                    width: "50px",
                    height: "50px",
                    borderRadius: "50%",
                    overflow: "hidden",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#b99ec7d4",

                },

            },
        },
        "& .leftChild": {
            position: 'relative',
            width: "50%",
            [theme.breakpoints.down('sm')]: {
                width: '100vw',
            },
            "& .scannerContainer": {
                position: "relative",
                height: "65vh",
                backgroundColor: "#333",
                borderBottom: "1px solid #ccc",
                "& .resultText": {
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 10
                },
                "& .CodeScanner": {
                    width: "100%",
                    height: "100%",
                    objectFit: "fit-content",
                },
                [theme.breakpoints.down('sm')]: {
                    height: "95vh"
                },
            },
            "& .welcomeContainer": {
                height: "35vh",
                [theme.breakpoints.down('sm')]: {
                    display: "none"
                },
            },

            '& .cornerBorder': {
                position: "absolute",
                left: "50%",
                top: "45%",
                zIndex: 1,
                width: "80%",
                height: "300px",
                transform: "translate(-50%, -50%)",
                background: `
      linear-gradient(to right, black 4px, transparent 4px) 0 0,
      linear-gradient(to right, black 4px, transparent 4px) 0 100%,
      linear-gradient(to left, black 4px, transparent 4px) 100% 0,
      linear-gradient(to left, black 4px, transparent 4px) 100% 100%,
      linear-gradient(to bottom, black 4px, transparent 4px) 0 0,
      linear-gradient(to bottom, black 4px, transparent 4px) 100% 0,
      linear-gradient(to top, black 4px, transparent 4px) 0 100%,
      linear-gradient(to top, black 4px, transparent 4px) 100% 100%`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: '30px 100%',
            },
        },
        "& .rightChild": {
            width: "50%",
            [theme.breakpoints.down('sm')]: {
                display: 'none'
            },
            overflowY: "auto",
            padding: "0 10px",
            borderLeft: "1px solid #ccc",

        },


        "& .ListIcons": {
            position: "absolute",
            right: 20,
            bottom: 20,
            height: "50px",
            width: "50px",
            backgroundColor: "white",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        },
        "& .beepIcons": {
            position: "absolute",
            right: 20,
            bottom: 20,
            height: "50px",
            width: "50px",
            backgroundColor: "white",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        },
        "& .smBeepIcon": {
            [theme.breakpoints.down('sm')]: {
                display: 'none'
            },
        },
        "& .mdBeepIcon": {
            [theme.breakpoints.up('sm')]: {
                display: 'none'
            },
        },
        "& .cameraIcons": {
            position: "absolute",
            left: 20,
            bottom: 20,
            height: "50px",
            width: "50px",
            backgroundColor: "white",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        },
        "& .StopScanButton": {
            margintop: "20px"
        },
        "& .loader": {
            position: "absolute",
            top: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
        },
    },
}));
const DialogBox = styled(Dialog)(({ theme }) => ({
    "& .input": {
        padding: "15px", borderRadius: '5px', backgroundColor: "white",
        margin: "10px 0px",
        width: "100%",
        border: "1px solid #022c2f",
        outline: "none",
    },
    "& .content": {
        width: "100%",
        "& .infoCard": {
            padding: "10px",
            display: "flex",
            width: "100%",
            height: "fit-content",
            gap: "10px",
            marginBottom: "10px",
            backgroundColor: "#fff",
            borderBottom: "1px solid #ccc",
            "& .image": {
                width: "50px",
                height: "50px",
                borderRadius: "50%",
                overflow: "hidden",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#b99ec7d4",

            },

        },
    },
    "& .MainTitle1": {
        fontSize: 14,
        fontWeight: 600,
        textShadow: "black",
        whiteSpace: "nowrap"
    },
    "& .MainTitle": {
        fontSize: 15,
        fontWeight: 600,
        whiteSpace: "nowrap"
    },
    "& .subTitle": {
        fontSize: 15,
        fontWeight: 500,
        whiteSpace: "nowrap"
    },
}));
const fadeIn = keyframes`
     0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
`;
const textAnimation = keyframes`
     0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
 
`;
const { GS1_DATABAR, MAXICODE } = BarcodeFormat;

function NewScanner() {
    const [state, setState] = React.useState({
        logoutAlert: false,
        welcomePopup: false,
        seniorDataPopup: false,
    });
    const [data, setData] = useState({
        keyTags: '',
        seniors: []
    });
    const isScan = useRef(true);
    const number = useRef(0);
    const [devices, setDevices] = useState([]);
    const [deviceId, setDeviceId] = useState(null);
    const [flipCamera, setFlipCamera] = useState(true)
    const [isLoading, setIsLoading] = useState(true);
    const [isBeepAllow, setIsBeepAllow] = useState(true);
    const [seniorData, setSeniorData] = useState([]);
    const [isLoading1, setIsLoading1] = useState(true);
    let navigate = useNavigate()
    let seniorInfo = [{ name: "Valerie Schmitt", img: "1.jpg", city: "102 Olde Farm Road" },]
    useEffect(() => {
        if (deviceId) {
            setIsLoading(false);
        }
    }, [deviceId]);
    // useEffect(() => {
    //     setSeniorData(seniorInfo)
    // }, [])
    useEffect(() => {
        let Token = AuthService.getAuthDataByName("token") || "";
        if (Token === "") {
            navigate("/");
        }// eslint-disable-next-line
    }, [number.current])


    ///   

    useEffect(() => {
        const getDevices = async () => {
            try {
                const devices = await navigator.mediaDevices.enumerateDevices();
                setDevices(devices);
                const videoDevices = devices.filter(
                    (device) => device.kind === "videoinput"
                );
                if (videoDevices.length > 0) {
                    setDeviceId(videoDevices[0].deviceId);
                }
            } catch (error) {
                console.error("Error enumerating devices:", error);
            }
        };

        getDevices();
    }, []);
    const constraints = {
        video: { facingMode: flipCamera ? 'user' : 'environment' },
        audio: false
    };
    const { ref } = useZxing({
        constraints: constraints,
        onDecodeResult(result) {

            if (isScan.current) {
                isScan.current = false
                number.current += 1
                if (isBeepAllow) {
                    handleAudio()
                }
                // setState({ ...state, welcomePopup: true })
                // setState({ ...state, welcomePopup: false })
                setData({ ...data, keyTags: result.text })
                getSeniorIdByTag(result.text)
            }
            setTimeout(() => {
                setIsLoading(false);
                isScan.current = true
            }, 1000);

        },
        hint: {
            [DecodeHintType.POSSIBLE_FORMATS]: [
                BarcodeFormat.UPC_A,
                BarcodeFormat.UPC_E,
                BarcodeFormat.EAN_8,
                BarcodeFormat.EAN_13,
                BarcodeFormat.CODE_39,
                BarcodeFormat.CODE_93,
                BarcodeFormat.CODE_128,
                BarcodeFormat.CODABAR,
                BarcodeFormat.ITF,
                BarcodeFormat.RSS14,
                BarcodeFormat.RSS_EXPANDED,
                BarcodeFormat.QR_CODE,
                BarcodeFormat.DATA_MATRIX,
                BarcodeFormat.AZTEC,
                BarcodeFormat.PDF_417,
                BarcodeFormat.GS1_DATABAR,
                BarcodeFormat.MAXICODE
            ]
        }
    });

    const toggleFlipCamera = () => {
        setFlipCamera((prevState) => !prevState);
    };
    const handleBeep = () => {
        setIsBeepAllow(!isBeepAllow)
    };
    ///
    const handleAudio = async () => {
        var audio = new Audio(require('../Assets/audio/scanner-beep.mp3'));
        await audio.play();
    }
    useEffect(() => {
        let Token = AuthService.getAuthDataByName("token") || "";
        if (Token === "") {
            navigate("/");
        } else {
            // GetInitialDetails();
        }
    }, [])
    const getSeniorIdByTag = (keyTags) => {
        try {
            let allTags = JSON.parse(localStorage.getItem('KayTags'));
            let Seniors = JSON.parse(localStorage.getItem('Seniors'));
            let selectedSenior = allTags.find(tag => tag.cardID === keyTags);
            let seniorId = selectedSenior.seniorID;
            let senior = Seniors.find(item => item.seniorID === seniorId);
            setSeniorData(senior)
            let copyData = [...data.seniors]
            copyData.unshift(senior)
            setData({ ...data, seniors: copyData })
        } catch (error) {
            console.log(error)
        }
    }

    // useEffect(() => {
    //     let slicedSeniorInfo = seniorInfo.slice(0, number.current);
    //     setSeniorData(slicedSeniorInfo)
    // }, [number.current])

    const handleClose = () => {
        setState({ ...state, logoutAlert: false })
        isScan.current = true
    }
    const handleWelcomClose = (e, reason) => {
        if (reason && reason === "backdropClick") {
            return;
        }
        setState({ ...state, welcomePopup: false })
        isScan.current = true
    }
    function handleFilter(e) {
        let query = e.target.value
        query = query.toLowerCase();
        let slicedSeniorInfo = seniorInfo.slice(0, number.current);
        let data = slicedSeniorInfo.filter(senior => senior.name.toLowerCase().includes(query));
        setSeniorData(data)
    }

    const [animationTrigger, setAnimationTrigger] = useState(false);

    useEffect(() => {
        setAnimationTrigger(true);
        const timeout = setTimeout(() => {
            setAnimationTrigger(false);
        }, 2000);
        return () => clearTimeout(timeout);
    }, [number.current]);
    return (
        <Root >
            {/* Header */}

            <Box className="header-cont">

                <Box sx={{ flexGrow: 1 }}>
                    <AppBar position="static">
                        <Toolbar
                            className='HeaderScan'
                            sx={{}}>
                            <Box className="headerNav">
                                <Box
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    aria-label="menu"
                                    sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                        <img src={require('../Assets/images/events.png')} alt="Logo" style={{ marginRight: 10 }} />
                                        <Typography color="black" className="pageMainTitle" id="pageTitle"> Event Name </Typography>
                                    </Box>
                                    <Box sx={{ display: "flex", alignItems: "center", gap: '5px' }}>
                                        <Typography color="black" className="pageMainTitle" id="pageTitle"> 11:20pm </Typography>

                                        <img className="calenderIcon" onClick={() => setState({ ...state, logoutAlert: true })} src={require('../Assets/images/changeEventIcon.png')} alt="Logo" />

                                        <Box sx={{ display: { sm: "none" } }} onClick={handleBeep} className="beepIcons">
                                            {!isBeepAllow ? <img src={require('../Assets/images/no-audio.png')} width="30px" alt="cam" /> :
                                                <img src={require('../Assets/images/volumeIcon.png')} width="30px" alt="cam" />}
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Toolbar>
                    </AppBar>
                </Box>
            </Box>
            <Box className="mainContainer">
                {isLoading ? <Box className="loader">
                    {/* <img src={require('../Assets/images/videoLoader.gif')} alt='logo' /> */}
                </Box> : ""}
                <Box className='leftChild'>
                    <Box className='scannerContainer'>
                        <video
                            // style={{ transform: flipCamera ? 'scaleX(-1)' : 'none' }}
                            id="video" playsInline className='CodeScanner' ref={ref} />
                        <Box className="cornerBorder"></Box>
                        {/* <Box onClick={() => setState({ ...state, logoutAlert: true })} className="ListIcons">
                            <img src={require('../Assets/images/list-icon.png')} width="30px" alt="list" />
                        </Box> */}
                        <Box onClick={handleBeep} className="beepIcons smBeepIcon">
                            {!isBeepAllow ? <img src={require('../Assets/images/no-audio.png')} width="30px" alt="cam" /> :
                                <img src={require('../Assets/images/volumeIcon.png')} width="30px" alt="cam" />}
                        </Box>

                        <Box onClick={() => setState({ ...state, seniorDataPopup: true })} className="beepIcons mdBeepIcon">
                            <img src={require('../Assets/images/list-icon.png')} width="30px" alt="cam" />
                        </Box>

                        <Box onClick={toggleFlipCamera} className="cameraIcons">
                            <img src={require('../Assets/images/camera.png')} width="30px" alt="cam" />
                        </Box>


                    </Box>
                    <Box className='welcomeContainer'>
                        {number.current > 0 ? <Box className="content" sx={{ borderBottom: 0, }}>
                            <Box className='infoCard' sx={{ borderBottom: "0 !important", marginBottom: "0px !important", marginTop: "25px !important", alignItems: "center", animation: `${fadeIn} 0.2s forwards ${animationTrigger ? ', ' + textAnimation + ' 0.2s forwards' : ''}` }}>
                                <Box className="image" style={{ width: "150px", height: "150px" }}>
                                    <img src={seniorData?.photoURL} sx={{}} alt="imge" width="100%" height="100%" />
                                </Box>
                                <Box >
                                    <Typography className="MainTitle" style={{ fontSize: "30px" }}> Welcome </Typography>
                                    <Typography className="MainTitle1" style={{ fontSize: "24px" }}> {seniorData?.firstName} {seniorData?.lastName} </Typography>
                                    <Typography className="subTitle" style={{ fontSize: "20px" }}>Check In :{seniorData?.city}</Typography>
                                </Box>
                            </Box>

                        </Box>
                            : <Box style={{ display: "flex", flexDirection: "column", height: "78%", justifyContent: "center", textAlign: "center", alignItems: "center", }}>
                                <img src={require(`../Assets/images/notfound.png`)} sx={{}} alt="imge" width="100px" height="100px" />
                                <Typography variant='h3' >Please Scan Barcode</Typography>

                            </Box>}
                    </Box>
                </Box>
                <Box className='rightChild' >
                    <Box className="content">
                        {/* {seniorInfo?.map((e, i) => (
                            <Box key={i} className='infoCard'>
                                <Box className="image">
                                    <img src={require(`../Assets/seniors-img/${e.img}`)} sx={{}} alt="imge" width="80%" height="80%" />
                                </Box>
                                <Box>
                                    <Typography className="MainTitle" > {e.name} </Typography>
                                    <Typography className="subTitle">{e.checkIn}</Typography>
                                </Box>
                            </Box>
                        ))} */}
                        {data?.seniors?.map((data, index) => (
                            <SeniorInformation key={index} data={data} />
                        ))}

                    </Box>
                </Box>


            </Box>
            {/* popUp < sm screen for senior info */}
            <DialogBox
                fullScreen
                TransitionComponent={Transition}
                open={state.seniorDataPopup}
                onClose={() => setState({ ...state, seniorDataPopup: false })}
                aria-labelledby="logout-alert-title"
                aria-describedby="logout-alert-description"
            >
                <DialogTitle id="logout-alert-title" sx={{ fontSize: "20px", paddingBottom: "8px", paddingLeft: "15px", paddingRight: "10px" }}>
                    <Box sx={{ display: "flex", justifyContent: "end", }}>
                        <CloseIcon onClick={() => setState({ ...state, seniorDataPopup: false })} />
                    </Box>
                    {/* <input className='input' onChange={handleFilter} inputprops={{ style: { fontSize: 12, fontWeight: 600 } }} type='text' placeholder='Search List' /> */}
                </DialogTitle>
                <DialogContent sx={{ paddingBottom: "10x", paddingLeft: "15px" }}>
                    <Box className="content">
                        {data?.seniors?.map((data, index) => (
                            <SeniorInformation key={index} data={data} />
                        ))}
                    </Box>
                </DialogContent>
            </DialogBox>

            {/* PopUp */}
            <DialogBox
                fullScreen
                open={state.logoutAlert}
                onClose={() => setState({ ...state, logoutAlert: false })}
                aria-labelledby="logout-alert-title"
                aria-describedby="logout-alert-description"
            >

                <DialogTitle id="logout-alert-title" sx={{ fontSize: "20px", paddingBottom: "8px", paddingLeft: "15px", paddingRight: "10px" }}>
                    <Box sx={{ display: "flex", justifyContent: "end", }}>

                        <CloseIcon onClick={handleClose} />
                    </Box>
                    <input className='input' onChange={handleFilter} inputprops={{ style: { fontSize: 12, fontWeight: 600, } }} type='text' placeholder='Search Event List' />
                </DialogTitle>
                <DialogContent sx={{ paddingBottom: "10x", paddingLeft: "15px" }}>
                    <Box className="content">
                        {/* {seniorData?.map((e, i) => (
                            <Box key={i} className='infoCard'>
                                <Box className="image">
                                    <img src={require(`../Assets/images/events1.png`)} sx={{}} alt="imge" width="60%" height="60%" />
                                </Box>
                                <Box>
                                    <Typography className="MainTitle" > Event Name </Typography>
                                    <Typography className="subTitle">Location</Typography>
                                </Box>
                            </Box>
                        ))} */}

                    </Box>
                </DialogContent>

            </DialogBox>
            {/* PopUp2 */}
            <DialogBox
                disablebackdropclick={true}
                fullWidth
                open={state.welcomePopup}
                onClose={handleWelcomClose}
                aria-labelledby="logout-alert-title"
                aria-describedby="logout-alert-description"
            >
                <DialogContent sx={{ padding: 0 }}>
                    <Box className="content" sx={{ borderBottom: 0, }}>
                        <Box className='infoCard' sx={{ borderBottom: "0px", marginBottom: "0px !important", }}>
                            <Box className="image">
                                <img src={seniorData?.photoURL} sx={{}} alt="imge" width="80%" height="80%" />
                            </Box>
                            <Box>
                                <Typography className="MainTitle" > Welcome </Typography>
                                <Typography className="MainTitle1" > {seniorData?.firstName} {seniorData?.lastName} </Typography>
                                <Typography className="subTitle">Check In :{seniorData?.city}</Typography>
                            </Box>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions sx={{ paddingLeft: "15px", paddingRight: "12px", display: 'flex', justifyContent: "end" }}>
                    <Button sx={{ backgroundColor: "#000524", color: "white" }} size='small' variant="contained" onClick={handleWelcomClose} >Ok</Button>
                </DialogActions>
            </DialogBox>
        </Root>

    );
}
export default NewScanner;


const SeniorInformation = (props) => {
    return (
        <Box className='infoCard'>
            <Box className="image">
                <img src={props?.data?.photoURL} sx={{}} alt="imge" width="80%" height="80%" />
            </Box>
            <Box>
                <Typography className="MainTitle" > {props?.data?.firstName} {props?.data?.lastName} </Typography>
                <Typography className="subTitle">{props?.data?.city}</Typography>
            </Box>
        </Box>
    );
}