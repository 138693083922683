import React, { useState } from 'react';
import { Box, Button, DialogContent, Divider, IconButton, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { SetSeniorMealDelivered } from '../../Services/Api/MealService';
import { styled } from '@mui/material/styles';
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import DefaultLayout from '../../Layouts/DefaultLayout';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import SignaturePad from 'signature_pad';
import AuthService from '../../Services/AuthService';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const Root = styled(Box)(({ theme }) => ({
    height: '100%',
    padding: 0,

    '& .mainContainer': {

        backgroundImage: `url(${require('../../Assets/loginBackgroundImg/img1.png')})`,
        backgroundSize: "cover",
        overflow: "hidden",
        backgroundPosition: "center",
        height: "calc(100vh - 48px)",
        marginTop: 48,
        textAlign: "center",
        "& .sign": {
            margin: "20px auto ",
            backgroundImage: `url(${require('../../Assets/images/signhere.png')})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundPosition: "center",
            '& canvas': {
                width: "100%",
                border: '1px solid grey',
                borderRadius: '5px',
            },
        },
        "& .doneBtn": {
            fontSize: 18,
            backgroundColor: theme.palette.success.light,
            borderRadius: 20,
            color: theme.palette.secondary.contrastText,
        },
        "& .btn": {
            fontSize: 18,
            backgroundColor: theme.palette.primary.contrastText,
            margin: 10,
            width: "95%"
        },
        "& .outlineButton": {
            fontSize: 18,
            color: theme.palette.button.main,
            borderColor: theme.palette.button.main,
            borderRadius: 20,
        },
        "& .containedButton": {
            fontSize: 18,
            color: theme.palette.primary.contrastText,
            borderColor: theme.palette.button.main,
            borderRadius: 20,
            backgroundColor: theme.palette.button.main
        },
    },

    '& img[alt="heartpng"]': {
        width: 50,
    },

}));
const DialogBox = styled(Dialog)(({ theme }) => ({
    "& .titleBoxDialogTwo": {
        display: "flex",
        justifyContent: "end",
        padding: "4px 8px",
        "& .MuiSvgIcon-root": {
            color: theme.palette.background.black,
            fontSize: 30,
        },
    },
    "& .active": {
        backgroundColor: "#edaeae",
    },

    "& .routeTextDialogTwo": {
        textAlign: "center",
        fontSize: 20,
        fontStyle: "italic",
    },
}));
export default function SignatureArea(props) {
    const [isLoading, setIsLoading] = useState(false)
    const [openDialogTwo, setOpenDialogTwo] = React.useState(false);
    let location = useLocation();
    let { routeID, seniorID, mealID, senior } = location.state;
    let navigate = useNavigate()
    const ref = React.useRef(null);
    const pad = React.useRef();
    React.useEffect(() => {
        let Token = AuthService.getAuthDataByName("token") || "";
        if (Token === "") {
            navigate("/");
        } else {
            // resize canvas to full size
            ref.current.width = ref.current.parentElement.offsetWidth;
            pad.current = new SignaturePad(ref.current);
        }
    });
    const setSignature = () => {
        setIsLoading(true);
        if (!pad.current.isEmpty()) {
            SetSeniorMealDelivered(routeID, seniorID, mealID, pad.current.toDataURL())
            setTimeout(() => {
                if (senior === true) {
                    navigate("/senior");
                    setIsLoading(false);
                } else {
                    navigate("/routesenior");
                    setIsLoading(false);
                }
            }, 2000);
        } else {
            setOpenDialogTwo(true)
            setIsLoading(false);
        }

    }
    // ======== Dialog  =======
    const handleCross2 = () => {
        setOpenDialogTwo(false);
    };
    return (
        <DefaultLayout>
            <Root p={2} >
                <Box className="mainContainer" sx={{ px: 2 }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", margin: "12px 8px" }}>
                        <Button className='containedButton' variant='contained' onClick={() => { navigate(-1) }} startIcon={<ArrowBackIosNewIcon />}>Back</Button>
                        <Button className='containedButton' variant='contained' onClick={() => { navigate("/submitform", { state: { routeID: routeID, seniorID: seniorID, mealID: mealID } }) }} startIcon={<StickyNote2Icon />}>Note</Button>
                    </Box>
                    <Box
                        className="sign" id="signature-pad">
                        <canvas ref={ref} />
                        <Box> <Button className='doneBtn' fullWidth onClick={() => setSignature()} > {isLoading ? "Loading..." : "Done"} </Button> </Box>
                    </Box>
                </Box>
                {/* Dialog  */}
                <DialogBox
                    open={openDialogTwo}
                    onClose={handleCross2}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <Box className="titleBoxDialogTwo">
                        <IconButton onClick={handleCross2}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Divider />
                    <DialogContent>
                        <Box className="routeBoxInner">
                            <Box className="routeTextDialogTwo"> <Typography variant="p"> No Signature</Typography> </Box>
                        </Box>
                    </DialogContent>
                </DialogBox>
            </Root>
        </DefaultLayout>
    )

}