import React, { useCallback, useEffect, useRef, useState } from 'react';
import AuthService from '../../Services/AuthService';
import { GetRoutes, GetAllSeniors, GetSeniorCount, GetSeniorsBySite, GetAllKeytags } from '../../Services/Api/MealService';
import { AppBar, Box, CircularProgress, Toolbar, Typography, IconButton, TextField, InputAdornment, LinearProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { BeatLoader, PulseLoader } from 'react-spinners';
import RefreshIcon from '@mui/icons-material/Refresh';

import SearchIcon from '@mui/icons-material/Search';
const Root = styled(Box)(({ theme, loader }) => ({
  height: '100%',
  padding: 0,
  backgroundColor: "#f8e9f7",
  '& .header-cont': {
    // width: "100%",
    width: "device-width",
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1,
    "& header": {
      display: "flex",
      height: 48,
      background: "white",
      fontSize: 12,
      alignItems: "center",
      '& img[alt="Logo"]': {
        width: 34,
        filter: 'invert(1)',
      },
      "& .headerNav": {
        // marginRight: 10,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        "& .fuel": {
          color: "red",
          position: "absolute",
          width: "26px",
          right: "62px",
          top: "12px",
          cursor: "pointer"
        },
        "& .pageMainTitle": {
          fontSize: 15,
          fontWeight: 600,
          whiteSpace: "nowrap"
        },
        "& .headerText": {
          display: "flex",
          color: '#1f0757',
          fontSize: 13,
          textShadow: '#1f0757',
          fontWeight: 600,
          whiteSpace: "nowrap"
        },
        "& .refreshingIcon": {
          color: "Grey"
        },
        "& .reloadBtn": {
          padding: "0px 0px 4px 0px"
        }
      }
    },
  },
  '& .mainContainer': {
    backgroundImage: `url(${require('../../Assets/loginBackgroundImg/img1.png')})`,
    backgroundSize: "cover",
    overflow: "hidden",
    backgroundPosition: "center",
    backgroundColor: "#f8e9f7",
    height: "calc(100vh - 56px)",
    marginTop: loader ? 50 : 56,
    overflowY: "auto",
    "& .routeTextNull": {
      height: "80vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column"
    },
    "& .routeBox": {
      margin: "20px auto ",
      display: "flex",
      width: "100",
      minWidth: 310,
      gap: 10,
      backgroundColor: theme.palette.primary.contrastText,
      padding: "10px",
      boxShadow: "0px 3px 15px -6px rgb(0 0 0 / 50%)",
      borderRadius: 10,
      "& .image": {
        height: "50px",
        width: "50px",
        borderRadius: "50%",
        overflow: "hidden",
        "& img": {
          objectFit: "contain",
          width: "100%",
          height: "100%"
        }
      },
      "& .routeText": {
        display: "flex",
        flexDirection: "column",
      },
      "& .routetextColor": {
        fontSize: 20,
        fontWeight: 400,
        color: theme.palette.other.main
      },
      "& .textColor": {
        fontSize: 16,
        fontWeight: 300,
        color: theme.palette.primary.dark
      }
    },
  },
  '& img[alt="heartpng"]': {
    width: 50,
  },
}));
export default function Meal() {

  const [state, setState] = useState({
    routes: [],
    seniorId: [],
    seniorsList: null,
  })
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
  const [progress, setProgress] = React.useState(10);
  const [loader, setLoader] = useState(false);
  const [seniorListBySite, setSeniorListBySite] = useState([]);
  const [filteredData, setFilterData] = useState([]);
  const [visibleSeniors, setVisibleSeniors] = useState(10);
  const [countLoader, setCountLoader] = useState(true)

  const containerRef = useRef(null);
  let navigate = useNavigate();

  useEffect(() => {
    let isAlreadyVisited = JSON.parse(localStorage.getItem('alreadyVisited'));
    if (!isAlreadyVisited) {
      localStorage.setItem('alreadyVisited', true)
      getSeniorsList()
    }

    // if (state.seniorsList === null) {  
    // GetInitialDetails();

    // }

    // eslint-disable-next-line
  }, [])
  //New Work 
  useEffect(() => {
    const seniors = JSON.parse(localStorage.getItem('Seniors'));
    setSeniorListBySite(seniors);
    setFilterData(seniors);
  }, []);
  //Scroll function
  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        const bottom = containerRef.current.scrollHeight - containerRef.current.scrollTop === containerRef.current.clientHeight;
        if (bottom && visibleSeniors < seniorListBySite.length) {
          setVisibleSeniors(prevVisibleSeniors => prevVisibleSeniors + 10);
        }
      }
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll); // Cleanup on unmount
      }
    };
  }, [visibleSeniors, seniorListBySite]);


  React.useEffect(() => {

  }, []);

  const getSeniorsList = async () => {
    setLoader(true)
    setProgress(0)
    const timer = setInterval(() => {
      if (progress >= 80) {
        setProgress((prevProgress) => (prevProgress >= 100 ? 80 : prevProgress + 10));
      }

    }, 800);
    try {
      let res = await GetSeniorsBySite()
      // setSeniorListBySite(res.data)
      localStorage.setItem('Seniors', JSON.stringify(res.data))
      setProgress(100)
    } catch (error) {
      console.log(error)
      setLoader(false)
    } finally {
      clearInterval(timer);
      const seniors = JSON.parse(localStorage.getItem('Seniors'));
      setSeniorListBySite(seniors);
      setFilterData(seniors);
      getAllKey()
    }
  }
  const getAllKey = async () => {
    try {
      let res = await GetAllKeytags();
      // setKeyTags(res.data)
      localStorage.setItem('KayTags', JSON.stringify(res.data))
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false)
    }
  }
  const openDetail = (id) => {
    navigate("/routesenior", { state: id })
  }

  // const handleSearchChange = (event) => {
  //   setSearchTerm(event.target.value);
  //   if (!event.target.value) return seniorListBySite;
  //   const filteredValues = seniorListBySite?.filter(item => {
  //     let fullName = `${item.firstName} ${item.lastName}`
  //     return fullName?.toLowerCase()?.includes(event.target.value.toLowerCase())
  //   }
  //   );
  //   setFilterData(filteredValues);
  // };
  // Debounce the search term by 1 second
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 700);

    // Cleanup function to clear the timer if the user types again within 1 second
    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]); // Effect runs whenever `searchTerm` changes

  // Handle the search functionality with the debounced term
  useEffect(() => {
    if (!debouncedSearchTerm) {
      setFilterData(seniorListBySite); // Reset to full list if search term is empty
      return;
    }

    const filteredValues = seniorListBySite?.filter(({ firstName, lastName }) => {
      const fullName = `${firstName} ${lastName}`.toLowerCase();
      return fullName.includes(debouncedSearchTerm.toLowerCase());
    });

    setFilterData(filteredValues);
  }, [debouncedSearchTerm, seniorListBySite]); // Effect runs when `debouncedSearchTerm` changes

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  //New work End
  const AddDetail = (id) => {
    localStorage.setItem("MealId", id);
    navigate("/routesenior", { state: id })
  }
  const GetInitialDetails = async () => {
    // debugger
    setLoader(true)
    let routes = await GetRoutes();
    let routesData = routes.data;
    // let seniorsList = GetAllSeniors();
    // let seniorsListData = seniorsList.data;
    // setState({ ...state, routes: routesData, seniorsList: seniorsListData })
    setState({ ...state, routes: routesData })
    setLoader(false)

    // setCountLoader(true)
    await GetSeniorCount(routesData);
    let seniorsList = GetAllSeniors();
    let seniorsListData = seniorsList.data;
    setState({ ...state, routes: routesData, seniorsList: seniorsListData })
    setCountLoader(false)

  }
  useEffect(() => {
    // let Token = AuthService.getAuthDataByName("token") || "";
    // if (Token === "") {
    //   navigate("/");
    // }// eslint-disable-next-line
  }, [])
  const getSeniorsCount = (routeId) => {
    let count = 0;
    count = state.seniorsList?.filter((f) => f.RouteID === routeId)?.length;
    console.log(state.seniorsList, "<-- senior lists")
    return count;
  };
  // const getSeniorsCount = useCallback((routeId) => {
  //   return state.seniorsList?.filter((f) => f.RouteID === routeId)?.length || 0;
  // }, [state.seniorsList]);

  return (

    <Root p={2} loader={loader}>
      {/* Header */}
      <Box className="header-cont">
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="static">
            <Toolbar
              sx={{ width: "100%", maxWidth: "100%", padding: "0px 15px 0px 45px !important" }}>
              <Box className="headerNav">
                <Box
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2, display: "inline-flex", alignItems: "center" }}>

                  <img src={require('../../Assets/icons/msc_heart.png')} alt="Logo" style={{ marginRight: 10 }} />
                  <div>
                    <Typography color="black" className="pageMainTitle" id="pageTitle">
                      My Senior Center
                    </Typography>
                    <Typography color="black" className="headerText" id="pageTitle">
                      Meals
                    </Typography>
                  </div>

                </Box>
                <IconButton className="reloadBtn" onClick={() => getSeniorsList()} >
                  {loader ? <CircularProgress size={20} className='refreshingIcon' /> : <RefreshIcon size={20} />}
                </IconButton>
              </Box>
            </Toolbar>
          </AppBar>
        </Box>
      </Box>

      {/* search bar */}
      {!loader && filteredData?.length ? <Box mx={2} sx={{ zIndex: "0" }} height={50} >
        <TextField
          sx={{
            marginTop: "56px",
            color: 'red',
            zIndex: 2, fontSize: 16, fontWeight: 200,
            "& .MuiOutlinedInput-root": {
              color: "black !important",
              borderRadius: "40px",
              fontSize: "16px",
              fontWeight: "600",
              backgroundColor: "#fff",
              "& Mui-focused": {
                border: "1px solid grey",
                outline: "1px solid grey",
              },
              "& fieldSet": {

              },
              "& .MuiInputBase-input": {
                padding: "14px 0px"
              }
            },
          }}
          className='searchInput'
          InputProps={{
            startAdornment: <InputAdornment sx={{ cursor: 'pointer' }} position='start'> <SearchIcon sx={{ color: "#c4c4c4", fontSize: "30px", marginTop: "4px" }} /> </InputAdornment>
          }}
          placeholder='Search'
          onChange={handleSearchChange}
          value={searchTerm}
        />
      </Box> :
        ""}
      <Box className="mainContainer" ref={containerRef}>

        {loader ?
          <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ width: '100%', mr: 1, mt: 1 }}>
                <LinearProgress variant="determinate" value={progress} color='secondary' />
              </Box>

            </Box>
            <Box className="routeTextNull">
              <Box sx={{ minWidth: 35 }}>
                <Typography variant="subtitle1" sx={{ color: '#3aa7f7' }}>
                  {`${Math.round(progress)}%`}
                </Typography>
              </Box>
              <Typography variant='subtitle1'>
                Loading Seniors
              </Typography>
            </Box>
          </>
          :
          // (state?.routes?.length > 0) ? (
          //   state.routes.map((item, index) => (
          //     <Box key={index} sx={{ margin: "4px 10px", cursor: 'pointer' }} onClick={() => { AddDetail(item.RouteID) }}>
          //       <Box className="routeBox">
          //         <Box className="image">
          //           <img width={50} src={require('../../Assets/images/route-icon.png')} alt="heartpng" />
          //         </Box>
          //         <Box className="routeText">
          //           <Typography className='routetextColor' variant='h1'>{item.Name}</Typography>
          //           {
          //             countLoader ? <Box mt={0.6}><PulseLoader margin={5} size={8} color='grey' /></Box> :
          //               <Typography className='textColor' variant='h5'>{(getSeniorsCount(item?.RouteID) ? getSeniorsCount(item?.RouteID) : 0) + (getSeniorsCount(item?.RouteID) > 1 ? ' seniors' : ' senior')}</Typography>
          //           }
          //         </Box>
          //       </Box>
          //     </Box>
          //   ))
          // ) : (
          //   <Box className="routeTextNull">
          //     <Typography className='routetextColor' variant='h1'>No Routes Found</Typography>
          //   </Box>
          // )
          filteredData?.length ?
            filteredData?.slice(0, visibleSeniors).map((item, index) => (
              <Box key={index} sx={{ margin: "4px 10px", cursor: 'pointer' }} onClick={() => { openDetail(item.seniorID) }}>
                <Box className="routeBox">
                  <Box className="image">
                    <img width={"100%"} src={item.photoURL} alt="senior" />
                  </Box>
                  <Box className="routeText">
                    <Typography className='routetextColor' variant='h1'>{item.firstName} {item.lastName}</Typography>
                    <Typography className='textColor' variant='h5'>{item.city}</Typography>
                    {/* {
                    countLoader ? <Box mt={0.6}><PulseLoader margin={5} size={8} color='grey' /></Box> :
                      <Typography className='textColor' variant='h5'>{(getSeniorsCount(item?.RouteID) ? getSeniorsCount(item?.RouteID) : 0) + (getSeniorsCount(item?.RouteID) > 1 ? ' seniors' : ' senior')}</Typography>
                  } */}
                  </Box>
                </Box>
              </Box>
            )) :
            <Box className="routeTextNull">
              <Typography className='routetextColor' variant='h1'>
                {/* <CircularProgress sx={{ color: "black" }} /> */}
                No Seniors Found
              </Typography>
            </Box>
        }



      </Box>
    </Root>

  )
}