import { GetRequest, PostRequest } from "./Apibase";
import { SyncApplicationWithServer } from "./RidesServices";

// Basic Reload
var isOnline = window.navigator.onLine
isOnline = true
window.addEventListener('online', async () => {
    isOnline = true;
    await SyncApplicationWithServer();
    window.location.reload();
});
window.addEventListener('offline', () => {
    isOnline = false
});

let GetWorkRequests = async (endDate, status) => {
    if (isOnline) {
        let response = await GetRequest(`/api/mobile/GetWorkRequests?endDate=${endDate}&weeks=36&status=${status ? 'accepted' : "open"}`, true, false, true);
        return response;
    } else {
        console.error(`Connection Broken! can't post the request to SendMessages endpoint`)
    }
}
let AssignWorkRequests = async (data) => {
    if (isOnline) {
        let response = await PostRequest(`/api/mobile/AssignWorkRequests`, data, true, false, true);
        return response;
    } else {
        console.error(`Connection Broken! can't post the request to SendMessages endpoint`)
    }
}

export { GetWorkRequests, AssignWorkRequests }
