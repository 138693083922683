import React, { useEffect } from 'react';
import { AppBar, Box, Divider, IconButton, Toolbar, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AuthService from '../../Services/AuthService';

const Root = styled(Box)(({ theme }) => ({
    height: '100%',
    padding: 0,
    '& .header-cont': {
        // width: "100%",
        width: "device-width",
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1,
        "& header": {
            display: "flex",
            height: 48,
            background: "white",
            fontSize: 12,
            alignItems: "center",
            '& img[alt="Logo"]': {
                width: 34,
                filter: 'invert(1)',
            },
            "& .headerNav": {
                // marginRight: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                "& .fuel": {
                    color: "red",
                    position: "absolute",
                    width: "26px",
                    right: "62px",
                    top: "12px",
                    cursor: "pointer"
                },
                "& .pageMainTitle": {
                    fontSize: 15,
                    fontWeight: 600,
                    whiteSpace: "nowrap"
                },
                "& .headerText": {
                    display: "flex",
                    color: '#1f0757',
                    fontSize: 13,
                    textShadow: '#1f0757',
                    fontWeight: 600,
                    whiteSpace: "nowrap"
                }
            }
        },
    },
    '& .mainContainer': {
        backgroundImage: `url(${require('../../Assets/loginBackgroundImg/img1.png')})`,
        backgroundSize: "cover",
        overflow: "hidden",
        backgroundPosition: "center",
        height: "calc(100vh - 48px)",
        marginTop: 48,
        overflowY: "auto",
        "& .routeBox": {
            margin: "10px auto ",
            display: "flex",
            width: "100",
            minWidth: 310,
            gap: 10,
            padding: "5px",
            alignItems: "center",
            "& .image": {
                border: "1px solid grey",
                borderRadius: "50%",
                height: 50,
                width: 50,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: theme.palette.primary.contrastText,
            },
            "& .routeText": {
                display: "flex",
                flexDirection: "column",
                gap: 3
            },
            "& .routetextColor": {
                fontSize: 20,
                fontWeight: 400,
                color: theme.palette.button.main
            },
            "& .textColor": {
                fontSize: 14,
                fontWeight: 400,
                color: theme.palette.other.darkgray,
                textShadow: "0 0 gray"
            }
        },
        "& .checkIconBox": {
            padding: "0 6px",
            margin: "0px auto ",
            display: "flex",
            width: "100",
            minWidth: 310,
            backgroundColor: theme.palette.primary.contrastText,
            boxShadow: "0px 3px 15px -6px rgb(0 0 0 / 50%)",
            borderRadius: 10,
            alignItems: "center",
            justifyContent: "space-between"
        },
        "& .buttons": {
            margin: "8px 10px",
            display: 'flex',
            justifyContent: "space-between",
            gap: "10px",

            "& .btnImg": {
                border: "1px solid lightGray",
                backgroundColor: "white",
                width: 140,
                alignItems: "center",
                display: 'flex',
                flexDirection: "column",
                justifyContent: 'center',
                borderRadius: 8,
                padding: "6px 5px",
                flex: 1
            }
        }
    },
    '& img[alt="heartpng"]': {
        width: 50,
    },
}));
export default function Bingo() {
    let navigate = useNavigate();
    useEffect(() => {
        let Token = AuthService.getAuthDataByName("token") || "";
        if (Token === "") {
            navigate("/");
        }
    }, [navigate])
    const HandleBack = () => {
        navigate(-1);
    }
    const HandleScan = () => {
        navigate("/scanner")
    }
    return (
        <Root p={2} >
            {/* Header */}
            <Box className="header-cont">
                <Box sx={{ flexGrow: 1 }}>
                    <AppBar position="static">
                        <Toolbar
                            sx={{ width: "100%", maxWidth: "100%", padding: "0px 15px 0px 45px !important" }}>
                            <Box className="headerNav">
                                <Box
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    aria-label="menu"
                                    sx={{ mr: 2, display: "inline-flex", alignItems: "center" }}>

                                    <img src={require('../../Assets/icons/msc_heart.png')} alt="Logo" style={{ marginRight: 10 }} />
                                    <div>
                                        <Typography color="black" className="pageMainTitle" id="pageTitle">
                                            My Senior Center
                                        </Typography>
                                        <Typography color="black" className="headerText" id="pageTitle">
                                            Activities
                                        </Typography>
                                    </div>
                                </Box>

                            </Box>
                        </Toolbar>
                    </AppBar>
                </Box>
            </Box>
            <Box className="mainContainer">
                <Box sx={{ margin: "4px 10px", cursor: 'pointer' }} >
                    <Box className="routeBox " >
                        <Box className="image"> <img style={{ width: "30px" }} src={require('../../Assets/images/activities-icon.png')} alt="heartpng" /> </Box>
                        <Box className="routeText">
                            <Typography className='routetextColor' variant='h1'>Bingo</Typography>
                            <Typography className='textColor' variant='h5'>3:05 AM - 5:00 AM</Typography>
                        </Box>
                    </Box>
                </Box>
                <Divider />
                <Box sx={{ margin: "8px 10px", cursor: 'pointer' }} >
                    <Box className="checkIconBox">
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <IconButton sx={{ color: 'green' }}> <CheckCircleIcon /> </IconButton>
                            <Typography variant='subtitle2'>Benjamin New Year</Typography>
                        </Box>
                        <Typography variant='body3'>12:47 PM</Typography>
                    </Box>
                </Box>
                <Box className="buttons">
                    <Box className="btnImg" onClick={HandleBack}>
                        <img src={require('../../Assets/images/back-icon.png')} alt="heartpng" />
                        <Typography variant='subtitle2'>Back</Typography>
                    </Box>
                    <Box className="btnImg" onClick={HandleScan}>
                        <img src={require('../../Assets/images/scan-icon.png')} alt="heartpng" />
                        <Typography variant='subtitle2'>Scan</Typography>
                    </Box>
                </Box>
            </Box>
        </Root>
    )
}