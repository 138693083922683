import * as React from 'react';
import { Global } from '@emotion/react';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { grey } from '@mui/material/colors';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
import CircularProgress from '@mui/material/CircularProgress';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus'
import { GetLiveMatrix, SaveRideStep, SendMessages } from '../Services/Api/RidesServices';
import { DialogContent, Divider, Drawer, IconButton } from '@mui/material';
import AuthService from '../Services/AuthService';
import moment from 'moment';
import NotificationsIcon from '@mui/icons-material/Notifications';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from "@mui/material/Dialog";
import TimerSharpIcon from '@mui/icons-material/TimerSharp';
import NavigationIcon from '@mui/icons-material/Navigation';
import { useAppContext } from '../Shell/AppContext';

const Root = styled('div')(({ theme }) => ({
    height: '100%',
    backgroundColor:
        theme.palette.mode === 'light' ? grey[100] : theme.palette.background.default,
}));
const StyledBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
    "&.stylebxdrawer": {
        boxShadow: "-1px -14px 15px 0px #0000004a",
        "& .busIconButton ": {
            width: "50px", height: "50px", borderRadius: "50%", justifyContent: "center", alignItems: "center"
        },
        "& .busgif ": {
            display: "flex",
            overflow: "hidden",
            width: "40px", height: "40px", borderRadius: "50%", justifyContent: "center", alignItems: "center", backgroundColor: "white",
            "& .statusicon": {
                marginTop: "-6px"
            }
        }
    },

    '& .MuiTypography-subtitle1': {
        [theme.breakpoints.up('sm')]: {
            fontSize: 28
        },
    },
    '& .MuiTypography-caption': {
        [theme.breakpoints.up('sm')]: {
            fontSize: 24
        },
    },
    "& .location-info-lastspan": {
        flex: " 0 0 25%",
        maxWidth: "25%",
        paddinRight: 10,
        textAlign: "right",
    },
    '& .infoCardShow': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundColor: "white",
        padding: "0px 5px 5px 5px",
        height: "100%",
        "& .disabledBtn": {
            fontSize: 35,
            padding: "0px 70px",
            backgroundColor: theme.palette.button.light,
            cursor: "pointer",
            color: " white",
            lineHeight: 1.4,
            [theme.breakpoints.up('sm')]: {
                marginBottom: "10px",
            },
            "& .MuiButton-startIcon": {
                marginRight: 5,
                '& svg': {
                    fontSize: 30
                }
            }
        },
        "& .completeBtn": {
            fontSize: 35,
            padding: "0px 70px",
            backgroundColor: theme.palette.button.main,
            cursor: "pointer",
            color: "white",
            lineHeight: 1.4,
            [theme.breakpoints.up('sm')]: {
                marginBottom: "10px",
            },
            "&:hover": {
                fontSize: 35,
                backgroundColor: theme.palette.button.main,
                cursor: "pointer",
            },
            "& .MuiButton-startIcon": {
                marginRight: 5,
                '& svg': {
                    fontSize: 30
                }
            }
        },
        "& .btn": {
            display: "flex",
            justifyContent: "center",
            marginBottom: "12px"
        },

        "& .WaitingTimeCounter": {
            textAlign: "center",
            fontWeight: 600,
            fontSize: 25,
            display: "block",
        },
    },
    "& .css-1sn2e8b-MuiButtonBase-root-MuiIconButton-root:hover": {
        backgroundColor: theme.palette.primary.main
    },
    "& .Wrapper": {
        display: "flex",
        justifyContent: "space-between",
        flex: "0 0 20%",
        "& .timeText": {
            color: "black",
            textShadow: "0 0 black",
            // width: "75px",
            [theme.breakpoints.up('sm')]: {
                // width: "135px",
            },
        },
        "& .homeIcon": {
            padding: "5px 12px 0px 0px",
            [theme.breakpoints.up('sm')]: {
                padding: "7px 12px 0px 0px",
            },
            "& img": {
                width: "40px",
                [theme.breakpoints.up('sm')]: {
                    width: "60px"
                },
            }
        },
        "& .NotifyIcon": {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.background.white,
            textAlign: "center",
            marginLeft: "auto",
            width: 48,
            height: 48,
            borderRadius: "5px",
            cursor: "pointer",
            [theme.breakpoints.up('sm')]: {
                width: 60,
                height: 60,
            },
            "& svg": {
                fontSize: "2.5rem",
                jstifyContent: "end",
                [theme.breakpoints.up('sm')]: {
                    fontSize: "50px",
                },
            }
        },
        "& .NavigationIcon": {
            backgroundColor: theme.palette.button.main,
            color: theme.palette.background.white,
            textAlign: "left",
            marginLeft: "auto",
            width: 48,
            height: 48,
            borderRadius: "5px",
            cursor: "pointer",
            [theme.breakpoints.up('sm')]: {
                width: 60,
                height: 60,
            },
            "& img": {
                filter: theme.palette.type === 'light' ? "invert(1)" : 'invert(0)',
                width: "44px",
                [theme.breakpoints.up('sm')]: {
                    width: "55px"
                },
            }
        }
    },
    "& .Wrapper3": {
        display: "flex",
        alignItems: "baseline",
        color: "black",
        "& .feeInDollar": {
            color: "#000 ",
            fontSize: 24,
            lineHeight: "21px",
            [theme.breakpoints.up('sm')]: {
                fontSize: 36,
                lineHeight: "34px",
            },
        },
        "& .editIcon": {
            width: 38,
            height: 38,
            backgroundColor: theme.palette.secondary.dark,
            borderRadius: "5px",
            color: theme.palette.background.white,
            alignSelf: "flex-start",
            marginTop: "14px",
            marginRight: "11px",
            [theme.breakpoints.up('sm')]: {
                width: 55,
                height: 57,
                marginTop: "19px",
                '& svg': {
                    fontSize: 32
                }
            },
        },
        "& .wrapperNotes": {
            paddingTop: "8px",
            lineHeight: 2.4,
            maxHeight: "95px",
            overflowY: "auto",
            minHeight: '90px',
            marginLeft: "11px",
            [theme.breakpoints.down('md')]: {
                maxHeight: "100px",
            },
            [theme.breakpoints.down('sm')]: {
                maxHeight: "60px",
            },
            [theme.breakpoints.up('md')]: {
                maxHeight: "130px",
            },
            "& .NotesData": {
                fontSize: 16,
                color: "black",
                textShadow: "0 0 black",
                lineHeight: "22px",
                display: "block",
                [theme.breakpoints.up('sm')]: {
                    fontSize: 24,
                    lineHeight: "36px",
                },
            }
        }
    },
    "& .seniorInfoContact": {
        overflowY: 'auto',

        "& a:hover": {
            textDecoration: "none"
        }
    },
    "& .logoImg": {
        backgroundImage: `url(${require('../Assets/core-img/center.png')})`,
        backgroundSize: "cover",
        overflow: "hidden",
        backgroundPosition: "center",
        height: 40,
        width: 40,
        borderRadius: "50%",
        [theme.breakpoints.up('sm')]: {
            height: 70,
            width: 70,
        },
    },
    "& .subheading": {
        color: theme.palette.background.dark
    },
    "& .subheading2": {
        color: theme.palette.background.dark
    },
    "& .subheading1": {
        color: theme.palette.background.dark
    },

}));
const DialogBox = styled(Dialog)(({ theme }) => ({
    "& .dialogBtn": {
        width: "240px",
        borderRadius: "6px",
        padding: "3px 14px",
        background: theme.palette.button.main,
        borderColor: theme.palette.button.main,
        fontSize: 18,
        fontWeight: 600,
        textAlign: "center",
        "&:hover": {
            borderColor: "rgba(31, 7, 87, -1.96)",
            color: theme.palette.button.main,
        },
    },
    "& .dialogBtnClose": {
        marginTop: "8px",
        textAlign: "center",
        "& .closeDialog": {
            border: "none",
            backgroundColor: "transparent",
            color: theme.palette.sidebarLink.main,
            textDecoration: "underline",
            cursor: "pointer",
            fontSize: "16px",
            "&:hover": {
                textDecoration: "none"
            },
        }
    },
    "& .titleBoxDialogTwo": {
        display: "flex",
        justifyContent: "start",
        padding: "4px 8px",
    },
    "& .disabledBtnNotifi": {
        width: "280px",
        height: "60px",
        borderRadius: "6px",
        padding: "3px 14px",
        background: "#CCCCCC",
        borderColor: "#CCCCCC",
        fontSize: 18,
        fontWeight: 600,
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        "&:hover": {
            borderColor: "rgba(31, 7, 87, -1.96)",
            color: theme.palette.button.main,
        },
    },
    "& .messagedialogBtn": {
        minWidth: "250px",
        maxWidth: "285px",
        height: "60px",
        borderRadius: "6px",
        padding: "3px 14px",
        background: theme.palette.button.main,
        borderColor: theme.palette.button.main,
        fontSize: 18,
        fontWeight: 600,
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        "&:hover": {
            borderColor: "rgba(31, 7, 87, -1.96)",
            backgroundColor: "rgb(76 175 80)",
            color: theme.palette.button.main,
        },
        "& .loader": {
            position: "absolute"
        }
    },
}));
function BottomTray(props) {
    const { rides, routes, responceRide, stopStatus, handlestateFromChild, dataLoader, timewaiting, } = props;

    const [state, setState] = React.useState({
        bottomDrawer: props.bottomDrawer || false,
        routesData: routes,
        ridesData: rides,
    })
    const [openDialogTwo, setOpenDialogTwo] = React.useState(false);
    const [openMessageDialog, setOpenMessageDialog] = React.useState(false);
    const [waitingTime, setWaitingTime] = React.useState(null);
    const [loader, setLoader] = React.useState(true);
    const [disabled, setDisabled] = React.useState(false);
    const [countTime, setCountTime] = React.useState();
    const [sec, setSec] = React.useState(0);
    const [min, setMin] = React.useState(0);
    const [hr, setHr] = React.useState(0);
    const [showWaitTime, setShowWaitTime] = React.useState(false)
    const [displayTime, setDisplayTime] = React.useState(false)
    React.useEffect(() => {
        setCountTime(localStorage.getItem("time"))

    }, [routes.stopID])
    React.useEffect(() => {
        let timeCount = localStorage.getItem("time")
        const time = new Date();
        let waittime = secondsToTime(timeCount)
        setCountTime(waittime)
        time.setSeconds(time.getSeconds() + parseInt(timeCount));
        if (showWaitTime) {
            initializeClock(time);
        }

        setState({ ...state, routesData: routes })
        return () => {
            clearInterval(timeinterval);
        };

    }, [localStorage.getItem("time"), showWaitTime])
    // secondToTime
    function secondsToTime(seconds) {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;

        return `${hours}:${minutes}:${remainingSeconds}`;
    }
    //Count Down Timer
    let timeinterval;
    function getTimeRemaining(endtime) {
        var t = Date.parse(endtime) - Date.parse(new Date());
        var seconds = Math.floor((t / 1000) % 60);
        var minutes = Math.floor((t / 1000 / 60) % 60);
        var hours = Math.floor((t / (1000 * 60 * 60)) % 24);
        var days = Math.floor(t / (1000 * 60 * 60 * 24));
        return {
            'total': t,
            'days': days,
            'hours': hours,
            'minutes': minutes,
            'seconds': seconds
        };
    }
    function initializeClock(endtime) {
        clearInterval(timeinterval);
        function updateClock() {
            var t = getTimeRemaining(endtime);
            setSec(t.seconds < 10 ? "0" + t.seconds : t.seconds);
            setMin(t.minutes < 10 ? "0" + t.minutes : t.minutes);
            setHr(t.hours < 10 ? "0" + t.hours : t.hours);
            if (t.total <= 0) {
                clearInterval(timeinterval);
            }
        }

        updateClock();
        timeinterval = setInterval(updateClock, 1000);

    }
    React.useEffect(() => {
        setState({ ...state, bottomDrawer: props.bottomDrawer })
    }, [props.bottomDrawer])

    React.useEffect(() => {
        setState({ ...state, ridesData: rides })
    }, [rides])
    setTimeout(() => {
        setState({ ...state, ridesData: rides })
    }, 500)
    React.useEffect(() => {
        setState({ ...state, routesData: routes })
    }, [routes])
    //Complete Button Name
    const getButtonName = () => {
        if (routes && routes.stopStatus === "notarrived") {
            return "Depart";
        } else if (routes && routes.stopStatus === "departed") { //arrived
            return "Arrive";
        } else if (routes && routes.stopStatus === "arrived") { //departed
            return "Depart";
        } else if (routes && routes.stopStatus === "completed") {
            return "Depart";
        } else {
            return "Arrived";
        }
    }
    //Edit Button Name
    const getEditButtonName = () => {
        return routes?.stopStatus === "arrived" ? "Arrived" : routes?.stopStatus === "departed" ? "Departed" : routes?.stopStatus === "completed" ? "Arrived" : "Departed"
    }
    // handle edit button icon
    const handleEditButtonIcon = () => {
        if (routes && (routes.stopStatus === "arrived" || routes.stopStatus === "departed" || routes.stopStatus === "completed")) {
            return <IconButton className='editIcon' onClick={() => handleDialog()}><EditIcon /></IconButton>;
        }
        else {
            return "";
        }
    }
    //Disable Complete Button
    const disableOnComplete = () => {
        return routes.stopStatus === "completed" || routes.stopStatus === "arrived"
        // return routes.stopStatus === "completed"
    }
    // FakeLoader
    const fakeLoader = (timeout = 1000) => {
        handlestateFromChild("setResponceRide", true)
        setTimeout(() => {
            handlestateFromChild("setResponceRide", false)
        }, timeout);
    }
    // handle  Notification
    const HandleNotify = (message) => {
        var notification;
        if (message === "Onmyway") {
            notification = "Your ride has arrived and is waiting."
            sendNotification(notification)

        } else if (message === "waiting") {
            notification = "Your ride will arrive momentarily please be ready."
            sendNotification(notification)
        } else {
            notification = `Your ride will be arriving in ${waitingTime}`
            sendNotification(notification)
        }
    }
    //  current latitude and longitude
    async function getLocation() {
        return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject);
        });
    }
    // waitingTime
    async function getliveMatrixTime() {
        let gsid = AuthService.getAuthDataByName("gsid") || "";
        try {
            const position = await getLocation();
            const { latitude, longitude } = position.coords;
            let PlaceSID = routes?.locPlaceSID;
            let liveMatrixDTO = {
                OriginLat: latitude,
                OriginLng: longitude,
                DestPlaceSID: PlaceSID,
                DestLat: routes.locationInfo.lat,
                DestLng: routes.locationInfo.lng,
            }
            await GetLiveMatrix(liveMatrixDTO, gsid)
                .then(responce => {
                    console.log(responce);
                    if (responce.data !== null) {
                        let time = secondsToMinutes(responce.data.seconds);
                        // let time = secondsToMinutes(160);
                        console.log(time);
                        setWaitingTime(time)
                        setLoader(false)
                    } else {
                        // setWaitingTime(0 + " " + "minutes" + " " + 0 + " " + "seconds");
                        setWaitingTime('0 minutes 0 seconds');
                        setDisabled(true);
                        setLoader(false)
                    }

                }).catch(error => {
                    setLoader(false)
                    console.log(error)
                })
        } catch (error) {

            console.error('Failed to get location:', error);
            setLoader(false)
            setOpenMessageDialog(false);
        }
    }
    //send Notification
    const sendNotification = async (notification) => {
        setOpenMessageDialog(false);
        setDisabled(false)
        fakeLoader(1000)
        let gsid = AuthService.getAuthDataByName("gsid") || "";
        let userID = AuthService.getAuthDataByName("userID") || "";
        let IdofSeniorON = routes.requestsOn.map(x => x.seniorID)
        let IdofSeniorOF = routes.requestsOff.map(x => x.seniorID)
        let DateTime = new Date().toUTCString();
        var idOfSenior;
        if (IdofSeniorON.length) {
            idOfSenior = IdofSeniorON;
        } else {
            idOfSenior = IdofSeniorOF
        }
        let data = {
            SeniorIDs: idOfSenior,
            UserID: userID,
            BroadcastMethod: "text",
            Content: notification,
            DateTime: DateTime,
        }
        await SendMessages(data, gsid)
            .then(responce => {
                console.log(responce.data)

            }).catch(error => {
                console.log(error)
            })
    }
    // formate Time
    const timeFormate = (time) => {
        var localtime = new Date(time);
        return localtime.toLocaleTimeString([], { hour: 'numeric', minute: 'numeric', hour12: true }).toUpperCase();
    }
    // Confrim alert
    const handleConfrim = (StopID, routID, from) => {
        handleComplete(StopID, routID, from);
        setOpenDialogTwo(false);
    }
    //navigation on map
    const handleNavigation = () => {
        // open google map
        handleGetGoogleDirectionClick()
        // window.open("comgooglemaps://?saddr=24.925291,67.102737&daddr=24.9361,67.1144");
    }
    const handleGetGoogleDirectionClick = () => {
        navigator.geolocation.getCurrentPosition(openMap);
    };
    const openMap = (position) => {
        const userLat = position.coords.latitude;
        const userLng = position.coords.longitude;
        const url = `comgooglemaps://?saddr=${userLat},${userLng}&daddr=${routes?.locationInfo.lat},${routes?.locationInfo.lng}`;
        window.location.href = url;

    };
    // Complete Button 
    const handleComplete = async (StopID, routID, from) => {
        // others
        let currentWaitingIds = JSON.parse(localStorage?.getItem("currentWaitingIds") || "[]");
        let currentWaitings = JSON.parse(localStorage?.getItem("currentWaitings") || "[]");
        let gsid = AuthService.getAuthDataByName("gsid");
        let DateTimeUTC = moment.utc();
        let dataList = state.ridesData?.data.filter(item => item.stopID === StopID)[0];
        let dataIndex = state.ridesData?.data.findIndex(item => item.stopID === StopID);
        let data = {
            stopID: StopID,
            routeID: routID,
            stopStatus: stopStatus,
            DateTimeUTC
        }
        if (dataIndex >= 0) {
            handlestateFromChild("setStopStatus", dataList.stopStatus)
            if (from !== "fromanchor") {
                if (dataList.stopStatus === "notarrived") {

                    data.stopStatus = "departed"; //arrived
                    state.ridesData.data[dataIndex].stopStatus = "departed"; //arrived

                    if ((new Date(routes.departureTime).getTime() - new Date(routes.arrivalTime).getTime()) / 1000 > 120) {
                        setShowWaitTime(true);
                        setDisplayTime(true);
                        // Start Timer
                        currentWaitingIds.push(routes.routeID);
                        localStorage.setItem("currentWaitingIds", JSON.stringify(currentWaitingIds));
                        props.startTimer();
                    }
                }
                else if (dataList.stopStatus === "departed") { //arrived

                    data.stopStatus = "arrived"; //departed
                    state.ridesData.data[dataIndex].stopStatus = "arrived"; //departed
                    let rideslength = state.ridesData.data.length - 1;
                    let CompleteRides = rides.data.filter(item => item.stopStatus !== "arrived") //departed
                    handlestateFromChild("setCompletedRides", CompleteRides)
                    let CompleteRidesLength = rides.data.filter(item => item.stopStatus === "arrived") //departed
                    handlestateFromChild("setCompletedRidesCount", CompleteRidesLength.length)
                    handlestateFromChild("setCompleteButtonClicked", true);

                    // Clear Timer
                    let newTimerIds = currentWaitingIds.filter(x => x !== routes.routeID);
                    localStorage.setItem("currentWaitingIds", JSON.stringify(newTimerIds));

                    let newTimers = currentWaitings.filter(x => x.routeID !== routes.routeID);
                    localStorage.setItem("currentWaitings", JSON.stringify(newTimers));

                    if (dataIndex === rideslength) {
                        handlestateFromChild("setFilterId", state.ridesData.data[0].stopID)
                    } else {
                        handlestateFromChild("setFilterId", state.ridesData.data[dataIndex + 1].stopID)
                    }
                    if (CompleteRides.length === 0) {
                        handlestateFromChild("setBottomDrawer", false)
                    }

                }
                else if (dataList.stopStatus === "arrived") { //departed
                    data.stopStatus = "completed";
                    state.ridesData.data[dataIndex].stopStatus = "completed";

                }
                // else if (dataList.stopStatus === "completed") {
                //     data.stopStatus = "completed";
                //     state.ridesData.data[dataIndex].stopStatus = "completed";
                // }
                else if (dataList.stopStatus === "undelivered") {
                    data.stopStatus = "departed"; //arrived
                    state.ridesData.data[dataIndex].stopStatus = "departed"; //arrived
                }
                else {
                    return
                }
            } else {
                if (dataList.stopStatus === "arrived") { //departed
                    data.stopStatus = "departed"; //arrived
                    state.ridesData.data[dataIndex].stopStatus = "departed"; //arrived
                    let CompleteRides = rides.data.filter(item => item.stopStatus !== "arrived") //departed
                    handlestateFromChild("setCompletedRides", CompleteRides)
                    let CompleteRidesLength = rides.data.filter(item => item.stopStatus === "arrived") //departed
                    handlestateFromChild("setCompletedRidesCount", CompleteRidesLength.length);
                    handlestateFromChild("setCompleteButtonClicked", true)
                    // Clear Timer
                    let newTimerIds = currentWaitingIds.filter(x => x !== routes.routeID);
                    localStorage.setItem("currentWaitingIds", JSON.stringify(newTimerIds));

                    let newTimers = currentWaitings.filter(x => x.routeID !== routes.routeID);
                    localStorage.setItem("currentWaitings", JSON.stringify(newTimers));
                } else if (dataList.stopStatus === "completed") {
                    data.stopStatus = "arrived"; // departed
                    state.ridesData.data[dataIndex].stopStatus = "arrived"; //departed

                } else if (dataList.stopStatus === "departed") { //arrived

                    data.stopStatus = "notarrived";
                    state.ridesData.data[dataIndex].stopStatus = "notarrived";

                    // Clear Timer
                    let newTimerIds = currentWaitingIds.filter(x => x !== routes.routeID);
                    localStorage.setItem("currentWaitingIds", JSON.stringify(newTimerIds));

                    let newTimers = currentWaitings.filter(x => x.routeID !== routes.routeID);
                    localStorage.setItem("currentWaitings", JSON.stringify(newTimers));

                    props.startTimer();
                }
            }
            handlestateFromChild("setRides", rides.data);
        }
        let responce = SaveRideStep(data, gsid);
        handlestateFromChild("setResponceRide", responce.success)
        fakeLoader(1000)

    }
    //Notes
    const handleNotes = () => {
        if (Object.keys(routes).length) {
            if (routes?.requestsOn.length > 0) {
                if (routes.requestsOn[0].notes !== "") {
                    return (routes.requestsOn[0].notes)
                } else {
                    return "Not Mentioned"
                }

            } else if (routes?.requestsOff.length > 0) {
                if (routes.requestsOff[0].notes !== "") {
                    return (routes.requestsOff[0].notes);
                } else {
                    return "Not Mentioned"
                }
            } else {
                return "Not Mentioned"
            }
        } else {
            return "Not Mentioned"
        }
    }
    // Dialog
    const handleDialog = () => {
        setOpenDialogTwo(true);
    }
    const handleClose = () => {
        setOpenDialogTwo(false);
    };
    const handleCloseMessageDialog = () => {
        setDisabled(false)
        setLoader(true)
        setOpenMessageDialog(false);
    };
    const handleMessageDialog = () => {
        setLoader(true)
        getliveMatrixTime()
        setOpenMessageDialog(true);

    };
    // Icons
    const handleIcons = () => {
        if (Object.keys(routes).length) {
            if (responceRide) {
                return <CircularProgress size="1.5rem" />;
            } else if (routes.stopStatus === "notarrived") {
                return <DirectionsBusIcon />;
            } else if (routes.stopStatus === "arrived") {
                return <DirectionsBusIcon />;
            } else if (routes.stopStatus === "departed") {
                return <CheckIcon />;
            } else {
                return <CheckIcon color='success' />;
            }
        }
    }
    // Location
    const handleLocations = () => {
        if (routes?.locationID) {
            return `${routes?.locationInfo?.address} ${routes?.locationInfo?.city} ${routes?.locationInfo?.state} ${routes?.locationInfo?.zip} `;
        }
    }
    //Address
    const handleAddress = () => {
        if (routes?.locationID) {
            return `${routes?.locationInfo?.address} ${routes?.locationInfo?.city} `;
        }
    }
    //Phone Number when tile are not select by user
    const handlePhoneNumber = () => {
        if (routes?.requestsOn.length) {
            return routes?.requestsOn[0].seniorInfo.homePhone;
        } else {
            return "508-411-1111"
        }
    }
    //Name when tile are not select by user
    const handleName = () => {
        if (routes?.requestsOn.length) {
            return routes?.requestsOn[0].seniorInfo.firstName;
        } else {
            return "Stan"
        }
    }
    const handleCompleteColor = () => {
        if (routes?.stopStatus === "completed") {
            return "#2e7d32";
        } else {
            return "white"
        }
    }
    //handle Senior Name
    const handleSeniorName = () => {
        return routes.locationInfo.name;
    }
    // change seconds to minutes
    function secondsToMinutes(seconds) {
        var minutes = Math.floor(seconds / 60);
        var remainingSeconds = seconds % 60;
        return minutes + " minutes " + remainingSeconds + " seconds";
    }
    //get Icon
    const getStatusIcons = () => {
        if (timewaiting) {
            return <img className='statusicon' src={require("../Assets/images/buswatch.gif")} width={"30px"} height={"30px"} alt='watch' />
        } else {
            if (routes.stopStatus === 'arrived') {
                return <img className='statusicon' src={require("../Assets/images/movingCar.gif")} width={"30px"} height={"30px"} alt='watch' />
            } else if (routes.stopStatus === 'notarrived') {
                return <img className='statusicon' src={require("../Assets/images/parkingicon.png")} width={"30px"} height={"30px"} alt='watch' />
            } else {
                return <img className='statusicon' src={require("../Assets/images/buscheck.png")} width={"30px"} height={"30px"} alt='watch' />
            }
        }
    }
    return (
        <Root sx={{ height: 0 }}>
            <CssBaseline />
            <Global
                styles={{
                    ".MuiDrawer-root": {
                        position: "inherit !important"
                    },
                    '.MuiDrawer-root > .MuiPaper-root': {
                        height: dataLoader ? "40%" : state.ridesData.data ? "40%" : "0%",
                        overflow: 'visible',
                    },
                }}
            />
            <Drawer
                anchor="bottom"
                open={state.bottomDrawer}
                // onClose={() => toggleDrawer(false)}
                // onOpen={() => toggleDrawer(true)}
                // swipeAreaWidth={0}
                // disableSwipeToOpen={true}
                ModalProps={{
                    keepMounted: true,
                }}
            >
                <StyledBox className='stylebxdrawer'
                    sx={{ height: "46px", position: 'absolute', top: state.ridesData.data ? -46 : 30, borderTopLeftRadius: 8, borderTopRightRadius: 8, visibility: 'visible', right: 0, left: 0, backgroundColor: dataLoader ? "fff3cd" : !timewaiting ? state.ridesData.data ? "#fff" : "#fff3cd" : state.ridesData.data ? "#eddda4" : "#fff3cd", }}
                >
                    <Box sx={{ display: "flex", justifyContent: "space-between", padding: '10px' }}>
                        {timewaiting ? <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
                            <TimerSharpIcon />
                            <Typography sx={{ fontSize: '16px', fontWeight: 500 }} variant='span'>Wait.</Typography>
                            <Typography sx={{ fontSize: '16px', fontWeight: 500 }} variant='span'>{timeFormate(routes?.departureTime)}</Typography>
                        </Box> : ""}
                        {timewaiting ? displayTime ? <Box className="WaitingTimeCounter">
                            <div style={{ fontSize: "25px", fontWeight: "bold" }}>
                                <span>{hr}</span>:<span>{min}</span>:
                                <span>{sec}</span>
                            </div>
                        </Box> : <div style={{ fontSize: "25px", fontWeight: "bold" }}>
                            <span>{countTime}</span>
                        </div> : ""}
                        <Box className='busIconButton' sx={{ display: (!state.bottomDrawer) ? "none" : "flex", backgroundColor: "#82d8bb", position: "absolute", left: 'calc(50% - 25px)', top: "-22px" }}>

                            <Box className="busgif" sx={{}}>
                                {getStatusIcons()}
                            </Box>
                            {/* <img className='iii' src={require("../Assets/icons/busicons.png")} width={"30px"} height={"30px"} alt='watch' /> */}
                        </Box>
                    </Box>
                </StyledBox>
                <StyledBox sx={{ px: 0.5, pb: 0.5, height: '100%', backgroundColor: dataLoader ? "fff3cd" : state.ridesData.data ? "white" : "#fff3cd", }} >
                    {!dataLoader ?
                        state.ridesData.data ?
                            <Box className="infoCardShow mt-5" >
                                <Box sx={{ overflow: 'auto' }}>
                                    <Box className="Wrapper" sx={{ marginBottom: "4px", alignItems: "center" }}>
                                        <Box>
                                            <Box sx={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                                                <Box className="homeIcon"> <img src={require("../Assets/icons/home.png")} alt="homepng" width={"32px"} /> </Box>
                                                <Typography className='subheading1' variant='subtitle2'>{handleAddress()}</Typography>
                                            </Box>
                                            <Typography sx={{ lineHeight: "150%" }} variant='subtitle1'> {handleSeniorName()}</Typography>
                                            <Box sx={{ textAlign: "left", lineHeight: "150%" }} className="Wrapper">
                                                <Typography className='subheading1' variant="caption">{handleLocations()}</Typography>
                                            </Box>
                                        </Box>


                                        <Box sx={{ textAlign: " right", display: "flex", flexDirection: "column" }}>
                                            <Typography variant="subtitle1" className='timeText'>{timeFormate(routes?.arrivalTime)}</Typography>
                                            <Box sx={{ display: "flex", gap: "10px" }}>
                                                <Box className="NavigationIcon" onClick={() => handleNavigation()}> <img src={require("../Assets/icons/navigation-icon.png")} alt='navigation' width="44px" /></Box>
                                                <Box className="NotifyIcon" onClick={() => handleMessageDialog()}> <NotificationsIcon /> </Box>

                                            </Box>
                                        </Box>

                                    </Box>
                                    <Box className="Wrapper3">
                                        {handleEditButtonIcon()}
                                        <Box sx={{ textAlign: " center", display: "flex", flexDirection: "column", }}>
                                            <Typography variant="caption" sx={{ fontSize: 16 }}> Fee </Typography>
                                            <Typography variant="subtitle1" className='feeInDollar'> $0 </Typography>
                                        </Box>
                                        <Box className="wrapperNotes">
                                            <Typography sx={{ display: "block", fontSize: 16, }} variant="caption">Notes</Typography>
                                            <Typography className='NotesData' sx={{ fontSize: 16, textShadow: "0 0 black", lineHeight: "22px", display: "block" }} variant="caption">{handleNotes()}</Typography>
                                        </Box>
                                    </Box>
                                    <Box className="seniorInfoContact" sx={{ overflowY: 'auto', }}>
                                        {routes && (
                                            < Box>
                                                {routes.requestsOn && routes.requestsOn.map((e, i) => (
                                                    <Box sx={{ display: "flex", margin: "12px 10px", alignItems: "center", justifyContent: "center" }} key={i}>
                                                        <Box className="logoImg" ></Box>
                                                        <Box sx={{ display: "flex", flexDirection: "column", marginLeft: 1, lineHeight: 1.4 }}>
                                                            <Typography sx={{ textShadow: "0 0 black" }} variant='subtitle1' className='subheading'> {e.seniorInfo.firstName} </Typography>
                                                            <Typography variant='subtitle1' className='subheading2'>{e.seniorInfo.homePhone}</Typography>
                                                        </Box>
                                                    </Box>
                                                ))}
                                                {routes.requestsOff && routes.requestsOff.map((e, i) => (
                                                    <Box sx={{ display: "flex", margin: "12px 10px", alignItems: "center", justifyContent: "center" }} key={i}>
                                                        <Box className="logoImg" ></Box>
                                                        <Box sx={{ display: "flex", flexDirection: "column", marginLeft: 1, lineHeight: 1.4 }}>
                                                            <Box sx={{ padding: 0 }}> <Typography sx={{ textShadow: "0 0 black" }} variant='subtitle1' className='subheading'> {e.seniorInfo.firstName} </Typography> </Box>
                                                            <Box> <Typography variant='subtitle1' className='subheading2'>{e.seniorInfo.homePhone}</Typography> </Box>
                                                        </Box>
                                                    </Box>
                                                ))}
                                                {!routes.requestsOn?.length && !routes.requestsOff?.length && (
                                                    <Box sx={{ display: "flex", margin: "12px 10px", alignItems: "center", justifyContent: "center" }} >
                                                        <Box className="logoImg" sx={{}}></Box>
                                                        <Box sx={{ display: "flex", flexDirection: "column", marginLeft: 1, lineHeight: 1.4 }}>
                                                            <Box sx={{ padding: 0 }}> <Typography sx={{ textShadow: "0 0 black" }} variant='subtitle1' className='subheading'> {handleName()} </Typography> </Box>
                                                            <Box> <Typography variant='subtitle1' className='subheading'> {handlePhoneNumber()} </Typography> </Box>
                                                        </Box>
                                                    </Box>
                                                )}
                                            </Box >
                                        )}
                                    </Box>
                                </Box>
                                <Box  >
                                    <Button style={{ color: handleCompleteColor() }} disabled={routes.stopStatus === "completed" || routes.stopStatus === "arrived"} className={disableOnComplete() ? "disabledBtn" : "completeBtn"} fullWidth variant='contained' onClick={(e) => handleComplete(routes?.stopID, routes?.routeID)} startIcon={handleIcons()}>{getButtonName()}</Button>
                                </Box>
                            </Box> :
                            <Box sx={{ backgroundColor: "#fff3cd", padding: "0px 5px", color: "#9b8747" }}>
                                <Typography variant='body1'> Ooops!</Typography>
                                <Typography variant='subtitle1'>No Scheduled rides at the moment, please contact the center.</Typography>
                            </Box>
                        : <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                            <Box sx={{ display: "flex", flexDirection: "column", gap: 1, alignItems: "center" }}>
                                <CircularProgress sx={{ color: "#045a60" }} />
                                <Typography variant='body2'>loading..</Typography>
                            </Box>
                        </Box>}

                </StyledBox>
            </Drawer>
            {/*Edit  Dialog  */}
            <DialogBox
                open={openDialogTwo}
                onClose={() => handleClose()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Box className="titleBoxDialogTwo"> <Typography variant='subtitle1'>Status</Typography> </Box>
                <Divider />
                <DialogContent>
                    <Box onClick={(e) => handleConfrim(routes?.stopID, routes?.routeID, "fromanchor")} className="dialogBtn">
                        <Button ><Typography color="white" variant='subtitle1'>Change to {getEditButtonName()}</Typography></Button>
                    </Box>
                    <Box className="dialogBtnClose"> <Button onClick={() => handleClose()} className='closeDialog'>Close</Button> </Box>
                </DialogContent>
            </DialogBox>
            {/*Message  Dialog  */}
            <DialogBox
                open={openMessageDialog}
                onClose={() => handleCloseMessageDialog()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Box className="titleBoxDialogTwo"> <Typography variant='subtitle1'>Messages</Typography> </Box>
                <Divider />
                <DialogContent sx={{ display: "flex", flexDirection: "column", gap: "5px", }}>
                    <Box onClick={() => HandleNotify("Onmyway")} className="messagedialogBtn">
                        <Typography sx={{ lineHeight: "22px" }} color="white" variant='subtitle1'>Your ride has arrived and is waiting.</Typography>
                    </Box>
                    <Box onClick={() => HandleNotify("waiting")} className="messagedialogBtn">
                        <Typography sx={{ lineHeight: "22px" }} color="white" variant='subtitle1'>Your ride will arrive momentarily please be ready.</Typography>
                    </Box>
                    <Button disabled={disabled} onClick={() => HandleNotify("leaving")} className={!disabled ? "messagedialogBtn" : "disabledBtnNotifi"}>
                        <Typography sx={{ lineHeight: "22px" }} color="white" variant='subtitle1'>{loader === false ? `Your ride will be arriving in ${waitingTime}` : ""}</Typography>
                        {loader === true ? <CircularProgress className='loader' /> : ""}
                    </Button>
                    <Box className="dialogBtnClose"> <Button onClick={() => handleCloseMessageDialog()} className='closeDialog'>Close</Button> </Box>
                </DialogContent>
            </DialogBox>
        </Root >
    );
}
export default BottomTray;