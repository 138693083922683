import React, { useEffect, useState } from 'react';
import { Box, AppBar, Toolbar, Typography } from '@mui/material';
import { GetRoutes } from '../../Services/Api/MealService';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import AuthService from '../../Services/AuthService';

const Root = styled(Box)(({ theme }) => ({
  height: '100%',
  padding: 0,
  '& .header-cont': {
    // width: "100%",
    width: "device-width",
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1,
    "& header": {
      display: "flex",
      height: 48,
      background: "white",
      fontSize: 12,
      alignItems: "center",
      '& img[alt="Logo"]': {
        width: 34,
        filter: 'invert(1)',
      },
      "& .headerNav": {
        // marginRight: 10,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        "& .fuel": {
          color: "red",
          position: "absolute",
          width: "26px",
          right: "62px",
          top: "12px",
          cursor: "pointer"
        },
        "& .pageMainTitle": {
          fontSize: 15,
          fontWeight: 600,
          whiteSpace: "nowrap"
        },
        "& .headerText": {
          display: "flex",
          color: '#1f0757',
          fontSize: 13,
          textShadow: '#1f0757',
          fontWeight: 600,
          whiteSpace: "nowrap"
        }
      }
    },
  },
  '& .mainContainer': {
    backgroundImage: `url(${require('../../Assets/loginBackgroundImg/img1.png')})`,
    backgroundSize: "cover",
    overflow: "hidden",
    backgroundPosition: "center",
    height: "calc(100vh - 48px)",
    marginTop: 48,
    overflowY: "auto",
    "& .routeTextNull": {
      height: "80vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    "& .routeBox": {
      margin: "20px auto ",
      display: "flex",
      width: "100",
      minWidth: 310,
      gap: 10,
      backgroundColor: theme.palette.primary.contrastText,
      padding: "10px",
      boxShadow: "0px 3px 15px -6px rgb(0 0 0 / 50%)",
      borderRadius: 10,
      alignItems: "center",
      "& .image": {
        border: "1px solid grey",
        borderRadius: "50%",
        height: 50,
        width: 50,
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      },
      "& .routeText": {
        display: "flex",
        flexDirection: "column",
        gap: 3
      },
      "& .routetextColor": {
        fontSize: 20,
        fontWeight: 400,
        color: theme.palette.button.main
      },
      "& .textColor": {
        fontSize: 14,
        fontWeight: 400,
        color: theme.palette.other.darkgray,
        textShadow: "0 0 gray"
      }
    },
  },
  '& img[alt="heartpng"]': {
    width: 50,
  },
}));
export default function Activities() {
  let obj = [
    {
      header: "Bingo",
      time: "3:05 AM - 5:00 AM",
      address: "Music Room; 2nd Floor"
    },
    {
      header: "Bingo",
      time: "3:05 AM - 5:00 AM",
      address: "Music Room; 2nd Floor"
    },
    {
      header: "Bingo",
      time: "3:05 AM - 5:00 AM",
      address: "Music Room; 2nd Floor"
    },
    {
      header: "Bingo",
      time: "3:05 AM - 5:00 AM",
      address: "Music Room; 2nd Floor"
    },
    {
      header: "Bingo",
      time: "3:05 AM - 5:00 AM",
      address: "Music Room; 2nd Floor"
    }
  ]
  const [state, setState] = useState({
    activites: [],
    seniorId: [],
    seniorsList: null,
  })
  let navigate = useNavigate();
  useEffect(() => {
    if (state.seniorsList === null) {
      // GetInitialDetails();
    }
    // eslint-disable-next-line
  })
  const AddDetail = (id) => {
    localStorage.setItem("MealId", id);
    navigate("/bingo", { state: id })
  }
  // eslint-disable-next-line 
  const GetInitialDetails = async () => {
    let activites = await GetRoutes();
    let activitesData = activites.data;
    setState({ ...state, activites: activitesData })
  }
  useEffect(() => {
    let Token = AuthService.getAuthDataByName("token") || "";
    if (Token === "") {
      navigate("/");
    }
  }, [navigate])
  return (

    <Root p={2} >
      {/* Header */}
      <Box className="header-cont">
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="static">
            <Toolbar
              sx={{ width: "100%", maxWidth: "100%", padding: "0px 15px 0px 45px !important" }}>
              <Box className="headerNav">
                <Box
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2, display: "inline-flex", alignItems: "center" }}>

                  <img src={require('../../Assets/icons/msc_heart.png')} alt="Logo" style={{ marginRight: 10 }} />
                  <div>
                    <Typography color="black" className="pageMainTitle" id="pageTitle">
                      My Senior Center
                    </Typography>
                    <Typography color="black" className="headerText" id="pageTitle">
                      Activities
                    </Typography>
                  </div>
                </Box>

              </Box>
            </Toolbar>
          </AppBar>
        </Box>
      </Box>
      <Box className="mainContainer">
        {obj.map((item, index) => (
          <Box key={index} sx={{ margin: "4px 10px", cursor: 'pointer' }} onClick={() => { AddDetail(item.RouteID) }}>
            <Box className="routeBox">
              <Box className="image">
                <img style={{ width: "30px", marginTop: 3 }} src={require('../../Assets/images/activities-icon.png')} alt="heartpng" />
              </Box>
              <Box className="routeText">
                <Typography className='routetextColor' variant='h1'>{item.header}</Typography>
                <Typography className='textColor' variant='h5'>{item.time}</Typography>
                <Typography className='textColor' variant='h5'>{item.address}</Typography>
              </Box>
            </Box>
          </Box>
        ))
        }
      </Box>
    </Root>
  )
}