import React from "react";
import rg4js from 'raygun4js';
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import * as Raygun from 'raygun4js';
// Lazy Components
import Msc from '../Pages/Msc'
import SubmitForm from '../Pages/Meal/SubmitForm'
import Home from '../Pages/Home'
import Ride from '../Pages/Rides/Ride'
import NewRide from '../Pages/Rides/newRide'
import Meal from '../Pages/Meal/Meal'
import Activities from '../Pages/Activities/Activities'
import Bingo from '../Pages/Activities/Bingo'
import RouteSenior from '../Pages/Meal/RouteSeniors'
import Scanner from '../Components/Scanner'
import SignatureArea from '../Pages/Meal/Signature'
import Senior from '../Pages/Meal/Senior'
import NotFound from '../Components/NotFound'
import TokenActivation from '../Pages/Access/TokenActivation'
import SelecteVehicle from '../Pages/Activation/SelectVehicle'
import SelecteDriver from '../Pages/Activation/SelectDriver'
import BluetoothScanner from '../Components/BluetoothScanner'
import Settings from '../Pages/Settings'
import Notification from '../Pages/Notifications'
import ChangeTheme from '../Pages/ChangeTheme'
import SeniorInfo from '../Pages/SeniorInfo'
import EditInfo from '../Pages/EditInfo'
import NewScanner from "../Components/NewScanner";

export default function RoutesDefinition(props) {
    const navigate = useNavigate();
    const location = useLocation();
    // //Ragyun
    // const errorPayload = {
    //     type: 'manual',
    //     customData: {
    //         message: 'Pwa',
    //     },
    // };
    // rg4js('apiKey', '6E4UtRiVzMNMEQykopPWg');
    // rg4js('enableCrashReporting', true);
    // rg4js('enablePulse', true);
    // rg4js('send', errorPayload);
    // React.useEffect(function () {
    //     rg4js('trackEvent', { type: 'pageView', path: location.pathname });

    // }, [location]);

    React.useEffect(() => {
        // Reload on Navigiator
        let el = document.querySelector(".connectivity > p > a");
        el.addEventListener("click", () => navigate("/ride"))
    }, [navigate]);
    React.useEffect(() => {
        localStorage.setItem("lastVisitedPage", window.location.href)
        if (location.pathname === "/") {
            const isButtonVisiblemenu = location.pathname === "/";
            props.handlestateFromChildMenu(isButtonVisiblemenu)
        } else if (location.pathname === "/theme") {
            const isButtonVisiblemenu = location.pathname === "/theme";
            props.handlestateFromChildMenu(isButtonVisiblemenu)
        }
        else if (location.pathname === "/edit") {
            const isButtonVisiblemenu = location.pathname === "/edit";
            props.handlestateFromChildMenu(isButtonVisiblemenu)
        }
        else if (location.pathname === "/notify") {
            const isButtonVisiblemenu = location.pathname === "/notify";
            props.handlestateFromChildMenu(isButtonVisiblemenu)
        }

        else {
            props.handlestateFromChildMenu(false)
        }
    }, [location])
    React.useEffect(() => {
        localStorage.setItem("lastVisitedPage", window.location.href)
    }, [location])
    return (
        <>
            <Routes>
                <Route index path="/" element={<TokenActivation />} />
                <Route exact path="/activties" element={<Activities />} />
                <Route exact path="/bingo" element={<Bingo />} />
                <Route exact path="/driver" element={<SelecteDriver />} />
                <Route exact path="/home" element={<Home />} />
                <Route exact path="/meal" element={<Meal />} />
                <Route exact path="/msc" element={<Msc />} />
                <Route exact path="/routesenior" element={<RouteSenior />} />
                <Route exact path="/ride" element={<Ride drawer={props.rightDrawer} />} />
                <Route exact path="/nrfachanbaltee" element={<NewRide drawer={props.rightDrawer} />} />
                <Route exact path="/scanner" element={<Scanner />} />
                <Route exact path="/senior" element={<Senior />} />
                <Route exact path="/signature" element={<SignatureArea />} />
                <Route exact path="/submitform" element={<SubmitForm />} />
                <Route exact path="/vehicle" element={<SelecteVehicle />} />
                <Route exact path="/bluetooth" element={<BluetoothScanner />} />
                <Route exact path="/theme" element={<ChangeTheme handleTheme={props.handleTheme} isDarkMode={props.isDarkMode} />} />
                <Route exact path="/settings" element={<Settings />} />
                <Route exact path="/notify" element={<Notification />} />
                <Route exact path="/info" element={<SeniorInfo />} />
                <Route exact path="/edit" element={<EditInfo />} />
                <Route exact path="/newscan" element={<NewScanner />} />
                <Route path='*' element={<NotFound />}></Route>
            </Routes>
            <div id="check-render"></div>
        </>
    );
}

