import { Typography } from '@mui/material'
import { Box, styled } from '@mui/system'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import constant from '../../Shell/Constant';

const Root = styled(Box)(({ theme }) => ({
    height: "100vh",
    backgroundImage: `url(${require('../../Assets/loginBackgroundImg/img1.png')})`,
    backgroundSize: "cover",
    overflow: "hidden",
    backgroundPosition: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: "10px 20px",
    "& .backIcon": {
        position: "absolute",
        top: "15px",
        left: "20px"
    }

}))
export const NoAccess = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const host = window.location.hostname;
    return (
        <>
            <Root>
                <Box mt={2}>
                    <ArrowBackRoundedIcon className='backIcon' onClick={() => host == constant.driversDomain ? navigate("/ride") : (host == constant.volunteerDomain || host == constant.volunteerDomain2) ? navigate("/volunteer") : host == constant.mealsDomain ? navigate('/meal') : navigate("/ride")} />
                </Box>
                <img src={require("../../Assets/images/noAccess.png")} alt="" />
                <Typography textAlign={'center'} variant='subtitle1'>{state?.name}</Typography>
                <Typography textAlign={'center'} variant='subtitle2'>Contact your center to grant you access to this section</Typography>
            </Root>
        </>
    )
}
