import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';
import { Button, IconButton, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import CloseIcon from '@mui/icons-material/Close';
import Tab from '@mui/material/Tab';
import { paperClasses } from "@mui/material/Paper";
import AuthService from '../Services/AuthService';
import { AddVehicleGallons } from '../Services/Api/RidesServices';
import CircularProgress from '@mui/material/CircularProgress';
const Root = styled(Box)(({ theme }) => ({


}))
const StyledBox = styled(Box)(({ theme }) => ({

    "& .MuiPaper-root": {
        backgroundColor: "red",
        maxWidth: "calc(100% - 15px)"
    },
    "& .mainTab": {

        [theme.breakpoints.up('sm')]: {
            width: "380px"
        },
    },
    "& .fuelButton": {
        backgroundColor: "#3db058"
    },
    "& .Mui-selected": {
        color: "black !important"
    },
    "& .tabs": {
        "& .css-1owmaky-MuiButtonBase-root-MuiTab-root ": {
            minWidth: "100px",
            marginLeft: "5px"
        },
        "& .MuiTabs-flexContainer": {
            height: "45px ",
            display: "flex",
            alignItems: "center",
            marginLeft: "5px",
            justifyContent: "space-between",
            "& .Mui-disabled": {
                "& .MuiTab-iconWrapper": {
                    opacity: "0.3",
                },
            },

            "& .MuiTab-iconWrapper": {
                marginRight: "4px",
            },
        },
        "& .css-7g9nma-MuiTabs-root": {
            minHeight: "46px"
        }

    },
    "& .input": {
        padding: "15px", borderRadius: '5px', backgroundColor: "white",
        marginBottom: "15px",
        width: "100%",
        border: "1px solid #022c2f",
        outline: "none",
    }


}));
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box className="mainTab" sx={{ p: 1, }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
export default function TemporaryDrawer(props) {
    const [state, setState] = React.useState({
        right: false,
    });
    const [gallons, setGallons] = React.useState("");
    const [alertMessasge, setAlertMessasge] = React.useState('');
    const [value, setValue] = React.useState(1);
    const success = React.useRef()
    const [isSubmit, setIsSubmit] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleChangeGallon = (e) => {
        if (e.target.value <= 999.999) {
            setGallons(e.target.value)
        }

    };
    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ ...state, right: open });
        props.handlestateFromDrawer(open)
    };
    //handleFuelConsumption
    const handleFuelConsumption = async () => {
        setIsLoading(true)
        if (gallons.length === 0 || gallons == 0) {
            setIsSubmit(true)
            success.current = false;
            setAlertMessasge("Please add valid Gallons Added/Filled.")
            setIsLoading(false)
            setTimeout(() => {
                setIsSubmit(false)
            }, 10000)
        } else {
            let gsid = AuthService.getAuthDataByName("gsid") || "";
            let vehicleUID = AuthService.getAuthDataByName("vehicleUID") || "";
            let userID = AuthService.getAuthDataByName("userID") || "";
            let gel = Number(gallons)

            let data = {
                vehicleUID: vehicleUID,
                Gallons: gel,
                UserID: userID,
                GSID: gsid
            }
            await AddVehicleGallons(data, gsid)
                .then((res) => {

                    setIsSubmit(true)
                    if (res.success) {
                        success.current = true
                        setGallons('')
                        setAlertMessasge("Fuel Consumption Saved Successfully.")
                    } else {
                        success.current = false
                        setAlertMessasge("Fuel Consumption Failed To Save.")
                    }
                })
                .catch((err) => {
                    success.current = false
                    setAlertMessasge("Fuel Consumption Failed To Save.")
                }).finally(() => {
                    setIsLoading(false)
                    setIsSubmit(true)
                    setTimeout(() => {
                        setIsSubmit(false)
                    }, 10000)

                })
        }

    }
    return (
        <Root>
            <React.Fragment key='right'>
                <Box onClick={toggleDrawer(true)} width='25px'>
                    <img src={require("../Assets/icons/busicons.png")} width='100%' alt='bus' />
                </Box>

                <Drawer
                    anchor="right"
                    open={state.right}
                    onClose={toggleDrawer(false)}
                    sx={{
                        [`& .${paperClasses.root}`]: {
                            maxWidth: "calc(100% - 34px)",

                        }
                    }}

                >
                    <StyledBox className='styleboxRight'>
                        <Box className="abc" sx={{ height: "100vh", backgroundImage: `url(${require("../Assets/loginBackgroundImg/img1.png")})`, backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "cover", }}>
                            <Box
                                role="presentation"
                            // onClick={toggleDrawer(false)}
                            // onKeyDown={toggleDrawer(false)}
                            >
                                <Box sx={{ height: "100vh", typography: 'body1' }}>
                                    <IconButton onClick={toggleDrawer(false)}>
                                        <CloseIcon />
                                    </IconButton>
                                    <Box className="tabs" sx={{ borderBottom: 1, borderColor: 'divider', height: "45px" }} >
                                        <Tabs variant="scrollable"
                                            scrollButtons="auto" sx={{ height: "40px ", }} value={value} onChange={handleChange} aria-label="basic tabs example">
                                            <Tab disabled sx={{ minWidth: "100px", fontSize: "12px", margin: 0, padding: 0, }} color='red' iconPosition='start' icon={<img src={require('../Assets/images/bell.png')} width='18px' alt='notifi' />} label="Notifications" {...a11yProps(0)} />
                                            <Tab sx={{ fontSize: "12px", margin: 0, padding: 0 }} label="Gallons" iconPosition='start' icon={<img src={require('../Assets/images/feul.png')} alt='fuel' width='18px' />} {...a11yProps(1)} />
                                            <Tab disabled sx={{ fontSize: "12px", margin: 0, padding: 0 }} label="Accident" iconPosition='start' icon={<img src={require('../Assets/images/ambulance.png')} alt='acc' width='18px' />} {...a11yProps(2)} />
                                            <Tab disabled sx={{ fontSize: "12px", margin: 0, padding: 0 }} label="Add Stop" iconPosition='start' icon={<img src={require('../Assets/images/stop.png')} alt='stop' width='18px' />} {...a11yProps(3)} />
                                        </Tabs>
                                    </Box>
                                    <CustomTabPanel value={value} index={0}>
                                        <Box sx={{ marginBottom: "10px", borderRadius: "5px", backgroundColor: "white", padding: "10px", }}>
                                            <Box>
                                                <Typography variant='subtitle1'>Notification 1</Typography>
                                                <Typography variant='caption'></Typography>
                                            </Box>
                                            <Box sx={{ textAlign: "end" }}>
                                                <Typography variant='caption'>1 day ago</Typography>
                                            </Box>
                                        </Box>
                                        <Box sx={{ marginBottom: "10px", borderRadius: "5px", backgroundColor: "white", padding: "10px", }}>
                                            <Box>
                                                <Typography variant='subtitle1'>Notification 1</Typography>
                                                <Typography variant='caption'></Typography>
                                            </Box>
                                            <Box sx={{ textAlign: "end" }}>
                                                <Typography variant='caption'>1 day ago</Typography>
                                            </Box>
                                        </Box>
                                    </CustomTabPanel>
                                    <CustomTabPanel value={value} index={1}>
                                        <Box sx={{ marginBottom: "10px", width: "100%", position: "relative" }}>
                                            <Typography sx={{ marginBottom: '5px' }} variant='subtitle1'>Gallons Added/Filled</Typography>
                                            <input className='input' value={gallons} style={{ marginBottom: "20px" }} onChange={handleChangeGallon} inputprops={{ style: { fontSize: 12, fontWeight: 600, } }} type='number' placeholder='0.00' />

                                            {isSubmit && (alertMessasge.length && gallons.length === 0 || gallons <= "0") ? <Typography sx={{ color: success.current ? "green" : "red", position: "absolute", top: 80 }} variant='body2'>{alertMessasge}</Typography> : ""}
                                            <Box sx={{ display: "flex", justifyContent: "end", alignItems: 'center', }}>
                                                <Button className="fuelButton" onClick={handleFuelConsumption} variant='contained' startIcon={isLoading ? <CircularProgress size={15} /> : ""} >Submit</Button>
                                            </Box>
                                        </Box>
                                    </CustomTabPanel>
                                    <CustomTabPanel value={value} index={2}>
                                        <Box sx={{ marginBottom: "10px", borderRadius: "5px", padding: "10px", }}>

                                        </Box>

                                    </CustomTabPanel>
                                    <CustomTabPanel value={value} index={3}>
                                        <Box sx={{ marginBottom: "10px", borderRadius: "5px", padding: "10px", }}>

                                        </Box>
                                    </CustomTabPanel>
                                </Box>
                            </Box>
                        </Box>
                    </StyledBox>
                </Drawer>

            </React.Fragment>

        </Root >
    );
}