const constant = {
    volunteerDomain: 'volunteer.myactivecenter.com',
    volunteerDomain2: 'volunteers.myactivecenter.com',
    driversDomain: 'drivers.myseniorcenter.net',
    mealsDomain: 'mow.myseniorcenter.net',
    localDomain: 'localhost',
    qaDomain: 'qa1.xtecsoft.com',

};

constant.driverProtectedDomains = [constant.driversDomain, constant.localDomain, constant.qaDomain];

constant.volunteerProtectedDomains = [constant.volunteerDomain, constant.volunteerDomain2, constant.localDomain, constant.qaDomain];

constant.mealsProtectedDomains = [constant.mealsDomain, constant.qaDomain, constant.localDomain]

export default constant;
